import React, { useEffect, useRef, useState } from 'react';
import Routers from './Route';
import Providers from './Providers';
import ErrorBoundary from './ErrorBoundary';
import { fetchCustomizationSettings } from './utils/customization';
import Error500 from "./Components/Pages/ErrorPages/ErrorPage500";
import loadmsc from '../src/assets/images/loadmsc.gif';
import redinc from '../src/assets/images/redinc.gif';
import { SnackbarProvider } from 'notistack'
import { Toaster } from 'react-hot-toast';


const App = () => {
  const hasFetched = useRef(false);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const origin = window.location.origin;
  const office = process.env.REACT_APP_INTITIAL;
  const isRedbackstage = origin.includes(office);
  const initialLoaderImage = isRedbackstage ? redinc : loadmsc;
  const [loaderImage, setLoaderImage] = useState(initialLoaderImage);

  useEffect(() => {
    const fetchSettings = async () => {
      if (hasFetched.current) return;
      hasFetched.current = true;
      try {
        const data = await fetchCustomizationSettings();
        if (!data) {
          setHasError(true);
          return;
        }
        if (data.favicon) {
          const favicon = document.getElementById('favicon');
          favicon.href = data.favicon;
        }
        if (data.title) {
          document.title = data.title; 
        }
      } catch (error) {
        setHasError(true);
      } finally {
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      }
    };

    fetchSettings();
  }, []);

  if (isLoading && isRedbackstage) {
    const loaderStyle = { width: '500px', height: '500px' };
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <img src={loaderImage} alt="Loading..." className="loader" style={loaderStyle} />
      </div>
    );
  }

  if (hasError) {
    return <Error500 />;
  }

  return (
    <div className='App'>
      <ErrorBoundary>
      <SnackbarProvider 
        maxSnack={3} 
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        autoHideDuration={1000}
      >
        <Providers>
          <Routers />
        </Providers>
      </SnackbarProvider>
      </ErrorBoundary>
      <Toaster />
    </div>
  );
};

export default App;