import React, { useState } from "react";
import { Breadcrumbs, H5, Btn } from "../../../../AbstractElements";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Input,
} from "reactstrap";
import DataTable from "react-data-table-component";
import ExpenseService from "../../../../Services/expenses/ExpenseService";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import NewExpensesModal from "../Modals/NewExpenseModal";
import UpdateExpensesModal from "../Modals/UpdateExpensesModal";
import { toast } from "react-hot-toast";
import Swal from "sweetalert2";
import GridLoader from "../../../Loaders/GridLoader";
import { Download } from "react-feather";
import { useAuth } from "../../../../_helper/Auth/AuthProvider";

function ContributorExpenses() {
  const queryClient = useQueryClient();

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [selectedExpense, setSelectedExpense] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [searchText, setSearchText] = useState("");
  const { userData } = useAuth();

  const {
    isPending: isCategoriesPending,
    isError: isCategoriesError,
    data: categories,
  } = useQuery({
    queryKey: ["contributorCategories"],
    queryFn: ExpenseService.getContributorCategories,
  });

  const {
    isPending: isArtistsPending,
    isError: isArtistsError,
    data: artists,
  } = useQuery({
    queryKey: ["artists"],
    queryFn: () => ExpenseService.getArtistsByLabel(userData),
  });

  const { isPending, isError, data } = useQuery({
    queryKey: ["expenses"],
    queryFn: () => ExpenseService.getExpense(userData),
  });

  const addExpenseMutation = useMutation({
    mutationFn: (formData) => ExpenseService.createExpense(formData, userData.label.label_id),
    onSuccess: (data) => {
      if (data.success) {
        toast.success("Expense added successfully!");
        queryClient.invalidateQueries(["contributorExpenses"]);
        setIsAddModalOpen(false);
      } else {
        toast.error("Failed to add expense.");
      }
    },
    onError: () => {
      toast.error("Failed to add expense.");
    },
  });

  const handleAddExpense = (expenseData) => {
    return new Promise((resolve, reject) => {
      addExpenseMutation.mutate(expenseData, {
        onSuccess: (data) => {
          if (data.success) {
            resolve();
          } else {
            reject(new Error("Failed to add expense."));
          }
        },
        onError: reject,
      });
    });
  };

  const updateExpenseMutation = useMutation({
    mutationFn: ({ id, expenseData }) =>
        ExpenseService.updateExpense(id, expenseData),
    onSuccess: () => {
      toast.success("Expense updated successfully!");
      queryClient.invalidateQueries(["contributorExpenses"]);
      setIsUpdateModalOpen(false);
    },
    onError: () => {
      toast.error("Failed to update expense.");
    },
  });

  const handleSaveUpdatedExpense = (expenseData) => {
    return new Promise((resolve, reject) => {
      updateExpenseMutation.mutate(
          { id: selectedExpense.id, expenseData },
          {
            onSuccess: resolve,
            onError: reject,
          }
      );
    });
  };

  const handleUpdateExpense = (expense) => {
    setSelectedExpense(expense);
    setIsUpdateModalOpen(true);
  };

  const deleteExpenseMutation = useMutation({
    mutationFn: ExpenseService.deleteExpense,
    onSuccess: () => {
      toast.success("Expense deleted successfully!");
      queryClient.invalidateQueries(["contributorExpenses"]);
    },
    onError: () => {
      toast.error("Failed to delete expense.");
    },
  });

  const handleDeleteExpense = (expenseId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to delete this expense?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteExpenseMutation.mutate(expenseId);
      }
    });
  };

  const handleDownloadFile = async (expenseId) => {
    try {
      const { data: fileBlob, mimeType } = await ExpenseService.downloadExpenseFile(expenseId);
      const url = window.URL.createObjectURL(new Blob([fileBlob], { type: mimeType }));
      const link = document.createElement('a');
      link.href = url;
      const date = new Date();
      const timestamp = `${date.getHours()}${date.getMinutes()}${date.getSeconds()}`;
      link.setAttribute('download', timestamp);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      toast.error("Failed to download file.");
    }
  };

  if (isCategoriesPending || isPending) {
    return <GridLoader />;
  }

  if (isCategoriesError || isError) {
    return <p>An error has occurred. Please try again later.</p>;
  }

  let filteredData = Array.isArray(data) ? data : [];

  if (selectedCategory) {
    filteredData = filteredData.filter(expense => expense.category_contributors === selectedCategory);
  }

  if (searchText) {
    filteredData = filteredData.filter(expense =>
        expense.artist_name.toLowerCase().includes(searchText.toLowerCase()) ||
        expense.title.toLowerCase().includes(searchText.toLowerCase()) ||
        expense.amount.toString().includes(searchText)
    );
  }

  const tableColumns = [
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Artist Name",
      selector: (row) => row.artist_name,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
    },
    {
      name: "Category",
      selector: (row) => row.expense_type,
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true,
    },
    {
      name: "Currencies",
      selector: (row) => row.currencies,
      sortable: true,
    },
    {
      name: "File",
      selector: (row) => row.file,
      cell: (row) => (
          row.file ? (
              <Download
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleDownloadFile(row.id)}
              />
          ) : (
              <span>No file chosen</span>
          )
      ),
      sortable: false,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
          <div className="todo">
            <div className="todo-list-wrapper">
              <div className="todo-list-container todo-list-footer">
                <ul className="simple-list">
                  <li className="task border-0">
                    <div className="task-container">
                    <span className="task-action-btn">
                      <span
                          className="action-box large icon-edit"
                          title="Update"
                          onClick={() => handleUpdateExpense(row)}
                      >
                        <i className="icon">
                          <i className="fa fa-edit"></i>
                        </i>
                      </span>
                      <span
                          className="action-box large delete-btn"
                          title="Delete"
                          onClick={() => handleDeleteExpense(row.id)}
                      >
                        <i className="icon">
                          <i className="icon-trash"></i>
                        </i>
                      </span>
                    </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
      ),
      center: true,
    },
  ];

  return (
      <>
        <Breadcrumbs
            parent="Expenses"
            title="Expenses"
            mainTitle="Manage Expenses"
            buttons={[
              <Btn
                  attrBtn={{
                    size: "sm",
                    color: "primary",
                    onClick: () => setIsAddModalOpen(true),
                  }}
              >
                <i className="fa fa-plus me-1"></i> Add Expense
              </Btn>,
            ]}
        />

        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader>
                  <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                  >
                    <H5>Manage Artists Expenses</H5>
                    <div style={{ display: "flex", marginLeft: "auto" }}>
                      <Input
                          type="select"
                          name="select"
                          id="exampleSelect"
                          onChange={(e) => setSelectedCategory(e.target.value)}
                          style={{ marginRight: "10px" }}
                      >
                        <option value="" defaultChecked>
                          All Categories
                        </option>
                        {categories?.data?.map((category, index) => (
                            <option key={index} value={category.title}>
                              {category.title}
                            </option>
                        ))}
                      </Input>
                      <Input
                          type="text"
                          placeholder="Search by artist, title, or amount"
                          onChange={(e) => setSearchText(e.target.value)}
                      />
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <DataTable
                      columns={tableColumns}
                      data={filteredData}
                      striped={true}
                      center={true}
                      responsive={true}
                      pagination
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <NewExpensesModal
            isOpen={isAddModalOpen}
            toggle={() => setIsAddModalOpen(!isAddModalOpen)}
            onSave={handleAddExpense}
            categories={categories?.data || []}
            artists={artists}
            expenseType="contributor"
        />

        <UpdateExpensesModal
            isOpen={isUpdateModalOpen}
            toggle={() => setIsUpdateModalOpen(!isUpdateModalOpen)}
            onSave={handleSaveUpdatedExpense}
            categories={categories?.data || []}
            expense={selectedExpense}
            expenseType="contributor"
        />
      </>
  );
}

export default ContributorExpenses;