import React, { Fragment, useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  CardFooter,
  ButtonGroup,
} from "reactstrap";
import { H5, Btn } from "../../../../AbstractElements";
import DatePicker from "react-datepicker";
import { Typeahead } from "react-bootstrap-typeahead";
import Select from "react-select";
import GridLoader from "../../../Loaders/GridLoader";
import { Controller } from "react-hook-form";
import { useAuth } from "../../../../_helper/Auth/AuthProvider";

const StepOne = ({
  control,
  errors,
  loading,
  handleNext,
  artists,
  genres,
  setValue,
  clearErrors
}) => {
  const [typeaheadOptions, setTypeaheadOptions] = useState(artists);
  const { userData } = useAuth();
  const pickerdateblocker = userData?.pickerdateblocker || 0;

  useEffect(() => {
    setTypeaheadOptions(artists);
  }, [artists]);

  return (
    <Fragment>
      <Col sm="12">
        {loading ? (
         <GridLoader />
        ) : (
          <>
            {/* <Col md={20}>
                      <Row className="justify-content-center mb-3">
                        <Col xs="auto">
                          <Btn
                            attrBtn={{
                              size: "lg",
                              color: "primary",
                              outline: true,
                              onClick: handleNext,
                            }}
                          >
                            <i className="fa fa-arrow-circle-right me-1"></i>NextStep
                          </Btn>
                        </Col>
                      </Row>
                    </Col> */}
          <Card>
            <CardHeader>
              <H5>{" Step One - MetaData Entry"}</H5>
              <span>{"Carefully fill out the form "}</span>
            </CardHeader>
            <CardBody>
              <Form className="theme-form" id="step1" onSubmit={handleNext}>
                <div className="row">
                  <FormGroup className="col-sm-3 mb-2">
                    <Label className="col-form-label">Title</Label>
                    <Controller
                        name="title"
                        control={control}
                        defaultValue=""
                        rules={{required: true}}
                        render={({field}) => (
                            <input
                                {...field}
                                className="form-control "
                                type="text"
                                placeholder="Title"
                                onChange={(e) => {
                                  field.onChange(e);
                                  clearErrors('title');
                                }}
                            />
                        )}
                    />
                    <span style={{color: "red"}}>
                      {errors.title && "Title is required"}
                    </span>
                  </FormGroup>
                  <FormGroup className="col-sm-3 mb-2">
                    <Label className="col-form-label">Artist</Label>
                    <Controller
                        name="artist"
                        control={control}
                        defaultValue=""
                        rules={{required: true}}
                        render={({field}) => (
                            <Input
                                {...field}
                                type="select"
                                placeholder="Select Artist"
                                className="form-control "
                                onChange={(e) => {
                                  field.onChange(e);
                                  clearErrors('artist');
                                }}
                            >
                              <option key="artist-option" value="">
                                Select Artist...
                              </option>
                              {artists.map((artist) => (
                                  <option key={artist.id_user} value={artist.id_user}>
                                    {artist.name}
                                  </option>
                              ))}
                            </Input>
                        )}
                    />
                    <span style={{color: "red"}}>
                      {errors.artist && "Artist is required"}
                    </span>
                  </FormGroup>

                  <FormGroup className="col-sm-3 mb-3">
                    <Label className="col-form-label">Copyright Year</Label>
                    <Controller
                        name="copyrightYear"
                        control={control}
                        defaultValue=""
                        /* rules={{ required: true }} */
                        render={({field}) => (
                            <input
                                {...field}
                                className="form-control "
                                type="number"
                                placeholder="Copyright Year"
                                onChange={(e) => {
                                  field.onChange(e);
                                  clearErrors('copyrightYear');
                                }}
                            />
                        )}
                    />
                    {/* <span style={{ color: "red" }}>
                      {errors.copyrightYear && "Copyright Year is required"}
                    </span> */}
                  </FormGroup>
                  <FormGroup className="col-sm-3 mb-2">
                    <Label className="col-form-label">Catalog Number</Label>
                    <Controller
                        name="catalogNumber"
                        control={control}
                        defaultValue=""
                        /* rules={{ required: true }} */
                        render={({field}) => (
                            <input
                                {...field}
                                className="form-control "
                                type="text"
                                placeholder="Catalog Number"
                                onChange={(e) => {
                                  field.onChange(e);
                                  clearErrors('catalogNumber');
                                }}
                            />
                        )}
                    />
                    {/* <span style={{ color: "red" }}>
                      {errors.catalogNumber && "Catalog Number is required"}
                    </span> */}
                  </FormGroup>


                </div>
                <Row>
                  <FormGroup className="col-sm-3 mb-3">
                    <Label className="col-form-label">Barcode</Label>
                    <Controller
                        name="barcode"
                        control={control}
                        defaultValue=""
                        /* rules={{ required: true }} */
                        render={({field}) => (
                            <input
                                {...field}
                                className="form-control "
                                type="text"
                                placeholder="Barcode"
                                onChange={(e) => {
                                  field.onChange(e);
                                  clearErrors('barcode');
                                }}
                            />
                        )}
                    />
                    {/* <span style={{ color: "red" }}>
                      {errors.barcode && "Barcode is required"}
                    </span> */}
                  </FormGroup>

                  <FormGroup className="col-sm-3 mb-3">
                    <Label className="col-form-label">Type</Label>
                    <Controller
                        name="type"
                        control={control}
                        defaultValue=""
                        /* rules={{ required: true }} */
                        render={({field}) => (
                            <Input
                                {...field}
                                type="select"
                                placeholder="Select Type"
                                className="form-control"
                                onChange={(e) => {
                                  field.onChange(e);
                                  clearErrors('type');
                                }}
                            >
                              <option value="">Select...</option>
                              <option value="0">Album</option>
                              <option value="1">Single</option>
                            </Input>
                        )}
                    />
                    {/* <span style={{ color: "red" }}>
                      {errors.type && "Type is required"}
                    </span> */}
                  </FormGroup>

                  {/* <FormGroup className="col-sm-3 mb-3">
                    <Label className="col-form-label">Status</Label>
                    <Controller
                      name="status"
                      control={control}
                      defaultValue=""
                      rules={{ required: true }} 
                      render={({ field }) => (
                        <Input
                          {...field}
                          type="select"
                          placeholder="Select Status"
                          className="form-control "
                          onChange={(e) => {
                            field.onChange(e); 
                            clearErrors('status');
                          }}
                        >
                          <option value="">Select...</option>
                          <option value="Approved">Approved</option>
                          <option value="Delivered">Delivered</option>
                          <option value="Draft">Draft</option>
                          <option value="Takedown">Takedown</option>
                        </Input>
                      )}
                    />
                     <span style={{ color: "red" }}>
                      {errors.status && "Status is required"}
                    </span> 
                  </FormGroup> */}

                  <FormGroup className="col-sm-3 mb-3">
                    <Label className="col-form-label">Explicit Content</Label>
                    <Controller
                        name="explicitContent"
                        control={control}
                        defaultValue=""
                        /* rules={{ required: true }} */
                        render={({field}) => (
                            <Input
                                {...field}
                                type="select"
                                placeholder="Explicit Content"
                                className="form-control "
                                onChange={(e) => {
                                  field.onChange(e);
                                  clearErrors('explicitContent');
                                }}
                            >
                              <option value="">Select...</option>
                              <option value="Explicit">Explicit</option>
                              <option value="No Explicit">No Explicit</option>
                            </Input>
                        )}
                    />
                    {/* <span style={{ color: "red" }}>
                      {errors.explicitContent && "Explicit Content is required"}
                    </span> */}
                  </FormGroup>

                  <FormGroup
                      className="col-sm-3 mb-3"
                  >
                    <Label className="col-form-label">Class</Label>
                    <Controller
                        name="genres"
                        control={control}
                        defaultValue=""
                        /* rules={{ required: true }} */
                        render={({field}) => (
                            <Input
                                {...field}
                                type="select"
                                placeholder="Select genres"
                                className="form-control "
                                onChange={(e) => {
                                  field.onChange(e);
                                  clearErrors('genres');
                                }}
                            >
                              <option value="">Select...</option>
                              {genres.map((genre) => (
                                  <option key={genre.id_genre} value={genre.id_genre}>
                                    {genre.genre}
                                  </option>
                              ))}
                            </Input>
                        )}
                    />
                    {/* <span style={{ color: "red" }}>
                      {errors.genres && "Genre is required"}
                    </span> */}
                  </FormGroup>

                </Row>
                <Row>
                    <FormGroup className="col-sm-3 mb-3">
                      <Label>Release Date</Label>
                      <div className="input-group">
                        <Controller
                            name="releaseDate"
                            control={control}
                            render={({field}) => (
                                <DatePicker
                                    {...field}
                                    className="form-control"
                                    placeholderText="mm/dd/yyyy"
                                    selected={field.value}
                                    onChange={(date) => {
                                      field.onChange(date);
                                      clearErrors('releaseDate');
                                    }}
                                    minDate={new Date().setDate(new Date().getDate() + parseInt(pickerdateblocker))}
                                />
                            )}
                        />
                      </div>
                    </FormGroup>

                    <FormGroup className="col-sm-3 mb-3">
                      <Label className="form-label">Copyright Text</Label>
                      <Controller
                          name="copyrightText"
                          control={control}
                          defaultValue=""
                          render={({field}) => (
                              <input
                                  {...field}
                                  className="form-control"
                                  type="text"
                                  placeholder="Copyright Text"
                                  onChange={(e) => {
                                    field.onChange(e);
                                    clearErrors('copyrightText');
                                  }}
                              />
                          )}
                      />
                    </FormGroup>

                    <FormGroup className="col-sm-3 mb-3">
                      <Label>Other Artists</Label>
                      <Controller
                          name="other_artists"
                          control={control}
                          render={({field}) => (
                              <Typeahead
                                  {...field}
                                  id="multiple-typeahead"
                                  clearButton
                                  labelKey="name"
                                  multiple
                                  allowNew
                                  options={typeaheadOptions}
                                  placeholder="Featuring ..."
                              />
                          )}
                      />
                    </FormGroup>

                    <FormGroup className="col-sm-3 mb-3">
                      <Label>Tags</Label>
                      <Controller
                          name="tags"
                          control={control}
                          render={({field}) => (
                              <Typeahead
                                  {...field}
                                  id="custom-typeahead"
                                  allowNew
                                  multiple
                                  placeholder="Tags..."
                                  newSelectionPrefix="Add a new item: "
                                  options={[]}
                                  onChange={(selected) => {
                                    field.onChange(selected);
                                    clearErrors('tags');
                                  }}
                              />
                          )}
                      />
                    </FormGroup>
                </Row>


                {/* <div className="mb-2">
                  <FormGroup>
                    <Label className="col-form-label">Other Artists</Label>
                    <Controller
                      name="otherArtists"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={Selectoptions}
                          className="js-example-basic-single col-sm-12"
                          placeholder="Featuring"
                          isMulti
                        />
                      )}
                    />
                  </FormGroup>
                      </div> */}

                <FormGroup className="mt-2">
                  <Label>Artwork</Label>
                  <Controller
                      name="artwork"
                      control={control}
                      /* rules={{ required: "Artwork is required" }} */
                      render={({field}) => (
                          <>
                            <input
                                className="input-group form-control col-sm-12"
                                type="file"
                                accept="image/*"
                                onChange={(e) => field.onChange(e.target.files[0])}
                            />
                            {/* {errors.artwork && <span className="text-danger">{errors.artwork.message}</span>} */}
                          </>
                      )}
                  />
                </FormGroup>

                {/* <ImageUpload /> */}
              </Form>
            </CardBody>
          </Card>
          </>
        )}
      </Col>
    </Fragment>
  );
};

export default StepOne;
