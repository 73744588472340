import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import BarcodeService from "../Services/barcode/BarcodeService";

const useBarcodeData = () => {
    return useQuery({
        queryKey: ["upcs"],
        queryFn: BarcodeService.getAllUPCs
    });
};

const useImportBarcode = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: BarcodeService.importData,
        onSuccess: () => {
            queryClient.invalidateQueries("upcs");
        },
    });
};

const useDeleteBarcode = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: BarcodeService.deleteUPC,
        onSuccess: () => {
            queryClient.invalidateQueries("upcs");
        },
    });
};

export { useBarcodeData, useImportBarcode, useDeleteBarcode };