import React, { useEffect } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { Controller } from "react-hook-form";

const AdditionalRowsModal = ({
                               showModal,
                               handleCloseModal,
                               selectedRows,
                               handleUpdate,
                               control,
                               setValue,
                             }) => {
  useEffect(() => {
    if (selectedRows) {
      setValue("id", selectedRows.id);
      setValue("name", selectedRows.name);
      setValue("url", selectedRows.url);
      setValue("logo", selectedRows.logo);
      setValue("territories", selectedRows.territories);
      setValue("description", selectedRows.description);
      setValue("lead_time", selectedRows.lead_time || 1);
      setValue("xml_file", selectedRows.xml_file);
      setValue("ftp_host", selectedRows.ftp_host);
      setValue("sftp_username", selectedRows.sftp_username);
      setValue("sftp_password", selectedRows.sftp_password);
      setValue("ssh_private_key", selectedRows.ssh_private_key);
      setValue("deal_terms", selectedRows.deal_terms || []);
    }
  }, [selectedRows, setValue]);

  if (!selectedRows) {
    return null;
  }

  return (
      <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title className="text-center">Update DSP</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row className="mb-3">
              <Col>
                <Form.Group controlId="name">
                  <Form.Label>Name</Form.Label>
                  <Controller
                      name="name"
                      control={control}
                      render={({ field }) => <Form.Control {...field} />}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="url">
                  <Form.Label>URL</Form.Label>
                  <Controller
                      name="url"
                      control={control}
                      render={({ field }) => <Form.Control {...field} />}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="logo">
                  <Form.Label>Logo</Form.Label>
                  <Controller
                      name="logo"
                      control={control}
                      render={({ field }) => <Form.Control type="text" {...field} />}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <Form.Group controlId="territories">
                  <Form.Label>Territories</Form.Label>
                  <Controller
                      name="territories"
                      control={control}
                      render={({ field }) => <Form.Control as="textarea" rows={3} {...field} />}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="lead_time">
                  <Form.Label>Lead Time</Form.Label>
                  <Controller
                      name="lead_time"
                      control={control}
                      render={({ field }) => (
                          <Form.Control as="select" {...field}>
                            {[...Array(90).keys()].map(day => (
                                <option key={day + 1} value={day + 1}>
                                  {day + 1} day{day + 1 > 1 ? 's' : ''}
                                </option>
                            ))}
                          </Form.Control>
                      )}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="xml_file">
                  <Form.Label>XML File</Form.Label>
                  <Controller
                      name="xml_file"
                      control={control}
                      render={({ field }) => <Form.Control {...field} />}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <Form.Group controlId="ftp_host">
                  <Form.Label>FTP Host</Form.Label>
                  <Controller
                      name="ftp_host"
                      control={control}
                      render={({ field }) => <Form.Control {...field} />}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="sftp_username">
                  <Form.Label>SFTP Username</Form.Label>
                  <Controller
                      name="sftp_username"
                      control={control}
                      render={({ field }) => <Form.Control {...field} />}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="sftp_password">
                  <Form.Label>SFTP Password</Form.Label>
                  <Controller
                      name="sftp_password"
                      control={control}
                      render={({ field }) => <Form.Control type="password" {...field} />}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="ssh_private_key">
                  <Form.Label>SSH Private Key</Form.Label>
                  <Controller
                      name="ssh_private_key"
                      control={control}
                      render={({ field }) => <Form.Control type="file" {...field} />}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group controlId="deal_terms" className="mb-3">
              <Form.Label>Deal Terms</Form.Label>
              <Row>
                {["SubscriptionModel", "AdvertisementSupportedModel", "Stream", "OnDemandStream", "ConditionalDownload", "NonInteractiveStream"].map(term => (
                    <Col key={term} xs={6} md={4}>
                      <Controller
                          name="deal_terms"
                          control={control}
                          render={({ field }) => (
                              <Form.Check
                                  type="checkbox"
                                  id={`deal_terms_${term}`}
                                  label={term}
                                  value={term}
                                  checked={field.value.includes(term)}
                                  onChange={(e) => {
                                    const newValue = e.target.checked
                                        ? [...field.value, term]
                                        : field.value.filter(t => t !== term);
                                    field.onChange(newValue);
                                  }}
                              />
                          )}
                      />
                    </Col>
                ))}
              </Row>
            </Form.Group>
            <Form.Group controlId="description" className="mb-3">
              <Form.Label>Description</Form.Label>
              <Controller
                  name="description"
                  control={control}
                  render={({ field }) => <Form.Control as="textarea" rows={5} {...field} />}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdate}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
  );
};

export default AdditionalRowsModal;