// SmartLinkProvider.js
import { create } from 'zustand';
import SmartLinkService from '../../Services/smartlink/SmartLinkService';

const useSmartLinkStore = create((set) => ({
  smartLinks: [],
  loading: false,
  error: null,
  productData: null,

  getAllSmartLinks: async () => {
    set({ loading: true });
    try {
      const { success, smartLinks, error } = await SmartLinkService.getAllSmartLinks();
      if (success) {
        set((state) => ({
          ...state,
          smartLinks: smartLinks, // Update smartLinks using the updater function
          loading: false,
          error: null,
        }));
      } else {
        set((state) => ({
          ...state,
          loading: false,
          error: error,
        }));
      }
    } catch (error) {
      console.error('Failed to fetch Smart Links', error);
      set((state) => ({
        ...state,
        loading: false,
        error: error,
      }));
    }
  },

  getProductByName: async (productName) => {
    set({ loading: true });
    try {
      const { success, productData, error } = await SmartLinkService.getProductByName(productName);
      if (success) {
        set({ productData: productData, loading: false, error: null });
      } else {
        set({ loading: false, error: error });
      }
    } catch (error) {
      set({ loading: false, error: error });
    }
  },


}));


export default useSmartLinkStore;
