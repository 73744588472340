import React from 'react';
import { useAuth } from '../../../_helper/Auth/AuthProvider';
import { Button } from "reactstrap";

const RedirectButton = () => {
    const { userData, loading, error, sttHc } = useAuth();
    const handleRedirect = async () => {
        if (userData && userData.password && userData.email) {
            window.open(`${process.env.REACT_APP_TICKET_URL}/auth/token?token=${sttHc}&email=${userData.email}`, '_blank', 'noopener,noreferrer');
        }
    };

    if (loading) {
        return <div style={{ textAlign: 'center', marginTop: '20px' }}>Loading...</div>;
    }

    if (error || !userData) {
        return <div style={{ textAlign: 'center', color: 'red', marginTop: '20px' }}>Unauthorized</div>;
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '50vh', backgroundColor: '#f5f5f5', padding: '20px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '8px', maxWidth: '400px', margin: 'auto', marginTop: '10vh' }}>
            <h2 style={{ color: '#333', marginBottom: '20px' }}>Connect Application</h2>
            <p style={{ color: '#666', marginBottom: '30px', textAlign: 'center' }}>Click the button below to connect to the application with your account.</p>
            <Button color="primary" onClick={handleRedirect} style={{ padding: '10px 20px', fontSize: '16px', borderRadius: '4px' }}>Connect</Button>
        </div>
    );
};

export default RedirectButton;