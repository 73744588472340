import React, { useState, useEffect } from 'react';
import Pusher from 'pusher-js';
import SvgIcon from '../../../Components/Common/Component/SvgIcon';
import { CHECKALL, DeliveryComplete, DeliveryProcessing, Notification, OrderComplete, TicketsGenerated } from '../../../Constant';
import { toast } from "react-hot-toast";


const Notificationbar = () => {
  const [notificationDropDown, setNotificationDropDown] = useState(false);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
    });

    const channel = pusher.subscribe('my-channel');

    channel.bind('my-event', data => {
      const newNotification = { message: data.message, read: false };
      setNotifications(prevNotifications => [...prevNotifications, newNotification]);
      toast.info(data.message);
    });

    return () => {
      channel.unbind('my-event');
      pusher.unsubscribe('my-channel');
    };
  }, []);

  useEffect(() => {
    localStorage.setItem('notifications', JSON.stringify(notifications));
  }, [notifications]);

  const markNotificationAsRead = index => {
    const updatedNotifications = [...notifications];
    updatedNotifications[index].read = true;
    setNotifications(updatedNotifications);
  };

  const clearAllNotifications = () => {
    setNotifications([]);
  };

  return (
    <li className='onhover-dropdown'>
      <div className='notification-box' onClick={() => setNotificationDropDown(!notificationDropDown)}>
        <SvgIcon iconId='notification' />
        <span className='badge rounded-pill badge-secondary'>{notifications.length}</span>
      </div>
      <div className={`notification-dropdown onhover-show-div ${notificationDropDown ? 'active' : ''}`}>
        <h6 className='f-18 mb-0 dropdown-title'>{Notification}</h6>
        <ul>
          {notifications.length === 0 ? (
            <li>
              <p className="text-center">You have no notifications</p>
            </li>
          ) : (
            notifications.map((notification, index) => (
              <li key={index} className={`b-l-primary border-4 ${notification.read ? 'read' : ''}`} onClick={() => markNotificationAsRead(index)}>
                <p>{notification.message}</p>
              </li>
            ))
          )}
          {notifications.length > 0 && (
            <li>
              <button className='f-w-700' onClick={clearAllNotifications}>
                {CHECKALL}
              </button>
            </li>
          )}
        </ul>
      </div>
    </li>
  );
};

export default Notificationbar;
