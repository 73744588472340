import React, { Fragment, useState } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { toast } from "react-hot-toast";
import CustomizerContext from '../../../../_helper/Customizer';
import { useAddDsp } from "../../../../Hooks/useDspData";

const AddDspModal = ({ show, handleClose }) => {
  const { mix_background_layout } = React.useContext(CustomizerContext);
  const inputStyle = { backgroundColor: 'light-only' };
  const [newDspData, setNewDspData] = useState({
    name: '',
    url: '',
    logo: '',
    status: 'active',
    description: '',
    territories: '',
    lead_time: 1,
    xml_file: '',
    ftp_host: '',
    sftp_username: '',
    sftp_password: '',
    ssh_private_key: null,
    deal_terms: []
  });

  const addDspMutation = useAddDsp();

  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    if (type === 'checkbox') {
      setNewDspData(prevState => ({
        ...prevState,
        deal_terms: checked
            ? [...prevState.deal_terms, value]
            : prevState.deal_terms.filter(term => term !== value)
      }));
    } else if (type === 'file') {
      setNewDspData({ ...newDspData, [name]: files[0] });
    } else {
      setNewDspData({ ...newDspData, [name]: value });
    }
  };

  const handleSubmit = async () => {
    const requiredFields = ['name', 'url', 'logo', 'territories', 'description', 'lead_time'];
    const hasEmptyField = requiredFields.some((field) => !newDspData[field]);

    if (hasEmptyField) {
      toast.error('Please fill in all fields.');
      return;
    }
    try {
      const response = await addDspMutation.mutateAsync(newDspData);
      if (response) {
        toast.success("DSP added successfully!");
        handleClose();
      } else {
        toast.error("Failed to add DSP. Please try again.");
      }
    } catch (error) {
      toast.error("Failed to add DSP. Please try again.");
    }
  };

  return (
      <Fragment>
        <Modal show={show} onHide={handleClose} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Add New DSP</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form className='theme-form'>
              <Row className="mb-3">
                <Col>
                  <Form.Group className="form-group" controlId="name">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter name"
                        name="name"
                        value={newDspData.name}
                        onChange={handleChange}
                        required
                        style={inputStyle}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="form-group" controlId="url">
                    <Form.Label>URL</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter URL"
                        name="url"
                        value={newDspData.url}
                        onChange={handleChange}
                        required
                        style={inputStyle}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="form-group" controlId="logo">
                    <Form.Label>Logo</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter logo URL"
                        name="logo"
                        value={newDspData.logo}
                        onChange={handleChange}
                        required
                        style={inputStyle}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <Form.Group className="form-group" controlId="territories">
                    <Form.Label>Territories</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Enter territories"
                        name="territories"
                        value={newDspData.territories}
                        onChange={handleChange}
                        required
                        style={inputStyle}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="form-group" controlId="lead_time">
                    <Form.Label>Lead Time</Form.Label>
                    <Form.Control
                        as="select"
                        name="lead_time"
                        value={newDspData.lead_time}
                        onChange={handleChange}
                        required
                        style={inputStyle}
                    >
                      {[...Array(90).keys()].map(day => (
                          <option key={day + 1} value={day + 1}>{day + 1} days</option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="form-group" controlId="xml_file">
                    <Form.Label>XML File</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter XML file URL"
                        name="xml_file"
                        value={newDspData.xml_file}
                        onChange={handleChange}
                        style={inputStyle}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <Form.Group className="form-group" controlId="ftp_host">
                    <Form.Label>FTP Host</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter FTP Host"
                        name="ftp_host"
                        value={newDspData.ftp_host}
                        onChange={handleChange}
                        style={inputStyle}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="form-group" controlId="sftp_username">
                    <Form.Label>SFTP Username</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter SFTP Username"
                        name="sftp_username"
                        value={newDspData.sftp_username}
                        onChange={handleChange}
                        style={inputStyle}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="form-group" controlId="sftp_password">
                    <Form.Label>SFTP Password</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder="Enter SFTP Password"
                        name="sftp_password"
                        value={newDspData.sftp_password}
                        onChange={handleChange}
                        style={inputStyle}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="form-group" controlId="ssh_private_key">
                    <Form.Label>SSH Private Key</Form.Label>
                    <Form.Control
                        type="file"
                        name="ssh_private_key"
                        onChange={handleChange}
                        style={inputStyle}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group className="form-group" controlId="deal_terms">
                <Form.Label>Deal Terms</Form.Label>
                <Row>
                  {["SubscriptionModel", "AdvertisementSupportedModel", "Stream", "OnDemandStream", "ConditionalDownload", "NonInteractiveStream"].map(term => (
                      <Col key={term} xs={6} md={4}>
                        <Form.Check
                            type="checkbox"
                            id={`deal_terms_${term}`}
                            label={term}
                            name="deal_terms"
                            value={term}
                            checked={newDspData.deal_terms.includes(term)}
                            onChange={handleChange}
                        />
                      </Col>
                  ))}
                </Row>
              </Form.Group>
              <Form.Group className="form-group" controlId="description">
                <Form.Label>Description</Form.Label>
                <Form.Control
                    as="textarea"
                    rows={5}
                    placeholder="Enter description"
                    name="description"
                    value={newDspData.description}
                    onChange={handleChange}
                    required
                    style={inputStyle}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleSubmit}>
              Save DSP
            </Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
  );
};

export default AddDspModal;