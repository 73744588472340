import React, { Fragment, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { H5, Btn } from "../../../../AbstractElements";
import {
  Email,
  Password,
  Admin,
  Artist,
  Label as labelrole,
  Staff,
  CreateNewUser,
  User_Name,
  User_FullName,
  Username,
  Submit,
} from "../../../../Constant";
import ArtistFields from "./ArtistFields";
import { Controller } from "react-hook-form";
import { useAuth } from "../../../../_helper/Auth/AuthProvider";
import GridLoader from "../../../Loaders/GridLoader";
import CustomizerContext from "../../../../_helper/Customizer";
const FormLayout = ({
  register,
  errors,
  handleSubmit,
  loading,
  watch,
  control,
  isAdmin,
}) => {

  const [customizeLabel, setCustomizeLabel] = useState(false);
  const [artistsChoice, setartistsChoice] = useState(false);
  const { userData } = useAuth();
  const { mix_background_layout} = React.useContext(CustomizerContext);
  
  const textColorClass = 'text-dark';
  //const Logo = watch("logo");

  const handleRoleChange = (event) => {
    const role = event.target.value;
    setCustomizeLabel(event.target.value === "label");
    setCustomizeLabel(role === "label");
    if (role === "label") {
      const confirmCustomization = window.confirm(
        "Do you want to customize the label?"
      );
      setCustomizeLabel(confirmCustomization);
    }
    if (role === "artist") {
      setartistsChoice(true);
    } else {
      setartistsChoice(false);
    }
  };
  /*
  const validateLogo = (Logo) => {
    const validTypes = ["image/jpeg", "image/png", "image/gif"];
    if (Logo && Logo[0]) {
      const isCorrectType = validTypes.includes(Logo[0].type);
      const isCorrectSize = Logo[0].size <= 5000000; // 5 MB
      return (
        (isCorrectType && isCorrectSize) ||
        "Logo should be an image and less than 5MB"
      );
    }
    return true;
  };
  */

  return (
    <Fragment>
      <Col sm="12">
        <Card>
          <CardHeader>
            <H5>{CreateNewUser}</H5>
            <span>{"Carefully fill out the form and create a new user"}</span>
          </CardHeader>
          <CardBody>
            {loading ? (
              <GridLoader/>
            ) : (
                <Form className="theme-form" onSubmit={handleSubmit}>
                  <div className="row">
                    <FormGroup className="col-sm-4 mb-3">
                      <Label className="col-form-label col-sm-3"><span className={textColorClass}>{Email}</span></Label>
                      <Col sm="12">
                        <input
                            className="form-control"
                            type="email"
                            placeholder="Email"
                            {...register("email", {required: true})}
                        />
                        <span style={{color: "red"}}>
                          {errors.email && "Email is required"}
                        </span>
                      </Col>
                    </FormGroup>

                    <FormGroup className="col-sm-4 mb-3">
                      <Label className="col-form-label col-sm-3">
                        <span className={textColorClass}> {User_FullName}</span>
                      </Label>
                      <Col sm="12">
                        <input
                            className="form-control"
                            type="text"
                            placeholder="Fullname"
                            {...register("fullname", {required: true})}
                        />
                        <span style={{color: "red"}}>
                          {errors.fullname && "Fullname is required"}
                        </span>
                      </Col>
                    </FormGroup>

                    <FormGroup className="col-sm-4 mb-3">
                      <Label className="col-form-label col-sm-3">
                        <span className={textColorClass}> {User_Name}</span>
                      </Label>
                      <Col sm="12">
                        <input
                            className="form-control"
                            type="text"
                            placeholder="Name"
                            {...register("name", {required: true})}
                        />
                        <span style={{color: "red"}}>
                          {errors.name && "Name is required"}
                        </span>
                      </Col>
                    </FormGroup>
                  </div>

                  <div className="row">
                    <FormGroup className="col-sm-4 mb-3">
                      <Label className="col-sm-3 col-form-label">
                        <span className={textColorClass}> {Username}</span>
                      </Label>
                      <Col sm="12">
                        <input
                            className="form-control"
                            type="text"
                            placeholder="Username"
                            {...register("username", {required: true})}
                        />
                        <span style={{color: "red"}}>
                          {errors.username && "Username is required"}
                        </span>
                      </Col>
                    </FormGroup>

                    <FormGroup className="col-sm-4 mb-3">
                      <Label className="col-form-label">
                        <span className={textColorClass}>Phone Number</span>
                      </Label>
                      <Col sm="12">
                        <input
                            className="form-control"
                            type="text"
                            placeholder="Phone Number"
                            {...register("phonenumber", { required: true })}
                        />
                        {errors.phonenumber && (
                            <span style={{ color: "red", display: "block", marginTop: "5px" }}>
                              Phone Number is required
                            </span>
                        )}
                      </Col>
                    </FormGroup>

                    <FormGroup className="col-sm-4 mb-3">
                      <Label className="col-sm-3 col-form-label">
                        <span className={textColorClass}> {Password}</span>
                      </Label>
                      <Col sm="12">
                        <input
                            className="form-control"
                            type="password"
                            placeholder="Password"
                            {...register("password", {required: true})}
                        />
                        <span style={{color: "red"}}>
                          {errors.password && "Password is required"}
                        </span>
                      </Col>
                    </FormGroup>
                  </div>


                  <Fragment>
                    <p className="text-muted mb-2">Optional Fields:</p>
                    <div className="row">
                      <FormGroup className="col-sm-4 mb-3">
                        <Label className="col-form-label"> <span className={textColorClass}>Host</span></Label>
                        <Col sm="12">
                          <input
                              className="form-control"
                              type="text"
                              placeholder="Host"
                              {...register("host")}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="col-sm-4 mb-3">
                        <Label className="col-form-label"> <span className={textColorClass}>SFTP Username</span></Label>
                        <Col sm="12">
                          <input
                              className="form-control"
                              type="text"
                              placeholder="SFTP Username"
                              {...register("sftp_username")}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="col-sm-4 mb-3">
                        <Label className="col-form-label"> <span className={textColorClass}>SFTP Password</span></Label>
                        <Col sm="12">
                          <input
                              className="form-control"
                              type="password"
                              placeholder="SFTP Password"
                              {...register("sftp_password")}
                          />
                        </Col>
                      </FormGroup>
                    </div>
                  </Fragment>


                  <FormGroup className="mt-4">
                    <Row>
                      <Label className="col-form-label col-sm-3 pt-0">
                        <span className={textColorClass}>User Type</span>
                      </Label>
                      <Col sm="9">
                        <div className="d-flex align-items-center">
                          <div className="radio radio-primary ms-2 me-4">
                            <input
                                type="radio"
                                name="role"
                                id="radio1"
                                value="artist"
                                {...register("role", { required: true })}
                                onChange={handleRoleChange}
                            />
                            <Label for="radio1">
                              <span className={textColorClass}>{Artist}</span>
                            </Label>
                          </div>

                          <div className="radio radio-primary ms-2 me-4">
                            <input
                                type="radio"
                                name="role"
                                id="radio3"
                                value="staff"
                                {...register("role")}
                                onChange={handleRoleChange}
                            />
                            <Label for="radio3">
                              <span className={textColorClass}>{Staff}</span>
                            </Label>
                          </div>

                          {userData.role === "admin" && (
                              <>
                                <div className="radio radio-primary ms-2 me-4">
                                  <input
                                      type="radio"
                                      name="role"
                                      id="radio4"
                                      value="admin"
                                      {...register("role")}
                                      onChange={handleRoleChange}
                                  />
                                  <Label for="radio4">
                                    <span className={textColorClass}>{Admin}</span>
                                  </Label>
                                </div>
                                <div className="radio radio-primary ms-2 me-4">
                                  <input
                                      type="radio"
                                      name="role"
                                      id="radio2"
                                      value="label"
                                      {...register("role")}
                                      onChange={handleRoleChange}
                                  />
                                  <Label for="radio2">
                                    <span className={textColorClass}>{labelrole}</span>
                                  </Label>
                                </div>
                              </>
                          )}
                        </div>
                        <span style={{ color: "red" }}>
                          {errors.role && "User Type is required"}
                        </span>
                      </Col>
                    </Row>
                  </FormGroup>

                  {artistsChoice && (
                      <ArtistFields
                          register={register}
                          errors={errors}
                          isAdmin={isAdmin}
                      />
                  )}

                  {customizeLabel && (
                      <Fragment>

                        <FormGroup className="row mb-3">
                          <Label className="col-sm-3 col-form-label">
                            <span className={textColorClass}>Label Name</span>
                          </Label>
                          <Col sm="9">
                            <Controller
                                name="label_name"
                                control={control}
                                defaultValue=""
                                rules={{ required: "Label Name is required" }}
                                render={({ field }) => (
                                    <Input
                                        type="text"
                                        placeholder="Label Name"
                                        {...field}
                                    />
                                )}
                            />
                            {errors.label_name && (
                                <p style={{ color: "red", marginTop: "5px" }}>
                                  {errors.label_name.message}
                                </p>
                            )}
                          </Col>
                        </FormGroup>

                        <FormGroup className="row mb-3">
                          <Label className="col-sm-3 col-form-label">
                            <span className={textColorClass}>ISRC</span>
                          </Label>
                          <Col sm="9">
                            <Controller
                                name="isrc_code"
                                control={control}
                                rules={{
                                  required: "ISRC Code is required",
                                  pattern: {
                                    value: /^[A-Z]{2}-\d{3}-\d{2}-\d{5}$/,
                                    message: "Invalid ISRC Code format eg TN-061-24-00000",
                                  },
                                }}
                                render={({ field }) => (
                                    <>
                                      <Input
                                          type="text"
                                          placeholder="ISRC Code"
                                          {...field}
                                      />
                                      {errors.isrc_code && (
                                          <p style={{ color: "red", marginTop: "5px" }}>
                                            {errors.isrc_code.message}
                                          </p>
                                      )}
                                    </>
                                )}
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup className="row mb-2">
                          <Label className="col-sm-3 col-form-label">
                            <span className={textColorClass}>Label Theme</span>
                          </Label>
                          <Col sm="9">
                            <Row>
                              <Col sm="3">
                                <Label className="col-form-label mb-1">
                                  <span className={textColorClass}>Primary Color</span>
                                </Label>
                                <Controller
                                    name="color1"
                                    control={control}
                                    defaultValue="#563d7c"
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <Input
                                            className="form-control-color"
                                            type="color"
                                            {...field}
                                        />
                                    )}
                                />
                              </Col>
                              <Col sm="3">
                                <Label className="col-form-label mb-1">
                                  <span className={textColorClass}>Secondary Color</span>
                                </Label>
                                <Controller
                                    name="color2"
                                    control={control}
                                    defaultValue="#563d7c"
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <Input
                                            className="form-control-color"
                                            type="color"
                                            {...field}
                                        />
                                    )}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </FormGroup>

                        <FormGroup className="row mb-3">
                          <Label className="col-sm-3 col-form-label">
                            <span className={textColorClass}>Logo</span>
                          </Label>
                          <Col sm="9">
                            <Input className="form-control" type="file" />
                            {errors.label_image && (
                                <p style={{ color: "red", marginTop: "5px" }}>
                                  {errors.label_image.message}
                                </p>
                            )}
                          </Col>
                        </FormGroup>

                        <FormGroup className="row mb-3">
                          <Label className="col-sm-3 col-form-label">
                            <span className={textColorClass}>Label Background</span>
                          </Label>
                          <Col sm="9">
                            <div className="d-flex align-items-center">
                              <div className="me-3">
                                <Input className="form-control" type="file" />
                              </div>
                              <span className="mx-2">or</span>
                              <div>
                                <Controller
                                    name="background_color"
                                    control={control}
                                    defaultValue="#ffffff"
                                    render={({ field }) => (
                                        <Input
                                            className="form-control-color"
                                            type="color"
                                            {...field}
                                        />
                                    )}
                                />
                              </div>
                            </div>
                          </Col>
                        </FormGroup>

                        <FormGroup className="row mb-3 align-items-center">
                          <Label className="col-sm-3 col-form-label">
                            <span className={textColorClass}>Have Ticket Account</span>
                          </Label>
                          <Col sm="9">
                            <div className="d-flex align-items-center">
                              <div className="radio radio-primary ms-2 me-4">
                                <input
                                    type="radio"
                                    name="isHC"
                                    id="radio_yes"
                                    value="true"
                                    {...register("isHC", { required: true })}
                                />
                                <Label for="radio_yes">
                                  <span className={textColorClass}>Yes</span>
                                </Label>
                              </div>
                              <div className="radio radio-primary ms-2 me-4">
                                <input
                                    type="radio"
                                    name="isHC"
                                    id="radio_no"
                                    value="false"
                                    defaultChecked
                                    {...register("isHC", { required: true })}
                                />
                                <Label for="radio_no">
                                  <span className={textColorClass}>No</span>
                                </Label>
                              </div>
                            </div>
                            {errors.isHC && (
                                <p style={{ color: "red", marginTop: "5px" }}>
                                  Have Ticket Account is required
                                </p>
                            )}
                          </Col>
                        </FormGroup>

                       


                      </Fragment>
                  )}

                  {(artistsChoice || customizeLabel) && (
                          <Fragment>
                            <hr />
                            <h5 className="mb-4">Additional Settings</h5>

                            <FormGroup className="row mb-3 align-items-center">
                              <Label className="col-sm-3 col-form-label">
                                <span className={textColorClass}>Picker Date Blocker</span>
                              </Label>
                              <Col sm="9">
                                <input
                                  className="form-control"
                                  type="number"
                                  placeholder="Picker Date Blocker"
                                  {...register("pickerdateblocker", {
                                    min: 0,
                                    pattern: /^\d+$/,
                                    validate: (value) => {
                                      if (value && isNaN(Number(value))) {
                                        return "Please enter a valid number";
                                      }
                                      return true;
                                    }
                                  })}
                                />
                                {errors.pickerdateblocker && (
                                  <span style={{ color: "red" }}>
                                    {errors.pickerdateblocker.message || "Invalid input"}
                                  </span>
                                )}
                              </Col>
                            </FormGroup>

                            <FormGroup className="row mb-3 align-items-center">
                              <Label className="col-sm-3 col-form-label">
                                <span className={textColorClass}>Products per Month</span>
                              </Label>
                              <Col sm="9">
                                <input
                                  className="form-control"
                                  type="number"
                                  placeholder="Number of Products per Month"
                                  {...register("numberofproductspermonth", { 
                                    min: 0, 
                                    pattern: /^\d+$/ 
                                  })}
                                />
                                {errors.numberofproductspermonth && (
                                  <span style={{ color: "red" }}>
                                    Please enter a valid number
                                  </span>
                                )}
                              </Col>
                            </FormGroup>

                            <FormGroup className="row mb-3 align-items-center">
                              <Label className="col-sm-3 col-form-label">
                                <span className={textColorClass}>Has Tools Access</span>
                              </Label>
                              <Col sm="9">
                                <div className="d-flex align-items-center">
                                  <div className="radio radio-primary ms-2 me-4">
                                    <input
                                      type="radio"
                                      name="isTools"
                                      id="radio_tools_yes"
                                      value="true"
                                      {...register("isTools")}
                                    />
                                    <Label for="radio_tools_yes">
                                      <span className={textColorClass}>Yes</span>
                                    </Label>
                                  </div>
                                  <div className="radio radio-primary ms-2 me-4">
                                    <input
                                      type="radio"
                                      name="isTools"
                                      id="radio_tools_no"
                                      value="false"
                                      defaultChecked
                                      {...register("isTools")}
                                    />
                                    <Label for="radio_tools_no">
                                      <span className={textColorClass}>No</span>
                                    </Label>
                                  </div>
                                </div>
                              </Col>
                            </FormGroup>

                            <FormGroup className="row mb-3 align-items-center">
                              <Label className="col-sm-3 col-form-label">
                                <span className={textColorClass}>YouTube Analytics</span>
                              </Label>
                              <Col sm="9">
                                <div className="d-flex align-items-center">
                                  <div className="radio radio-primary ms-2 me-4">
                                    <input
                                      type="radio"
                                      name="isYoutubeAnalytics"
                                      id="radio_youtube_yes"
                                      value="true"
                                      {...register("isYoutubeAnalytics")}
                                    />
                                    <Label for="radio_youtube_yes">
                                      <span className={textColorClass}>Yes</span>
                                    </Label>
                                  </div>
                                  <div className="radio radio-primary ms-2 me-4">
                                    <input
                                      type="radio"
                                      name="isYoutubeAnalytics"
                                      id="radio_youtube_no"
                                      value="false"
                                      defaultChecked
                                      {...register("isYoutubeAnalytics")}
                                    />
                                    <Label for="radio_youtube_no">
                                      <span className={textColorClass}>No</span>
                                    </Label>
                                  </div>
                                </div>
                              </Col>
                            </FormGroup>

                            <FormGroup className="row mb-3 align-items-center">
                              <Label className="col-sm-3 col-form-label">
                                <span className={textColorClass}>Uploader Access</span>
                              </Label>
                              <Col sm="9">
                                <div className="d-flex align-items-center">
                                  <div className="radio radio-primary ms-2 me-4">
                                    <input
                                      type="radio"
                                      name="isUploader"
                                      id="radio_uploader_yes"
                                      value="true"
                                      {...register("isUploader")}
                                    />
                                    <Label for="radio_uploader_yes">
                                      <span className={textColorClass}>Yes</span>
                                    </Label>
                                  </div>
                                  <div className="radio radio-primary ms-2 me-4">
                                    <input
                                      type="radio"
                                      name="isUploader"
                                      id="radio_uploader_no"
                                      value="false"
                                      defaultChecked
                                      {...register("isUploader")}
                                    />
                                    <Label for="radio_uploader_no">
                                      <span className={textColorClass}>No</span>
                                    </Label>
                                  </div>
                                </div>
                              </Col>
                            </FormGroup>
                          </Fragment>
                  )}

                  <hr/>
                  <FormGroup className="row text-center">
                    <Col md={{size: 6, offset: 3}}>
                      <Btn
                          attrBtn={{
                            color: "primary",
                            className: "m-r-15",
                            type: "submit",
                            style: {
                              padding: "10px 20px",
                              fontSize: "16px",
                            },
                          }}
                      >
                        {loading ? "Loading..." : Submit}
                      </Btn>
                    </Col>
                  </FormGroup>
                </Form>
            )}
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};

export default FormLayout;
