import React, { Fragment, useState } from "react";
import {Container, Row, Col, Card, CardBody, Button, Tooltip, Spinner} from "reactstrap";
import { Breadcrumbs, Btn } from "../../../../AbstractElements";
import HeaderCard from "../../../Common/Component/HeaderCard";
import AddWhitelistModal from "./AddWhitelistModal";
import UpdateWhitelistModal from "./UpdateWhitelistModal";
import DataTable from "react-data-table-component";
import EmptyDataTable from "../../../Dashboard/Common/EmptyDataTable";
import { useWhitelistData, useDeleteWhitelist } from "../../../../Hooks/useWhitelistData";
import moment from "moment";
import { Edit, Trash2 } from "react-feather";
import { toast } from "react-hot-toast";

const WhitelistList = () => {
    const { data: whitelists, isLoading, isError } = useWhitelistData();
    const deleteWhitelistMutation = useDeleteWhitelist();
    const [showAddModal, setShowAddModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [selectedWhitelist, setSelectedWhitelist] = useState(null);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [tooltipTarget, setTooltipTarget] = useState(null);
    const [tooltipUrls, setTooltipUrls] = useState([]);

    const handleShowAddWhitelistModal = () => {
        setShowAddModal(true);
    };

    const handleShowUpdateWhitelistModal = (whitelist) => {
        setSelectedWhitelist(whitelist);
        setShowUpdateModal(true);
    };

    const handleDeleteWhitelist = (id) => {
        return new Promise((resolve, reject) => {
            deleteWhitelistMutation.mutate(id, {
                onSuccess: () => {
                    resolve();
                },
                onError: (error) => {
                    reject(error);
                },
            });
        });
    };

    const confirmDeleteWhitelist = (id) => {
        toast((t) => (
            <span>
                Are you sure you want to delete this whitelist?
                <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center', gap: '10px' }}>
                    <Button color="danger" size="sm" style={{ padding: '5px 10px' }} onClick={() => {
                        toast.promise(
                            handleDeleteWhitelist(id),
                            {
                                loading: 'Deleting whitelist...',
                                success: 'Whitelist deleted successfully!',
                                error: 'Failed to delete whitelist.',
                            }
                        ).then(() => {
                            toast.dismiss(t.id);
                        });
                    }}>
                        Yes
                    </Button>
                    <Button color="secondary" size="sm" style={{ padding: '5px 10px' }} onClick={() => toast.dismiss(t.id)}>
                        No
                    </Button>
                </div>
            </span>
        ), {
            duration: Infinity
        });
    };

    const toggleTooltip = (target, urls) => {
        setTooltipTarget(target);
        setTooltipUrls(urls);
        setTooltipOpen(!tooltipOpen);
    };

    const columns = [
        { name: "ID", selector: row => row.id, sortable: true },
        { name: "Artist ID", selector: row => row.artist_id, sortable: true },
        { name: "Name", selector: row => row.name, sortable: true },
        { name: "YT URL", selector: row => row.yt_url, sortable: true },
        { name: "Status", selector: row => row.status, sortable: true },
        { name: "Created At", selector: row => moment(row.created_at).format("YYYY-MM-DD HH:mm:ss"), sortable: true },
        {
            name: "Actions",
            cell: row => (
                <div>
                    <Button color="warning" size="sm" onClick={() => handleShowUpdateWhitelistModal(row)}>
                        <Edit size={16} />
                    </Button>
                    <Button color="danger" size="sm" onClick={() => confirmDeleteWhitelist(row.id)}>
                        <Trash2 size={16} />
                    </Button>
                </div>
            )
        }
    ];

    if (isLoading) {
        return <Col className="d-flex justify-content-center">
            <Spinner color="primary" />
        </Col>;
    }

    if (isError) {
        return <div>Error loading whitelists</div>;
    }

    return (
        <Fragment>
            <Breadcrumbs
                parent="Whitelist Management"
                title="Whitelists"
                mainTitle="Whitelist Data"
                buttons={[
                    <Btn
                        attrBtn={{
                            size: "sm",
                            color: "primary",
                            onClick: handleShowAddWhitelistModal,
                        }}
                    >
                        <i className="fa fa-plus me-1"></i>Add Whitelist
                    </Btn>,
                ]}
            />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <HeaderCard title="Manage your Whitelists" />
                            <CardBody>
                                {whitelists.data.length > 0 ? (
                                    <DataTable
                                        columns={columns}
                                        data={whitelists.data}
                                        pagination
                                    />
                                ) : (
                                    <EmptyDataTable />
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <AddWhitelistModal
                show={showAddModal}
                handleClose={() => setShowAddModal(false)}
            />
            <UpdateWhitelistModal
                show={showUpdateModal}
                handleClose={() => setShowUpdateModal(false)}
                whitelist={selectedWhitelist}
            />
        </Fragment>
    );
};

export default WhitelistList;