import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import ClaimService from "../Services/tools/ClaimService";

const useClaimData = () => {
  return useQuery({
    queryKey: ["claims"],
    queryFn: ClaimService.getAllClaims,
  });
};

const useAddClaim = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ClaimService.createClaim,
    onSuccess: () => {
      queryClient.invalidateQueries("claims");
    },
    onError: (error) => {
      console.error("Failed to add claim", error);
    },
  });
};

const useUpdateClaim = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ claimId, updatedClaimData }) => ClaimService.updateClaim(claimId, updatedClaimData),
    onSuccess: () => {
      queryClient.invalidateQueries("claims");
    },
    onError: (error) => {
      console.error("Failed to update claim", error);
    },
  });
};

const useDeleteClaim = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ClaimService.deleteClaim,
    onSuccess: () => {
      queryClient.invalidateQueries("claims");
    },
    onError: (error) => {
      console.error("Failed to delete claim", error);
    },
  });
};

export { useClaimData, useAddClaim, useUpdateClaim, useDeleteClaim };