import axios from "../../api/axios";

const TagsService = {
  generateTags: async (keyword, type) => {
    try {
      const response = await axios.post("/tags", { keyword, type });
      return { success: true, data: response.data };
    } catch (error) {
      console.error("Failed to generate tags", error);
      throw error;
    }
  },
};

export default TagsService;