import React, { useContext } from 'react';
import { Grid } from 'react-feather';
import { Link } from 'react-router-dom';
import { Image } from '../../AbstractElements';
//import CubaIcon from '../../assets/images/logo/logo.png';
import RedIcon from '../../assets/images/logo/logo_red.svg';
import WhiteIcon from '../../assets/images/logo/logo_white.svg';
import CustomizerContext from '../../_helper/Customizer';
import { useAuth } from '../../_helper/Auth/AuthProvider';
import { getLabelImageUrl } from '../../_helper/Images/ImageHelper';
const SidebarLogo = () => {
  const { mixLayout, toggleSidebar, toggleIcon, layout, layoutURL } = useContext(CustomizerContext);
  const { userData } = useAuth();
  const openCloseSidebar = () => {
    toggleSidebar(!toggleIcon);
  };

  const layout1 = localStorage.getItem("sidebar_layout") || layout;
  const imageUrl = userData && userData.label && userData.label.label_image ? getLabelImageUrl(userData.label.label_image) : RedIcon;
  // console.log("imageUrl", imageUrl);
  return (
    <div className='logo-wrapper' >
{/*       {layout1 !== 'compact-wrapper dark-sidebar' && layout1 !== 'compact-wrapper color-sidebar' && mixLayout ? (
        <Link to={`${process.env.PUBLIC_URL}/dashboard/default/${layoutURL}`}>
          <Image attrImage={{ className: 'img-fluid d-inline custom-logo', src: `${RedIcon}`, alt: '' }}  />
        </Link>
      ) : (
        <Link to={`${process.env.PUBLIC_URL}/dashboard/default/${layoutURL}`}>
          <Image attrImage={{ className: 'img-fluid d-inline custom-logo', src: `${WhiteIcon}`, alt: '' }}  />
        </Link>
      )} */}
      <Link to={`${process.env.PUBLIC_URL}/dashboard/default/${layoutURL}`}>
        <Image 
          attrImage={{ 
            className: 'img-fluid d-inline custom-logo', 
            src: `${imageUrl}`, 
            alt: '', 
            style: {
              maxWidth: '150px',  
              maxHeight: '70px', 
              objectFit: 'contain', 
              width: 'auto', 
              height: 'auto'
            }
          }} 
        />
      </Link>
     {/*  <div className='back-btn' onClick={() => openCloseSidebar()}>
        <i className='fa fa-angle-left'></i>
      </div> */}
     {/*  <div className='toggle-sidebar' onClick={openCloseSidebar}>
        <Grid className='status_toggle middle sidebar-toggle' />
      </div> */}
    </div>
  );
};

export default SidebarLogo;
