import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap";
import { Btn } from "../../../../../../AbstractElements";
import Contributors from "../../../NewRelease/Tabs/Contributors";
import Files from "../../../NewRelease/Tabs/Files";
import { useFormContext } from "react-hook-form";
import { toast } from "react-hot-toast";
import MetadataModal from "./addAssetComponents/MetadataModal";
import { useAddRelease } from "../../../../../../Hooks/useReleaseData";
import Metadata from "../../../NewRelease/Tabs/Metadata";

export default function NewAsset({
                                   show,
                                   handleClose,
                                   genres,
                                   artists,
                                   i,
                                   productId,
                                   onNewAsset,
                                   resetField,
                                 }) {
  const { control, errors, setValue, clearErrors, getValues, trigger, reset } = useFormContext();
  const [activeTab, setActiveTab] = useState("1");
  const [formGroups, setFormGroups] = useState([]);
  const { mutate: addRelease, isLoading } = useAddRelease();

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const handleAddAsset = async () => {
    const isValid = await trigger();
    if (!isValid) {
      toast.error("Please make sure you at least provide the title & artist.");
      return;
    }
    const formData = getValues();
    const filteredData = Object.fromEntries(
        Object.entries(formData).filter(([_, value]) => value !== undefined)
    );

    console.log("formdata", formData)

    const cardsData = [0].map((cardIndex) => {
      const card = {
        title: filteredData[`title_${cardIndex}`],
        artist: filteredData[`artist_${cardIndex}`],
        audio_language: filteredData[`audio_language_${cardIndex}`],
        volume: filteredData[`volume_${cardIndex}`],
        metadata_language: filteredData[`metadata_language_${cardIndex}`],
        preview_text: filteredData[`preview_text_${cardIndex}`],
        preview_length: filteredData[`preview_length_${cardIndex}`],
        preview_start: filteredData[`preview_start_${cardIndex}`],
        lyrics: filteredData[`lyrics_${cardIndex}`],
        audio_file: filteredData[`audio_file_${cardIndex}`],
        type: filteredData[`type_${cardIndex}`],
        class: filteredData[`genres_${cardIndex}`],
        explicit_content: filteredData[`explicit_content_${cardIndex}`],
        others_artists: filteredData[`others_artists_${cardIndex}`],
        copyrightYear: filteredData[`copyrightYear_${cardIndex}`],
        asset_rights_claim: filteredData[`asset_rights_claim_${cardIndex}`],
        contributors: formGroups.map((_, i) => ({
          name: filteredData[`contributor_name_0_${i}`],
          role: filteredData[`contributor_role_0_${i}`],
          percentage: filteredData[`contributor_percentage_0_${i}`],
        })).filter(contributor => contributor.name && contributor.role && contributor.percentage),
      };
      return card;
    });

    const data = { productId, cards: cardsData };

    addRelease(data, {
      onSuccess: () => {
        toast.success("New Asset is Added!");
        reset();
        handleClose();
      },
      onError: () => {
        toast.error("An error occurred while creating your product asset.");
      },
    });
  };

  return (
      <Modal isOpen={show} toggle={handleClose} size="lg">
        <ModalHeader toggle={handleClose}>Add New Asset</ModalHeader>
        <ModalBody>
          <>
            <Nav tabs>
              <NavItem>
                <NavLink
                    className={activeTab === "1" ? "active" : ""}
                    onClick={() => {
                      toggle("1");
                    }}
                >
                  Metadata
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                    className={activeTab === "2" ? "active" : ""}
                    onClick={() => {
                      toggle("2");
                    }}
                >
                  Files
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                    className={activeTab === "3" ? "active" : ""}
                    onClick={() => {
                      toggle("3");
                    }}
                >
                  Contributors
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <Metadata
                    i={0}
                    artists={artists}
                    genres={genres}
                    handleNextSecondStep={handleAddAsset}
                    control={control}
                    errors={errors}
                    setValue={setValue}
                    clearErrors={clearErrors}
                />
              </TabPane>
              <TabPane tabId="2">
                <Files
                    i={0}
                    control={control}
                    errors={errors}
                    clearErrors={clearErrors}
                    setValue={setValue}
                    handleNextSecondStep={handleAddAsset}
                />
              </TabPane>
              <TabPane tabId="3">
                <Contributors
                    control={control}
                    errors={errors}
                    resetField={resetField}
                    clearErrors={clearErrors}
                    assetIndex={0}
                    handleNextSecondStep={handleAddAsset}
                    setFormGroups={setFormGroups}
                />
              </TabPane>
            </TabContent>
            <ModalFooter className="d-flex justify-content-center">
              <Button color="primary" onClick={handleClose}>
                Close
              </Button>
              <Btn
                  attrBtn={{
                    size: "l",
                    color: "primary",
                    onClick: handleAddAsset,
                    disabled: isLoading,
                  }}
                  type="button"
              >
                {isLoading ? "Adding..." : "Add Asset"}
              </Btn>
            </ModalFooter>
          </>
        </ModalBody>
      </Modal>
  );
}