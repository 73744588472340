import React, { createContext, useContext, useState, useEffect } from "react";
import AuthService from "../../Services/auth/AuthService";
import useBankDetailsStore from '../User/bankDetailsStore';
import UserService from '../../Services/users/UserService';
import { Navigate } from "react-router";
import ConfigDB from "../../Config/ThemeConfig";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(AuthService.isAuthenticated());
  const [isCompleted, setIsCompleted] = useState(false);
  const [userData, setUserData] = useState({});
  const [userstatus, setUserStatus] = useState(false);
  const [loading, setLoading] = useState(true);
  const [accountSecurity, setAccountSecurity] = useState(null);
  const [sttHc, setSttHc] = useState(null);
  const [error, setError] = useState(null);

  const login = async (email, password) => {
    try {
      const { success, user, error, trusted_device, profile_completed } = await AuthService.login(email, password);
      if (success) {
        setAuthenticated(true);
        setIsCompleted(profile_completed);
        setUserData(user);
        setAccountSecurity(user['2fa_status'] === 1);
      }
      return { success, error, user, trusted_device, profile_completed };
    } catch (err) {
      setError(err.message);
      return { success: false, error: err.message };
    }
  };

  const updateSecurityLayers = async (oldPassword, newPassword, isEmailOTP, isSmsOTP, isPinOTP, pinValue) => {
    try {
      const result = await AuthService.updateSecurityLayers(oldPassword, newPassword, isEmailOTP, isSmsOTP, isPinOTP, pinValue);
      return result;
    } catch (err) {
      setError(err.message);
      return { success: false, error: err.message };
    }
  };

  const getUser = async () => {
    if (!authenticated) {
      setLoading(false);
      return;
    }
    try {
      const { success, user, error, isCompleted, sttHc } = await AuthService.getUser();
      if (success) {
        setUserData(user);
        setUserStatus(isCompleted);
        setAccountSecurity(user['2fa_status'] === 1);
        setSttHc(sttHc);
        setLoading(false);
        if (user?.label) {
          const color1 = user.label.color1;
          const color2 = user.label.color2;
          localStorage.setItem("color1", color1);
          localStorage.setItem("color2", color2);
          document.documentElement.style.setProperty("--theme-default", color1);
          document.documentElement.style.setProperty("--theme-secondary", color2);
          ConfigDB.data.color.primary_color = color1;
          ConfigDB.data.color.secondary_color = color2;
        }
      } else {
        console.error("Failed to fetch user data", error);
        setError(error);
      }
    } catch (err) {
      console.error("Failed to fetch user data", err);
      setError(err.message);
    }
  };

  useEffect(() => {
    //! Moved to Zustand  fetchUserBankDetails();
    getUser();
    //! Moved to Zustand fetchSecuritySettings();
  }, [authenticated]);

  const verifyEmail = async (token) => {
    try {
      return await AuthService.verifyEmail(token);
    } catch (err) {
      setError(err.message);
      return { success: false, error: err.message };
    }
  };

  const logout = () => {
    AuthService.logout();
    setAuthenticated(false);
    localStorage.removeItem("color1");
    localStorage.removeItem("color2");
    document.documentElement.style.setProperty("--theme-default", '#D51515');
    document.documentElement.style.setProperty("--theme-secondary", '#1d1a1b');
  };

  const checkPassword = async (password) => {
    try {
      const isPasswordCorrect = await AuthService.checkPassword(password);
      return isPasswordCorrect;
    } catch (err) {
      setError(err.message);
      return false;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        authenticated,
        login,
        logout,
        setAuthenticated,
        verifyEmail,
        isCompleted,
        userData,
        sttHc,
        setUserData,
        userstatus,
        accountSecurity,
        setAccountSecurity,
        loading,
        checkPassword,
        updateSecurityLayers,
        error,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};