import React, { createContext, useContext, useState, useEffect } from 'react';
import spotifyService from '../../Services/analytics/AnalyticsService';

const AnalyticsContext = createContext();


export const useAnalyticsContext = () => { 
    return  useContext(AnalyticsContext)
};

export const AnalyticsProvider = ({ children }) => {
  const [spotifyData, setSpotifyData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [ageGroupData, setAgeGroupData] = useState({});
  const [freepremieumGroup, setFreepremieumGroup] = useState({});
  const [genderData, setGenderData] = useState({});
  const [deviceData, setDeviceData] = useState({});

  const getSpotifyData = async () => {
    setIsLoading(true);
    try {
      const response = await spotifyService.getSpotifyData();
      setSpotifyData(response.spotifyData);
      setAgeGroupData(generateAgeGroupData(response.spotifyData.age_group_spotify));
      setFreepremieumGroup(generateFreePremiumData(response.spotifyData.free_or_premium_spotify));
      setGenderData(generateGenderData(response.spotifyData.streaming_gender));
      setDeviceData(generateDeviceData(response.spotifyData.streaming_device));
    } catch (error) {
      console.error('Error fetching Spotify data:', error);
    } finally {
      setIsLoading(false);
    }
  };


/*   useEffect(() => {
    getSpotifyData();
  }, []); // Empty dependency array ensures this effect runs only once */

  const generateAgeGroupData = (ageGroupData) => {
    const data = ageGroupData.map(item => parseFloat(item.percentage));
    const labels = ageGroupData.map(item => item.age_group);
    return {
      series: data,
      options: {
        chart: {
          width: 380,
          type: 'pie',
          toolbar: {
            show: false,
          },
        },
        labels: labels,
        colors: ['#51bb25', '#544fff', '#fb740d', '#ff0000', '#00ff00', '#0000ff', '#ff00ff', '#ffff00'],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val.toFixed(2) + "%";
          },
          style: {
            fontSize: '12px',
          },
        },
      },
    };
  };

  // Function to generate data for free/premium
  const generateFreePremiumData = (freepremieumGroup) => {
    const data = freepremieumGroup.map(item => parseFloat(item.value));
    const labels = freepremieumGroup.map(item => item.attribute);
    return {
      series: data,
      options: {
        chart: {
          width: 380,
          type: 'pie',
          toolbar: {
            show: false,
          },
        },
        labels: labels,
        colors: ['#51bb25', '#544fff'], // Colors for Free and Premium categories
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val.toFixed(2) + "%";
          },
          style: {
            fontSize: '12px',
          },
        },
      },
    };
  };

  // Function to generate data for gender
  const generateGenderData = (genderData) => {
    const data = genderData.map(item => parseFloat(item.percentage));
    const labels = genderData.map(item => item.gender);
    return {
      series: data,
      options: {
        chart: {
          width: 380,
          type: 'pie',
          toolbar: {
            show: false,
          },
        },
        labels: labels,
        colors: ['#51bb25', '#544fff', '#fb740d'],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val.toFixed(2) + "%";
          },
          style: {
            fontSize: '12px',
          },
        },
      },
    };
  };

  // Function to generate data for devices
  const generateDeviceData = (deviceData) => {
    const data = deviceData.map(item => parseFloat(item.percentage));
    const labels = deviceData.map(item => item.device);
    return {
      series: data,
      options: {
        chart: {
          width: 380,
          type: 'pie',
          toolbar: {
            show: false,
          },
        },
        labels: labels,
        colors: ['#51bb25', '#544fff', '#fb740d', '#ff0000', '#00ff00', '#0000ff', '#ff00ff', '#ffff00', '#ff7f00'], // Colors for each category
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val.toFixed(2) + "%";
          },
          style: {
            fontSize: '12px',
          },
        },
      },
    };
  };

   const formatSourceData = (sourceData) => {
    return sourceData.map(item => {
      const formattedItem = {};
      for (const key in item) {
        if (typeof item[key] === 'number') {
          formattedItem[key] = parseFloat(item[key]).toFixed(2);
        } else {
          formattedItem[key] = item[key];
        }
      }
      return formattedItem;
    });
  }

  return (
    <AnalyticsContext.Provider value={{ 
      spotifyData, 
      isLoading, 
      ageGroupData,
      freepremieumGroup,
      genderData,
      deviceData,
      getSpotifyData,
      generateAgeGroupData, 
      generateFreePremiumData, 
      generateGenderData, 
      generateDeviceData,
      formatSourceData 
    }}>
      {children}
    </AnalyticsContext.Provider>
  );
};



