import React, { useState, useContext, useEffect } from 'react';
import { useParams, useLocation, useNavigate, Link } from 'react-router-dom';
import { Breadcrumbs, Btn } from '../../../../AbstractElements';
import CustomizerContext from '../../../../_helper/Customizer';
import { Image, P, H5, H6, H4 } from '../../../../AbstractElements';
import { useForm, Controller } from 'react-hook-form';
import { Card, CardBody, Col, Input, FormFeedback, Form, Row } from 'reactstrap';
import InvoiceHeader from './InvoiceHeader';
import InvoiceDescription from './InvoiceDescription';
import { useQuery, useMutation } from '@tanstack/react-query';
import InvoiceService from '../../../../Services/invoices/InvoiceService';
import GridLoader from '../../../Loaders/GridLoader';
import Error503 from '../../../Pages/ErrorPages/ErrorPage503';
import { toast } from "react-hot-toast";

function InvoiceDetails() {
    const { uuid } = useParams();
    const { layoutURL } = useContext(CustomizerContext);
    const location = useLocation();
    const navigate = useNavigate();
    const [dynamicAmounts, setDynamicAmounts] = useState({});
    const { control, handleSubmit, formState: { errors }, setError, clearErrors } = useForm();

    const { data, isLoading, isError } = useQuery({
        queryKey: ['invoice', uuid],
        queryFn: () => InvoiceService.getInvoiceByUUID(uuid),
    });

    const updateInvoiceMutation = useMutation({
        mutationFn: (payload) => InvoiceService.updateInvoice(uuid, payload),
        onSuccess: () => {
            toast.success('Invoice updated successfully');
            navigate(`${process.env.PUBLIC_URL}/app/invoices/invoices-list/${layoutURL}`);
        },
        onError: (error) => {
            toast.error(`Failed to update invoice: ${error.message}`);
        }
    });

    if (isLoading) {
        return <GridLoader />;
    }

    if (isError) {
        return <Error503 />;
    }

    const invoice = data.invoice;
    const artist = data.invoice.artist;
    const label = data.invoice.label;
    const expenses = data.expenses;

    const handleGoBack = () => {
        navigate(`${process.env.PUBLIC_URL}/app/invoices/invoices-list/${layoutURL}`);
    };

    const onSubmit = (data) => {
        let hasError = false;
        expenses.forEach((item, i) => {
            if (data[`amount_${i}`] > item.amount) {
                setError(`amount_${i}`, {
                    type: 'manual',
                    message: `Entered amount is greater than ${item.amount}`,
                });
                hasError = true;
            } else {
                clearErrors(`amount_${i}`);
            }
        });

        if (!hasError) {
            const selectedExpenses = expenses.map((item, i) => ({
                id: item.id,
                amount_taken: parseFloat(data[`amount_${i}`])
            }));

            updateInvoiceMutation.mutate(selectedExpenses);
        }
    };

    const handleInputChange = (e, item, i) => {
        const value = parseFloat(e.target.value);
        if (!isNaN(value)) {
            setDynamicAmounts(prevState => ({
                ...prevState,
                [`amount_${i}`]: item.amount - value
            }));
        } else {
            setDynamicAmounts(prevState => ({
                ...prevState,
                [`amount_${i}`]: item.amount
            }));
        }
    };

    return (
        <>
            <Breadcrumbs
                mainTitle='Invoice Details'
                buttons={[
                    <Btn
                        attrBtn={{
                            size: "sm",
                            color: "primary",
                            onClick: handleGoBack,
                        }}
                    >
                        <i className="fa fa-arrow-left me-1"></i>Go Back
                    </Btn>,
                    <Btn
                        attrBtn={{
                            size: "sm",
                            color: "primary",
                            type: "submit",
                            onClick: handleSubmit(onSubmit),
                        }}
                    >
                        <i className="fa fa-check me-1"></i>Update
                    </Btn>,
                ]}
            />

            <Row>
                <Col sm='12'>
                    <Card>
                        <CardBody>
                            <div className='invoice'>
                                <div>
                                    <InvoiceHeader invoice={invoice} label={label} />
                                    <InvoiceDescription artist={artist} />
                                    <hr />
                                    <Col>
                                        <H5 attrH5={{ className: 'm-10' }}>{"Expenses"}</H5>
                                        <div className='appointment'>
                                            <Form onSubmit={handleSubmit(onSubmit)} className="theme-form">
                                                <div className='expenses-list'>
                                                    {expenses.map((item, i) => (
                                                        <div key={i} className='expense-item d-flex justify-content-between align-items-center mb-3 p-3 border rounded'>
                                                            <div>
                                                                <Link className='d-block f-w-700 text-primary' to={`${process.env.PUBLIC_URL}/app/users/profile`}>
                                                                    {item.title}
                                                                </Link>
                                                                <span className='f-light text-muted' style={{ display: 'block', marginTop: '5px' }}>
                                                                    {item.description}
                                                                </span>
                                                            </div>
                                                            <div className='text-end'>
                                                                <P attrPara={{ className: `m-0 ${dynamicAmounts[`amount_${i}`] < 0 ? 'font-danger' : 'font-success'}` }}>
                                                                    {dynamicAmounts[`amount_${i}`] !== undefined ? dynamicAmounts[`amount_${i}`] : item.amount}
                                                                </P>
                                                            </div>
                                                            <div className='text-center'>
                                                                <Controller
                                                                    name={`amount_${i}`}
                                                                    control={control}
                                                                    defaultValue=""
                                                                    rules={{ required: true }}
                                                                    render={({ field }) => (
                                                                        <Input
                                                                            {...field}
                                                                            className='form-control'
                                                                            style={{ width: '300px' }}
                                                                            placeholder={`Enter amount`}
                                                                            type='number'
                                                                            invalid={errors[`amount_${i}`] ? true : false}
                                                                            onChange={(e) => {
                                                                                field.onChange(e);
                                                                                handleInputChange(e, item, i);
                                                                            }}
                                                                        />
                                                                    )}
                                                                />
                                                                {errors[`amount_${i}`] && <FormFeedback>{errors[`amount_${i}`].message}</FormFeedback>}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </Form>
                                        </div>
                                    </Col>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
}

export default InvoiceDetails;