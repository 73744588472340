import React, { useState } from 'react';
import { Collapse, Card, CardBody, CardHeader, Button, Table } from 'reactstrap';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

const ProductActivity = ({ isAdmin, histories }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  if (!isAdmin) {
    return null; 
  }

  const getProductHistoryByEventType = (eventType) => {
    return histories.filter(history => history.event_type === eventType);
  };

  const renderEventTypeIcon = (eventType) => {
    switch (eventType) {
      case 'product_created':
      case 'status_changed':
      case 'ddex_creation':
      case 'aws_data_retrieval':
      case 'sftp_login_anghami':
      case 'delivrance':
        return <FaCheckCircle className="text-primary" />;
      default:
        return <FaTimesCircle className="text-danger" />;
    }
  };
  

  const renderColorCodedRows = (eventType) => {
    return getProductHistoryByEventType(eventType).map(history => (
      <tr key={history.id} /* className={getClassForEventType(eventType)} */>
        <td>{history.event_date}</td>
        <td>{renderEventTypeIcon(history.event_type)} {history.event_type}</td>
        <td>{history.message}</td>
        <td>{history.ip_address}</td>
      </tr>
    ));
  };

/*   const getClassForEventType = (eventType) => {
    switch (eventType) {
      case 'product_created':
         return 'table-info';
      case 'status_changed':
         return 'table-info';
      case 'ddex_creation':
         return 'table-info';
      case 'aws_data_retrieval':
         return 'table-info';
      case 'sftp_login_anghami':
         return 'table-info';
      case 'sftp_login':
      case 'delivrance':
        return 'table-info';
      default:
        return 'table-danger';
    }
  }; */

  return (
    <Card className="mb-4">
      <CardHeader className="d-flex justify-content-between align-items-center">
        <h3 className="mb-0">Product Activity</h3>
        <Button
          color="primary"
          onClick={toggleCollapse}
          aria-expanded={isOpen}
          aria-controls="productActivityContent"
        >
          Toggle Product Activity
        </Button>
      </CardHeader>
      <Collapse isOpen={isOpen} id="productActivityContent">
        <CardBody>
          <Table responsive striped bordered>
            <thead>
              <tr>
                <th>Date</th>
                <th>Event Type</th>
                <th>Message</th>
                <th>IP Address</th>
              </tr>
            </thead>
            <tbody>
              {renderColorCodedRows('product_created')}
              {renderColorCodedRows('aws_data_retrieval')} 
              {renderColorCodedRows('ddex_creation')}
              {renderColorCodedRows('sftp_login_anghami')}
              {renderColorCodedRows('sftp_login')}
              {renderColorCodedRows('delivrance')}
              {renderColorCodedRows('status_changed')}
            </tbody>
          </Table>
        </CardBody>
      </Collapse>
    </Card>
  );
};

export default ProductActivity;
