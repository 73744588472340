import axios from 'axios';

export async function fetchCustomizationSettings() {
    const origin = window.location.origin;
    const apiKey = process.env.REACT_APP_KEY_HOST;
    const red_backend = process.env.REACT_APP_BACKEND_URL;

    const url = new URL(origin);
    const hostnameAndPort = url.host;

    try {
        const response = await axios.post(`${red_backend}/whitelabel/${hostnameAndPort}`, {}, {
            headers: {
                'Authorization': `Bearer ${apiKey}`
            }
        });
        const data = response.data;
        return {
            color1: data.color1 ?? '',
            color2: data.color2 ?? '',
            bgImage: data.background ?? '',
            logo: data.logo,
            title: data.title,
            favicon: data.favicon
        };
    } catch (error) {
        return null;
    }
}