import React, { Fragment, useState } from "react";
import {Container, Row, Col, Card, CardBody, Button, Tooltip, Spinner} from "reactstrap";
import { Breadcrumbs, Btn } from "../../../../AbstractElements";
import HeaderCard from "../../../Common/Component/HeaderCard";
import AddClaimModal from "./AddClaimModal";
import UpdateClaimModal from "./UpdateClaimModal";
import DataTable from "react-data-table-component";
import EmptyDataTable from "../../../Dashboard/Common/EmptyDataTable";
import { useClaimData, useDeleteClaim } from "../../../../Hooks/useClaimData";
import moment from "moment";
import { Edit, Trash2 } from "react-feather";
import { toast } from "react-hot-toast";

const ClaimList = () => {
    const { data: claims, isLoading, isError } = useClaimData();
    const deleteClaimMutation = useDeleteClaim();
    const [showAddModal, setShowAddModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [selectedClaim, setSelectedClaim] = useState(null);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [tooltipTarget, setTooltipTarget] = useState(null);
    const [tooltipUrls, setTooltipUrls] = useState([]);

    const handleShowAddClaimModal = () => {
        setShowAddModal(true);
    };

    const handleShowUpdateClaimModal = (claim) => {
        setSelectedClaim(claim);
        setShowUpdateModal(true);
    };

    const handleDeleteClaim = (id) => {
        return new Promise((resolve, reject) => {
            deleteClaimMutation.mutate(id, {
                onSuccess: () => {
                    resolve();
                },
                onError: (error) => {
                    reject(error);
                },
            });
        });
    };

    const confirmDeleteClaim = (id) => {
        toast((t) => (
            <span>
                Are you sure you want to delete this claim?
                <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center', gap: '10px' }}>
                    <Button color="danger" size="sm" style={{ padding: '5px 10px' }} onClick={() => {
                        toast.promise(
                            handleDeleteClaim(id),
                            {
                                loading: 'Deleting claim...',
                                success: 'Claim deleted successfully!',
                                error: 'Failed to delete claim.',
                            }
                        ).then(() => {
                            toast.dismiss(t.id);
                        });
                    }}>
                        Yes
                    </Button>
                    <Button color="secondary" size="sm" style={{ padding: '5px 10px' }} onClick={() => toast.dismiss(t.id)}>
                        No
                    </Button>
                </div>
            </span>
        ), {
            duration: Infinity
        });
    };

    const toggleTooltip = (target, urls) => {
        setTooltipTarget(target);
        setTooltipUrls(urls);
        setTooltipOpen(!tooltipOpen);
    };

    const columns = [
        { name: "Artist", selector: row => row.artist_id, sortable: true, width: "100px", },
        { name: "Type", selector: row => row.type, sortable: true, width: "100px", },
        {
            name: "YT Video URL",
            selector: row => row.yt_video_url.join(", "),
            sortable: true,
            width: "400px",
            cell: row => (
                <div id={`yt-tooltip-${row.id}`} onMouseEnter={() => toggleTooltip(`yt-tooltip-${row.id}`, row.yt_video_url)} onMouseLeave={() => setTooltipOpen(false)}>
                    {row.yt_video_url.join(", ")}
                    <Tooltip placement="top" isOpen={tooltipOpen} target={tooltipTarget} toggle={() => setTooltipOpen(!tooltipOpen)}>
                        <div>
                            {tooltipUrls.map((url, index) => (
                                <div key={index}>{url}</div>
                            ))}
                        </div>
                    </Tooltip>
                </div>
            )
        },
        { name: "ISRCs", selector: row => row.isrcs.join(", "), sortable: true, width: "100px", },
        { name: "Content Timing", selector: row => row.content_timing, sortable: true, width: "100px", },
        { name: "Policy", selector: row => row.policy, sortable: true, width: "100px", },
        { name: "Status", selector: row => row.status, sortable: true, width: "100px", },
        { name: "Created At", selector: row => moment(row.created_at).format("YYYY-MM-DD HH:mm:ss"), sortable: true },
        {
            name: "Actions",
            cell: row => (
                <div>
                    <Button color="warning" size="sm" onClick={() => handleShowUpdateClaimModal(row)} style={{ marginRight: '5px' }}>
                        <Edit size={16} />
                    </Button>
                    <Button color="danger" size="sm" onClick={() => confirmDeleteClaim(row.id)}>
                        <Trash2 size={16} />
                    </Button>
                </div>
            )
        }
    ];

    if (isLoading) {
        return <Col className="d-flex justify-content-center">
            <Spinner color="primary" />
        </Col>;
    }

    if (isError) {
        return <div>Error loading claims</div>;
    }

    return (
        <Fragment>
            <Breadcrumbs
                parent="Claim Management"
                title="Claims"
                mainTitle="Claim Data"
                buttons={[
                    <Btn
                        attrBtn={{
                            size: "sm",
                            color: "primary",
                            onClick: handleShowAddClaimModal,
                        }}
                    >
                        <i className="fa fa-plus me-1"></i>Add Claim
                    </Btn>,
                ]}
            />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <HeaderCard title="Manage your Claims" />
                            <CardBody>
                                {claims.data.length > 0 ? (
                                    <DataTable
                                        columns={columns}
                                        data={claims.data}
                                        pagination
                                    />
                                ) : (
                                    <EmptyDataTable />
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <AddClaimModal
                show={showAddModal}
                handleClose={() => setShowAddModal(false)}
            />
            <UpdateClaimModal
                show={showUpdateModal}
                handleClose={() => setShowUpdateModal(false)}
                claim={selectedClaim}
            />
        </Fragment>
    );
};

export default ClaimList;