import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Row, Col, FormGroup, Label } from 'reactstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import DataTable from 'react-data-table-component';
import { useYoutubeAccountingData } from '../../../../Hooks/useAccountingData';
import Widgets2 from "../../../Common/CommonWidgets/Widgets2";
import { Widgets2ChartData2Widgets } from "../../../../Data/DefaultDashboard/Chart";
import countries from 'i18n-iso-countries';
import HourGlassLoader from "../../../Loaders/HourGlassLoader";
import { AlertCircle, PlayCircle } from "react-feather";


countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
const YoutubeContainer = ({ userRole, userData,  labelArtists, isLabelArtistsLoading, labelArtistsError }) => {
    const animatedComponents = makeAnimated();

    const providerOptions = [
        { value: 'videos', label: 'Videos' },
        { value: 'video_countries', label: 'Video Countries' },
    ];

    const columns = {
        videos: [
            { name: 'Title', selector: row => row.title },
            { name: 'Views', selector: row => row.views },
            { name: 'Likes', selector: row => row.likes },
            { name: 'Estimated Revenue', selector: row => row.estimated_revenue },
            { name: 'Comments', selector: row => row.comments },
            { name: 'Month', selector: row => row.month },
            {
                name: 'Video ID',
                cell: row => (
                    <a href={`${row.video_youtube_id}`} target="_blank" rel="noopener noreferrer">
                        <PlayCircle size={24} />
                    </a>
                )
            },
            { name: 'Currency', selector: row => row.currency },
        ],
        video_countries: [
            { name: 'Country', selector: row => countries.getName(row.country_code, "en") || row.country_code },
            { name: 'Views', selector: row => row.views },
            { name: 'Likes', selector: row => row.likes },
            { name: 'Estimated Revenue', selector: row => row.estimated_revenue },
            { name: 'Comments', selector: row => row.comments },
            { name: 'Month', selector: row => row.month },
        ],
    };

    const [selectedProvider, setSelectedProvider] = useState(providerOptions[0]);
    const [selectedYear, setSelectedYear] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [selectedArtist, setSelectedArtist] = useState(null);
    const [page, setPage] = useState(1);
    const color1 = userData?.label?.color1 || "#900C3F";

    const handleProviderChange = (selectedOption) => {
        setSelectedProvider(selectedOption);
        setSelectedYear(null);
    };

    const handleYearChange = (selectedOption) => {
        setSelectedYear(selectedOption);
    };

    const handleMonthChange = (selectedOption) => setSelectedMonth(selectedOption);

    const handleArtistChange = (selectedOption) => setSelectedArtist(selectedOption);

    const artistId = userRole === 'label' ? selectedArtist?.value : userRole === 'artist' ? userData?.id_user : null;
    const month = selectedYear && selectedMonth ? `${selectedYear.value}-${selectedMonth.value}` : null;
    const { data, isLoading, error } = useYoutubeAccountingData(artistId, selectedProvider?.value, month, page);

    useEffect(() => {
        if (userRole === 'label') {
            console.log('Label Artists:', labelArtists);
        }
    }, [labelArtists, userRole]);

    useEffect(() => {
        // Fetch data whenever the selected provider, year, month, or page changes
    }, [selectedProvider, selectedYear, selectedMonth, page]);

    const getDataTable = () => {
        if (userRole === 'label' && !selectedArtist) {
            return (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', color: color1, flexDirection: 'column' }}>
                    <AlertCircle size={48} />
                    <p>Please select an artist to view data</p>
                </div>
            );
        }
        if (!selectedProvider) {
            return (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', color: color1, flexDirection: 'column' }}>
                    <AlertCircle size={48} />
                    <p>Please choose a provider to overview data</p>
                </div>
            );
        }
        if (isLoading) {
            return (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <HourGlassLoader/>
                </div>
            );
        }
        if (error) {
            return (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', color: color1, flexDirection: 'column' }}>
                    <AlertCircle size={48} />
                    <p>Error loading data</p>
                    <p>If the error persists, please contact the administrator.</p>
                </div>
            );
        }

        const tableData = data?.data || [];
        const rowsPerPage = data?.pagination?.per_page || 20;

        console.log('Table Data:', tableData);

        return (
            <DataTable
                columns={columns[selectedProvider.value]}
                data={tableData}
                pagination
                paginationServer
                paginationTotalRows={data.pagination.total}
                paginationPerPage={rowsPerPage}
                onChangePage={setPage}
            />
        );
    };

    const StreamWidget = {
        title: 'Streams',
        total: data?.totals?.total_streams || '0',
        chart: Widgets2ChartData2Widgets,
    };

    const DownloadWidget = {
        title: 'Downloads',
        total: data?.totals?.total_downloads || '0',
        chart: Widgets2ChartData2Widgets,
    };

    const TotalRevenueStream = {
        title: 'Total Revenue Streams',
        total: data?.totals?.total_revenue_streams || '0.00',
        chart: Widgets2ChartData2Widgets,
    };

    const TotalRevenueDownload = {
        title: 'Total Revenue Downloads',
        total: data?.totals?.total_revenue_downloads || '0.00',
        chart: Widgets2ChartData2Widgets,
    };

    return (
        <>
            {userRole === 'artist' && (
                <Row className="widget-grid">
                    <Col xxl='auto' xl='3' sm='6' className='box-col-6'>
                        <Widgets2 chartClass='profit-chart' data={StreamWidget} />
                    </Col>
                    <Col xxl='auto' xl='3' sm='6' className='box-col-6'>
                        <Widgets2 chartClass='profit-chart' data={DownloadWidget} />
                    </Col>
                    <Col xxl='auto' xl='3' sm='6' className='box-col-6'>
                        <Widgets2 chartClass='profit-chart' data={TotalRevenueStream} />
                    </Col>
                    <Col xxl='auto' xl='3' sm='6' className='box-col-6'>
                        <Widgets2 chartClass='profit-chart' data={TotalRevenueDownload} />
                    </Col>
                </Row>
            )}
            <Card>
                <CardHeader>
                    <Row>
                        {userRole === 'label' && (
                            <Col md="4">
                                <FormGroup>
                                    <Label for="artistSelect">Artist</Label>
                                    <Select
                                        id="artistSelect"
                                        components={animatedComponents}
                                        options={labelArtists?.map(artist => ({ value: artist.id, label: artist.name }))}
                                        value={selectedArtist}
                                        onChange={handleArtistChange}
                                        isClearable
                                        isLoading={isLabelArtistsLoading}
                                        isDisabled={labelArtistsError}
                                    />
                                </FormGroup>
                            </Col>
                        )}
                        <Col md="4">
                            <FormGroup>
                                <Label for="providerSelect">Provider</Label>
                                <Select
                                    id="providerSelect"
                                    components={animatedComponents}
                                    options={providerOptions}
                                    value={selectedProvider}
                                    onChange={handleProviderChange}
                                    isClearable
                                />
                            </FormGroup>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <Label for="yearSelect">Year</Label>
                                <Select
                                    id="yearSelect"
                                    components={animatedComponents}
                                    options={Array.from({ length: new Date().getFullYear() - 2013 }, (_, i) => ({
                                        value: 2014 + i,
                                        label: 2014 + i
                                    }))}
                                    value={selectedYear}
                                    onChange={handleYearChange}
                                    isClearable
                                    isDisabled={!selectedProvider}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <Label for="monthSelect">Month</Label>
                                <Select
                                    id="monthSelect"
                                    components={animatedComponents}
                                    options={[
                                        { value: "01", label: 'January' },
                                        { value: "02", label: 'February' },
                                        { value: "03", label: 'March' },
                                        { value: "04", label: 'April' },
                                        { value: "05", label: 'May' },
                                        { value: "06", label: 'June' },
                                        { value: "07", label: 'July' },
                                        { value: "08", label: 'August' },
                                        { value: "09", label: 'September' },
                                        { value: "10", label: 'October' },
                                        { value: "11", label: 'November' },
                                        { value: "12", label: 'December' }
                                    ]}
                                    value={selectedMonth}
                                    onChange={handleMonthChange}
                                    isClearable
                                    isDisabled={!selectedYear}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <div className="table-responsive support-table">
                        {getDataTable()}
                    </div>
                </CardBody>
            </Card>
        </>
    );
};

export default YoutubeContainer;