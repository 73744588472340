import React , {useState} from "react";
import { Breadcrumbs, H5, H6, Btn } from "../../../../AbstractElements";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Input,
} from "reactstrap";
import DataTable from "react-data-table-component";
import ExpenseService from "../../../../Services/expenses/ExpenseService";
import { useQuery, useMutation, useQueryClient  } from "@tanstack/react-query";
import NewExpensesModal from "../Modals/NewExpenseModal";
import Swal from "sweetalert2";
import { toast } from "react-hot-toast";
import GridLoader from "../../../Loaders/GridLoader";
import UpdateExpensesModal from "../Modals/UpdateExpensesModal";
import { Download } from "react-feather";
function MainExpenses() {
  const queryClient = useQueryClient();

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [selectedExpense, setSelectedExpense] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);


  const { isPending, isError, data, error } = useQuery({
    queryKey: ["inhouseExpenses"],
    queryFn: ExpenseService.getInhouseExpenses,
  });

  const {
    isPending: isCategoriesPending,
    isError: isCategoriesError,
    data: categories,
    error: categoryError,
  } = useQuery({
    queryKey: ["inhouseCategories"],
    queryFn: ExpenseService.getInhouseCategories,
  });

  const addExpenseMutation = useMutation({
    mutationFn: ExpenseService.createExpense,
    onSuccess: () => {
      toast.success("Expense added successfully!");
      queryClient.invalidateQueries(["inhouseExpenses"]);
      setIsAddModalOpen(false);
    },
    onError: (error) => {
      toast.error(`Failed to add expense: ${error.message}`);
    },
  });
  
  const handleAddExpense = (expenseData) => {
    return new Promise((resolve, reject) => {
      addExpenseMutation.mutate(expenseData, {
        onSuccess: resolve,
        onError: reject,
      });
    });
  };
  
  const updateExpenseMutation = useMutation({
    mutationFn: ({ id, expenseData }) =>
      ExpenseService.updateExpense(id, expenseData),
    onSuccess: () => {
      toast.success("Expense updated successfully!");
      queryClient.invalidateQueries(["inhouseExpenses"]);
      setIsUpdateModalOpen(false);
    },
    onError: (error) => {
      toast.error(`Failed to update expense: ${error.message}`);
    },
  });
  
  const handleSaveUpdatedExpense = (expenseData) => {
    return new Promise((resolve, reject) => {
      updateExpenseMutation.mutate(
        { id: selectedExpense.id, expenseData },
        {
          onSuccess: resolve,
          onError: reject,
        }
      );
    });
  };
  
  const handleUpdateExpense = (expense) => {
    setSelectedExpense(expense);
    setIsUpdateModalOpen(true);
  };
  
  const deleteExpenseMutation = useMutation({
    mutationFn: ExpenseService.deleteExpense,
    onSuccess: () => {
      toast.success("Expense deleted successfully!");
      queryClient.invalidateQueries(["inhouseExpenses"]);
    },
    onError: (error) => {
      toast.error(`Failed to delete expense: ${error.message}`);
    },
  });
  
  const handleDeleteExpense = (expenseId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to delete this expense?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteExpenseMutation.mutate(expenseId);
      }
    });
  };

  const handleDownloadFile = async (expenseId) => {
    try {
        const { data: fileBlob, fileName, mimeType } = await ExpenseService.downloadExpenseFile(expenseId);
        const url = window.URL.createObjectURL(new Blob([fileBlob], { type: mimeType }));
        const link = document.createElement('a');
        link.href = url;
        const date = new Date();
        const timestamp = `${date.getHours()}${date.getMinutes()}${date.getSeconds()}`;
        link.setAttribute('download', timestamp);
        document.body.appendChild(link);
        link.click();
        link.remove();
    } catch (error) {
        toast.error(`Failed to download file: ${error.message}`);
    }
};

  if (isCategoriesPending || isPending) {
    return <GridLoader/>;
  }

  if (isCategoriesError || isError) {
    return <p>An error has occurred: {categoryError.message}</p>;
  }

  let filteredData = data.data;

  if (selectedCategory) {
    filteredData = filteredData.filter(expense => expense.category_inhouse === selectedCategory);
  }
  
  if (searchText) {
    filteredData = filteredData.filter(expense => 
      expense.title.toLowerCase().includes(searchText.toLowerCase()) ||
      expense.amount.toString().includes(searchText)
    );
  }

  const tableColumns = [
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
    },
    {
      name: "Category Inhouse",
      selector: (row) => row.category_inhouse,
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true,
    },
    {
      name: "Currencies",
      selector: (row) => row.currencies,
      sortable: true,
    },
    {
      name: "File",
      selector: (row) => row.file,
      cell: (row) => (
          <Download
              style={{ cursor: 'pointer' }}
              onClick={() => handleDownloadFile(row.id)}
          />
      ),
      sortable: false,
  },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="todo">
          <div className="todo-list-wrapper">
            <div className="todo-list-container todo-list-footer">
              <ul className="simple-list">
                <li className="task border-0">
                  <div className="task-container">
                    <span className="task-action-btn">
                      <span
                        className="action-box large icon-edit"
                        title="Update"
                        onClick={() => handleUpdateExpense(row)}
                      >
                        <i className="icon">
                          <i className="fa fa-edit"></i>
                        </i>
                      </span>
                      <span
                        className="action-box large delete-btn"
                        title="Delete"
                        // onClick={() => handleDeleteUser(row.id)}
                      >
                        <i className="icon">
                          <i className="icon-trash"></i>
                        </i>
                      </span>
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      ),
      center: true,
    },
  ];

  return (
    <>
      <Breadcrumbs
        parent="Expenses"
        title="Expenses"
        mainTitle="In-House Expenses"
        buttons={[
          <Btn
            attrBtn={{
              size: "sm",
              color: "primary",
              onClick: () => setIsAddModalOpen(true),
            }}
          >
          <i className="fa fa-plus me-1"></i> Add Expense
          </Btn>,
        ]}
      />

      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <H5>In-House Expenses Table</H5>
                  <div style={{ width: "400px" }}>
                    <Input
                      type="select"
                      name="select"
                      id="exampleSelect"
                      className="form-control"
                      onChange={(e) => setSelectedCategory(e.target.value)}
                    >
                      <option value="" defaultChecked>
                        All Categories
                      </option>
                      {categories.data.map((category, index) => (
                        <option key={index} value={category.title}>
                          {category.title}
                        </option>
                      ))}
                    </Input>
                  </div>
                  <Input
                    type="text"
                    placeholder="Search by title or amount"
                    className="form-control"
                    style={{ width: "400px" }}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </div>
              </CardHeader>
              <CardBody>
                <DataTable
                  columns={tableColumns}
                  data={filteredData}
                  striped={true}
                  center={true}
                  responsive={true}
                  pagination
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <NewExpensesModal
        isOpen={isAddModalOpen}
        toggle={() => setIsAddModalOpen(!isAddModalOpen)}
        onSave={handleAddExpense}
        categories={categories.data}
        expenseType="inhouse"
      />
        <UpdateExpensesModal
        isOpen={isUpdateModalOpen}
        toggle={() => setIsUpdateModalOpen(!isUpdateModalOpen)}
        onSave={handleSaveUpdatedExpense}
        categories={categories.data}
        expense={selectedExpense}
        expenseType="inhouse"
      />
    </>
  );
}

export default MainExpenses;
