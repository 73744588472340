import React, { Fragment, useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import {Col, Container, Form, FormGroup, Input, Label, Row, Spinner} from "reactstrap";
import { Btn, H4, H6, P, Image } from "../../AbstractElements";
import { fetchCustomizationSettings } from "../../utils/customization";
import Error500 from "../../Components/Pages/ErrorPages/ErrorPage500";
import UserService from "../../Services/users/UserService";
import { ToastContainer, toast } from "react-toastify";

const ResetPwd = ({ logoClassMain }) => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [togglePassword, setTogglePassword] = useState(false);
  const [passwordValue, setPasswordValue] = useState("");
  const [rePasswordValue, setRePasswordValue] = useState("");
  const [hasError, setHasError] = useState(false);
  const [tokenValid, setTokenValid] = useState(false);
  const [customization, setCustomization] = useState(null);

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const response = await UserService.verifyToken(token);
        if (response.success) {
          setTokenValid(true);
        } else {
          alert("Invalid token. Redirecting to login.");
          navigate("/login");
        }
      } catch (error) {
        console.error("Error verifying token", error);
        alert("An error occurred. Redirecting to login.");
        navigate("/login");
      }
    };
    verifyToken();
  }, [token, navigate]);

  const handleResetPwd = async (e) => {
    e.preventDefault();
    if (passwordValue === rePasswordValue) {
      try {
        const response = await UserService.resetPassword(token, passwordValue);
        if (response.success) {
          toast.success("Password reset successfully. Please log in.");
          navigate("/login");
        } else {
          toast.error("Failed to reset password. Please try again.");
        }
      } catch (error) {
        console.error("Error resetting password", error);
        alert("An error occurred. Please try again.");
      }
    } else {
      toast.error("Passwords do not match.");
    }
  };



  useEffect(() => {
    async function loadCustomization() {
      const settings = await fetchCustomizationSettings();
      if (!settings) {
        setHasError(true);
        return;
      }
      setCustomization(settings);
      document.documentElement.style.setProperty("--theme-default", settings.color1);
      document.documentElement.style.setProperty("--theme-secondary", settings.color2);
    }
    loadCustomization();
  }, []);

  if (hasError) {
    return <Error500 />;
  }

  if (!customization) {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <Col md="3" key={1}>
            <div className="loader-box">
              <Spinner attrSpinner={{ className: 'loader-6' }} />
            </div>
          </Col>
        </div>
    );
  }

  return (
      <Fragment>
        <section>
          <Container
              className="p-0 login-page"
              fluid={true}
              style={{
                backgroundImage: `url(${customization.bgImage})`,
                backgroundSize: 'cover',
                backgroundAttachment: 'fixed',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat'
              }}
          >
            <Row className="m-0">
              <Col className="p-0">
                <div className="login-card">
                  <div>
                    <div>
                      <Link
                          className={`logo ${logoClassMain ? logoClassMain : ""}`}
                          to={process.env.PUBLIC_URL}
                      >
                        <Image
                            attrImage={{
                              className: "img-fluid for-light",
                              src: customization.logo,
                              alt: "loginpage",
                              style: { width: '300px' }
                            }}
                        />
                      </Link>
                    </div>
                    <div className="login-main">
                      {tokenValid ? (
                          <Form
                              className="theme-form login-form"
                              onSubmit={handleResetPwd}
                          >
                            <H4 attrH4={{ className: "mb-3" }}>
                              Reset Your Password
                            </H4>
                            <H6 attrH6={{ className: "mt-4" }}>
                              Create Your Password
                            </H6>
                            <FormGroup className="position-relative">
                              <Label className="col-form-label m-0">
                                New Password
                              </Label>
                              <div className="position-relative">
                                <Input
                                    className="form-control"
                                    type={togglePassword ? "text" : "password"}
                                    name="login[password]"
                                    required
                                    placeholder="*********"
                                    value={passwordValue}
                                    onChange={(e) => setPasswordValue(e.target.value)}
                                />
                                <div
                                    className="show-hide"
                                    onClick={() => setTogglePassword(!togglePassword)}
                                >
                              <span
                                  className={togglePassword ? "" : "show"}
                              ></span>
                                </div>
                              </div>
                            </FormGroup>
                            <FormGroup>
                              <Label className="col-form-label m-0">
                                Retype Password
                              </Label>
                              <Input
                                  className="form-control"
                                  type="password"
                                  name="login[password]"
                                  required=""
                                  placeholder="*********"
                                  value={rePasswordValue}
                                  onChange={(e) => setRePasswordValue(e.target.value)}
                              />
                            </FormGroup>
                            <FormGroup>
                              <div className="checkbox">
                                <Input id="checkbox1" type="checkbox" />
                                <Label className="text-muted" for="checkbox1">
                                  Remember password
                                </Label>
                              </div>
                            </FormGroup>
                            <FormGroup>
                              <Btn
                                  attrBtn={{
                                    color: "primary",
                                    className: "btn d-block w-100",
                                    type: "submit",
                                  }}
                              >
                                Done
                              </Btn>
                            </FormGroup>
                            <P attrPara={{ className: "text-start" }}>
                              Already have a password?
                              <Link
                                  to={`${process.env.PUBLIC_URL}/login`}
                                  className="ms-2"
                              >
                                Sign in
                              </Link>
                            </P>
                          </Form>
                      ) : (
                          <P attrPara={{ className: "text-center" }}>
                            Verifying token...
                          </P>
                      )}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <ToastContainer />
      </Fragment>
  );
};

export default ResetPwd;