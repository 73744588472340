import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { Btn, H4, P, Image } from '../../../AbstractElements';
import { useAuth } from '../../../_helper/Auth/AuthProvider';
import logoWhite from '../../../assets/images/logo/logo_red.svg';
import logoDark from '../../../assets/images/logo/logo_white.svg'

const EmailVerificationSuccess = ({ logoClassMain }) => {
  const { verifyEmail } = useAuth();

  useEffect(() => {
    const token = window.location.pathname.split('/').pop();

    verifyEmail(token)
      .then(response => {
      })
      .catch(error => {
        console.error('Email verification failed', error);
      });
  }, [verifyEmail]);

  return (
    <Container fluid className='p-0 login-page'>
      <Row className='m-0'>
        <Col className='p-0'>
          <div className='login-card'>
            <div>
              <div>
                <Link className={`logo ${logoClassMain ? logoClassMain : ''}`} to={process.env.PUBLIC_URL}>
                  <Image attrImage={{ className: 'img-fluid for-light', src: logoWhite, alt: 'looginpage' }} />
                  <Image attrImage={{ className: 'img-fluid for-dark', src: logoDark, alt: 'looginpage' }} />
                </Link>
              </div>
              <div className='login-main'>
                <H4>Email Verified Successfully</H4>
                <P>Your email has been verified successfully.</P>
                <Link to={`${process.env.PUBLIC_URL}/login`}>
                  <Btn attrBtn={{ className: 'd-block w-100', color: 'primary' }}>Go to Login</Btn>
                </Link>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default EmailVerificationSuccess;
