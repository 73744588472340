import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import { useUpdateWhitelist } from "../../../../Hooks/useWhitelistData";
import { toast } from "react-hot-toast";

const UpdateWhitelistModal = ({ show, handleClose, whitelist }) => {
    const [formData, setFormData] = useState({
        artist_id: "",
        name: "",
        yt_url: "",
        status: "",
    });

    const updateWhitelistMutation = useUpdateWhitelist();

    useEffect(() => {
        if (whitelist) {
            setFormData({
                artist_id: whitelist.artist_id,
                name: whitelist.name,
                yt_url: whitelist.yt_url,
                status: whitelist.status,
            });
        }
    }, [whitelist]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        updateWhitelistMutation.mutate({ whitelistId: whitelist.id, updatedWhitelistData: formData }, {
            onSuccess: () => {
                toast.success("Whitelist updated successfully!");
                handleClose();
            },
            onError: (error) => {
                console.error("Error updating whitelist:", error);
            },
        });
    };

    return (
        <Modal isOpen={show} toggle={handleClose} size="lg">
            <ModalHeader toggle={handleClose}>Update Whitelist</ModalHeader>
            <Form onSubmit={handleSubmit}>
                <ModalBody>
                    <Row>
                        <Col md="6">
                            <FormGroup>
                                <Label for="artist_id">Artist ID</Label>
                                <Input
                                    type="number"
                                    name="artist_id"
                                    id="artist_id"
                                    value={formData.artist_id}
                                    onChange={handleChange}
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <Label for="name">Name</Label>
                                <Input
                                    type="text"
                                    name="name"
                                    id="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    required
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <FormGroup>
                                <Label for="yt_url">YouTube URL</Label>
                                <Input
                                    type="url"
                                    name="yt_url"
                                    id="yt_url"
                                    value={formData.yt_url}
                                    onChange={handleChange}
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <Label for="status">Status</Label>
                                <Input
                                    type="text"
                                    name="status"
                                    id="status"
                                    value={formData.status}
                                    onChange={handleChange}
                                    required
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter className="d-flex justify-content-center">
                    <Button color="primary" type="submit">Update Whitelist</Button>
                    <Button color="secondary" onClick={handleClose}>Cancel</Button>
                </ModalFooter>
            </Form>
        </Modal>
    );
};

export default UpdateWhitelistModal;