import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import TailChase from "../../../Loaders/TailChase";
function NewExpensesModal({ isOpen, toggle, onSave, categories, expenseType, artists }) {
  const categoryFieldName =
    expenseType === "inhouse"
      ? "category_inhouse_id"
      : "category_contributors_id";
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  const [selectedFile, setSelectedFile] = useState(null);
  const [newFileSelected, setNewFileSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setValue("file", "");
      setSelectedFile(null);
      setNewFileSelected(false);
    }
  }, [isOpen, setValue]);

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      let file = e.target.files[0];
      setSelectedFile(file);
      setNewFileSelected(true);
    } else {
      setSelectedFile(null);
      setNewFileSelected(false);
    }
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (key !== "file") {
        formData.append(key, data[key]);
      }
    });
    if (newFileSelected) {
      formData.append("file", selectedFile);
    }
    onSave(formData)
        .then(() => {
          reset();
          setIsLoading(false);
          setNewFileSelected(false);
          setSelectedFile(null);
        })
        .catch((error) => {
          console.error("Error saving form data:", error);
          setIsLoading(false);
        });
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>Add New Expense</ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="title">Title</Label>
                <Controller
                  name="title"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Title is required" }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="text"
                      id="title"
                      placeholder="Enter title"
                      invalid={!!errors.title}
                    />
                  )}
                />
                {errors.title && (
                  <div className="text-danger">{errors.title.message}</div>
                )}
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="description">Description</Label>
                <Controller
                  name="description"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Description is required" }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="text"
                      id="description"
                      placeholder="Enter description"
                      invalid={!!errors.description}
                    />
                  )}
                />
                {errors.description && (
                  <div className="text-danger">
                    {errors.description.message}
                  </div>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label for={categoryFieldName}>Category</Label>
                <Controller
                  name={categoryFieldName}
                  control={control}
                  defaultValue=""
                  rules={{ required: "Category is required" }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="select"
                      id={categoryFieldName}
                      invalid={!!errors[categoryFieldName]}
                    >
                      {categories.map((category) => (
                        <option key={category.id} value={category.id}>
                          {category.title}
                        </option>
                      ))}
                    </Input>
                  )}
                />
                {errors[categoryFieldName] && (
                  <div className="text-danger">
                    {errors[categoryFieldName].message}
                  </div>
                )}
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="artist">Artist</Label>
                <Controller
                    name="artist_id"
                    control={control}
                    defaultValue={artists && artists.length === 1 ? artists[0].id : ""}
                    rules={{ required: "Artist is required" }}
                    render={({ field }) => (
                        <Input
                            {...field}
                            type="select"
                            id="artist"
                            invalid={!!errors.artist_id}
                        >
                          {artists && artists.map((artist) => (
                              <option key={artist.id} value={artist.id}>
                                {artist.name}
                              </option>
                          ))}
                        </Input>
                    )}
                />
                {errors.artist_id && (
                    <div className="text-danger">{errors.artist_id.message}</div>
                )}
              </FormGroup>
            </Col>

          </Row>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="currencies">Currency</Label>
                <Controller
                  name="currencies"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Currency is required" }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="text"
                      id="currencies"
                      placeholder="Enter currency"
                      invalid={!!errors.currencies}
                    />
                  )}
                />
                {errors.currencies && (
                  <div className="text-danger">{errors.currencies.message}</div>
                )}
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="status">Status</Label>
                <Controller
                    name="status"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Status is required" }}
                    render={({ field }) => (
                        <Input
                            {...field}
                            type="select"
                            id="status"
                            invalid={!!errors.status}
                        >
                          <option value="">Select status</option>
                          <option value="paid">Paid</option>
                          <option value="unpaid">Unpaid</option>
                        </Input>
                    )}
                />
                {errors.status && (
                    <div className="text-danger">{errors.status.message}</div>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="amount">Amount</Label>
                <Controller
                    name="amount"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Amount is required" }}
                    render={({ field }) => (
                        <Input
                            {...field}
                            type="number"
                            id="amount"
                            placeholder="Enter amount"
                            invalid={!!errors.amount}
                        />
                    )}
                />
                {errors.amount && (
                    <div className="text-danger">{errors.amount.message}</div>
                )}
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="file">File</Label>
                <Controller
                  name="file"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="file"
                      id="file"
                      onChange={(e) => {
                        handleFileChange(e);
                        field.onChange(e);
                      }}
                      invalid={!!errors.file}
                    />
                  )}
                />
                {errors.file && (
                  <div className="text-danger">{errors.file.message}</div>
                )}
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button
          type="submit"
          color="primary"
          onClick={handleSubmit(onSubmit)}
          style={{ margin: "0 auto" }}
        >
          {isLoading ? <TailChase /> : "Save"}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default NewExpensesModal;
