import React, { Fragment, useState } from "react";
import DataTable from "react-data-table-component";
import {Button, Spinner} from "reactstrap";
import { Trash, Edit } from "react-feather";
import UpdateAsset from "../Modals/UpdateModal";
import { useFormContext } from "react-hook-form";
import { getAudioFile } from "../../../../../../_helper/Images/ImageHelper";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import "react-h5-audio-player/lib/styles.less";
import { toast } from "react-hot-toast";
import { useReleaseContext } from "../../../../../../_helper/Release/ReleaseProvider";
import { useUpdateRelease } from "../../../../../../Hooks/useReleaseData";
import { useQueryClient } from "@tanstack/react-query";

const AssetTable = ({
                      assets,
                      artists,
                      genres,
                      fetchedProduct,
                      i,
                    }) => {
  const { deleteAssetById, loading } = useReleaseContext();
  const [selectedAsset, setSelectedAsset] = useState(null);
  const queryClient = useQueryClient();
  const [formGroups, setFormGroups] = useState([]);
  const { mutate: updateAsset, loader } = useUpdateRelease();

  const {
    control,
    errors,
    setValue,
    clearErrors,
    resetField,
    getValues,
    trigger,
  } = useFormContext();

  const UpdateAssetRecord = async () => {
    const isValid = await trigger();
    if (!isValid) {
      toast.error("Please make sure you at least provide the title & artist.");
      return;
    }
    const formData = getValues();
    const filteredData = Object.fromEntries(
        Object.entries(formData).filter(([_, value]) => value !== undefined && value !== "")
    );

    const cardIndex = 0;
    const card = {
      assetId: filteredData[`assetId_${cardIndex}`],
      title: filteredData[`title_${cardIndex}`],
      artist: filteredData[`artist_${cardIndex}`],
      audio_language: filteredData[`audio_language_${cardIndex}`],
      volume: filteredData[`volume_${cardIndex}`],
      metadata_language: filteredData[`metadata_language_${cardIndex}`],
      preview_text: filteredData[`preview_text_${cardIndex}`],
      preview_length: filteredData[`preview_length_${cardIndex}`],
      preview_start: filteredData[`preview_start_${cardIndex}`],
      lyrics: filteredData[`lyrics_${cardIndex}`],
      type: filteredData[`type_${cardIndex}`],
      class: filteredData[`genres_${cardIndex}`],
      explicit_content: filteredData[`explicit_content_${cardIndex}`],
      others_artists: filteredData[`others_artists_${cardIndex}`],
      copyrightYear: filteredData[`copyrightYear_${cardIndex}`],
      audio_file: filteredData[`audio_file_${cardIndex}`],
      asset_rights_claim: filteredData[`asset_rights_claim_${cardIndex}`],
      contributors: formGroups.map((_, i) => ({
        id: filteredData[`contributor_id_${i}`],
        name: filteredData[`contributor_name_${i}`],
        role: filteredData[`contributor_role_${i}`],
        percentage: filteredData[`contributor_percentage_${i}`],
      })).filter(contributor => contributor.name && contributor.role && contributor.percentage),
    };

    console.log("card", card);

    updateAsset(card, {
      onSuccess: () => {
        toast.success("Asset is updated successfully!");
        //queryClient.invalidateQueries("assets");
        setSelectedAsset(null);
      },
      onError: (error) => {
        console.error("Error updating asset:", error);
        toast.error("An error occurred while updating your asset. If this persists, please contact the administrator.");
      },
    });
  };

  const confirmDeleteAsset = (id) => {
    toast((t) => (
        <span>
        Are you sure you want to delete this asset?
        <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center', gap: '10px' }}>
          <Button color="danger" size="sm" style={{ padding: '5px 10px' }} onClick={() => {
            toast.promise(
                handleDelete(id),
                {
                  loading: 'Deleting asset...',
                  success: 'Asset deleted successfully!',
                  error: 'Failed to delete asset.',
                }
            ).then(() => {
              toast.dismiss(t.id);
            });
          }}>
            Yes
          </Button>
          <Button color="secondary" size="sm" style={{ padding: '5px 10px' }} onClick={() => toast.dismiss(t.id)}>
            No
          </Button>
        </div>
      </span>
    ), {
      duration: Infinity
    });
  };

  const handleDelete = async (id) => {
    try {
      const deleteResult = await deleteAssetById(id);
      if (deleteResult.success) {
        queryClient.invalidateQueries("assets");
      } else {
        throw new Error(deleteResult.error);
      }
    } catch (error) {
      console.error("Error deleting asset:", error);
    }
  };

  const handleCloseModal = () => {
    setSelectedAsset(null);
  };

  const columns = [
    {
      name: "Title",
      selector: (row) => row.title ? row.title : row.title_0 ? row.title_0 : "No title",
      sortable: true,
    },
    {
      name: "Artist",
      selector: (row) => row.assetartist?.name ? row.assetartist.name : row.artist_0 ? row.artist_0 : "No artist",
      sortable: true,
    },
    {
      name: "audio player",
      cell: (row) => (
          <div style={{ width: "100%" }}>
            {loader ? (
                <div className="loader-box">
                  <Spinner
                      attrSpinner={{
                        className: "loader-1",
                      }}
                  />
                </div>
            ) : (
                <AudioPlayer
                    autoPlay={false}
                    layout="horizontal-reverse"
                    showSkipControls={false}
                    showJumpControls={false}
                    showDownloadProgress={false}
                    customVolumeControls={[]}
                    src={getAudioFile(row.file)}
                    customAdditionalControls={[]}
                    preload={"auto"}
                    //onPlay={(e) => console.log("onPlay")}
                    style={{
                      background: "none",
                      boxShadow: "none",
                    }}
                />
            )}
          </div>
      ),
      width: "300px",
    },
    {
      name: "Actions",
      cell: (row) => (
          <div>
            <Trash
                size={20}
                className="me-2"
                onClick={() => confirmDeleteAsset(row.id)}
                title="Delete"
                style={{ cursor: "pointer" }}
            />
            <Edit
                size={20}
                onClick={() => setSelectedAsset(row)}
                title="Update"
                style={{ cursor: "pointer" }}
            />
          </div>
      ),
      center: true,
    }
  ];

  return (
      <Fragment>
        <DataTable
            data={assets}
            columns={columns}
            striped={true}
            pagination
            paginationPerPage={5}
            paginationRowsPerPageOptions={[5, 10, 15]}
            paginationComponentOptions={{
              rowsPerPageText: "Assets per page:",
              rangeSeparatorText: "of",
              noRowsPerPage: false,
              selectAllRowsItem: false,
              selectAllRowsItemText: "All",
            }}
        />
        <UpdateAsset
            show={selectedAsset !== null}
            handleClose={handleCloseModal}
            selectedAsset={selectedAsset}
            assetIndex={0}
            artists={artists}
            control={control}
            genres={genres}
            UpdateAssetRecord={UpdateAssetRecord}
            loading={loading}
            setValue={setValue}
            fetchedProduct={fetchedProduct}
            clearErrors={clearErrors}
            resetField={resetField}
            i={i}
            setFormGroups={setFormGroups}
        />
      </Fragment>
  );
};

export default AssetTable;