import AES from 'crypto-js/aes';
import Utf8 from 'crypto-js/enc-utf8';
import { enc } from 'crypto-js';

const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY; 

export const decryptToken = (encryptedToken) => {
    try {
        const decryptedToken = AES.decrypt(encryptedToken, encryptionKey).toString(Utf8);
        return decryptedToken;
    } catch (error) {
        console.error("Error decrypting token", error);
        return null;
    }
};

