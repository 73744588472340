export const formatSourceData = (sourceData) => {
    return sourceData.map(item => {
      const formattedItem = {};
      for (const key in item) {
        if (typeof item[key] === 'number') {
          formattedItem[key] = parseFloat(item[key]).toFixed(2);
        } else {
          formattedItem[key] = item[key];
        }
      }
      return formattedItem;
    });
  }