import React, { Fragment, useContext, useState, useEffect } from "react";
import { Breadcrumbs } from "../../../../AbstractElements";
//import { Add, Cancel, Loading } from '../../../../Constant';
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Container } from "reactstrap";
import CustomizerContext from "../../../../_helper/Customizer";
import FormLayout from "./FormLayout";
import UserContext from "../../../../_helper/User";
import LabelContext from "../../../../_helper/Label";
import { toast } from "react-hot-toast";
import { TypeaheadDataApi } from "../../../../api";
import axios from "axios";
const NewLabelPartner = () => {
  const history = useNavigate();
  const { layoutURL } = useContext(CustomizerContext);
  const { addNewUser } = useContext(UserContext);
  const { addNewPartner } = useContext(LabelContext);


  const [loading, setLoading] = useState(false);
  const PERMISSIONS = [
    'whitelist',
    'undercover',
    'mr_white6',
    'whitelist5',
    'undercover2',
    'mr_white2',
    'whitelist6',
    'undercover8',
  ];
  const [permissions, setPermissions] = useState(PERMISSIONS);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  
  const AddPartner = (data) => {
    if (data !== "") {
      setLoading(true);
      addNewPartner(data)
        .then(() => {
          toast.success("Partner created successfully!");
          history(
            `${process.env.PUBLIC_URL}/app/labels/labels-list/${layoutURL}`
          );
        })
        .catch(() => {
          toast.error("Partner registration failed.");
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      errors.showMessages();
    }
  };

  return (
    <Fragment>
      <Breadcrumbs
        parent="Labels"
        title="Add Partners"
        mainTitle="Create Partners"
      />
      <Container fluid={true}>
        <FormLayout
          register={register}
          loading={loading}
          errors={errors}
          handleSubmit={handleSubmit(AddPartner)}
          watch={watch}
          permissions={permissions}
        />
      </Container>
    </Fragment>
  );
};

export default NewLabelPartner;
