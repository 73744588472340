import axios from "../../api/axios";

const DspService = {
  getAllDsps: async () => {
    try {
      const response = await axios.get("/dsps/getAllDSPs");
      const { dsps } = response.data;
      return { success: true, dsps };
    } catch (error) {
      console.error("Failed to fetch DSPs", error);
      throw error;
    }
  },

  addDsp: async (dspData) => {
    try {
      const response = await axios.post("/dsps/addDSP", dspData);
      return { success: true, data: response.data };
    } catch (error) {
      console.error("Failed to add DSP", error);
      throw error;
    }
  },

  updateDsp: async (dspId, updatedDspData) => {
    try {
      const response = await axios.post(`/releases/updateDSP/${dspId}`, updatedDspData);
      return { success: true, data: response.data };
    } catch (error) {
      console.error("Failed to update DSP", error);
      throw error;
    }
  },

  deleteDsp: async (dspId) => {
    try {
      const response = await axios.delete(`/dsps/deleteDSP/${dspId}`);
      return { success: true, data: response.data };
    } catch (error) {
      console.error("Failed to delete DSP", error);
      throw error;
    }
  },
};

export default DspService;