import React, { Fragment, useState, useEffect } from "react";
import { Container } from "reactstrap";
import { Breadcrumbs, Btn } from "../../../../AbstractElements";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import { useForm } from "react-hook-form";
import { Stepper, Step } from "react-form-stepper";
import StepThree from "./StepThree";
import { useReleaseContext } from "../../../../_helper/Release/ReleaseProvider";
import moment from "moment";
import { toast } from "react-hot-toast";
import { useDspContext } from "../../../../_helper/DSP/DspProvider";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const NewRelease = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [productId, setProductId] = useState(null);
  const [selectedDsps, setSelectedDsps] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [formGroups, setFormGroups] = useState([]); // Add this state
  const { dsps, fetchDsp } = useDspContext();
  const {
    artists,
    saveThirdStepData,
    saveStepData,
    genres,
    fetchArtists,
    countries,
    fetchGenres,
    saveSecondStepData,
    getAllCountries,
    loading: StepsLoading,
  } = useReleaseContext();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    resetField,
    unregister,
    trigger,
    clearErrors,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [cards, setCards] = useState([0]);

  const addForm = () => {
    setCards((prevCards) => [...prevCards, prevCards.length]);
  };

  const deleteForm = (index) => {
    setCards((prevCards) => prevCards.filter((card) => card !== index));
    resetField(`title_${index}`);
    resetField(`assetId_${index}`);
    resetField(`artist_${index}`);
    resetField(`copyrightYear_${index}`);
    resetField(`genres_${index}`);
    resetField(`asset_rights_claim_${index}`);
    resetField(`explicit_content_${index}`);
    resetField(`type_${index}`);
    //resetField(`tags_${index}`);
    //resetField(`others_artists_${index}`);
    resetField(`copyrightLine_${index}`);
    resetField(`audio_language_${index}`);
    resetField(`asset_rights_claim_${index}`);
    resetField(`volume_${index}`);
    resetField(`preview_text_${index}`);
    resetField(`preview_length_${index}`);
    resetField(`preview_start_${index}`);
    resetField(`metadata_language_${index}`);
    resetField(`lyrics_${index}`);
    resetField(`audio_file_${index}`);
  };

  const handleNext = async () => {
    const isValid = await trigger();
    if (!isValid) {
      return;
    }
    const formData = getValues();
    const releaseDate = moment(formData.releaseDate).format();
    const data = { ...formData, releaseDate };
  
    try {
      const response = await saveStepData(activeStep, data);
      if (response && response.success) {
        toast.success("Release step 1/3 successfully saved!");
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        reset();
        setProductId(response.data.product);
      } else {
        toast.error(response.error || "An error occurred while creating your product In Step 1.");
      }
    } catch (error) {
      if (error.response && error.response.status === 403) {
        const errorMessage = error.response.data.error || "Monthly product limit reached";
        toast.error(errorMessage, {
          style: {
            background: '#ff4d4f',
            color: '#ffffff',
          }
        });
      } else {
        toast.error("An error occurred while creating your product In Step 1.");
      }
    }
  };
  const handleNextSecondStep = async () => {
        const isValid = await trigger();
        if (!isValid) {
            return;
        }
        const formData = getValues();
        const filteredData = Object.fromEntries(
            Object.entries(formData).filter(([_, value]) => value !== undefined)
        );

        console.log(formData);

        const cardsData = cards.map((cardIndex) => {
            const card = {
                title: filteredData[`title_${cardIndex}`],
                artist: filteredData[`artist_${cardIndex}`],
                audio_language: filteredData[`audio_language_${cardIndex}`],
                volume: filteredData[`volume_${cardIndex}`],
                metadata_language: filteredData[`metadata_language_${cardIndex}`],
                preview_text: filteredData[`preview_text_${cardIndex}`],
                preview_length: filteredData[`preview_length_${cardIndex}`],
                preview_start: filteredData[`preview_start_${cardIndex}`],
                lyrics: filteredData[`lyrics_${cardIndex}`],
                audio_file: filteredData[`audio_file_${cardIndex}`],
                type: filteredData[`type_${cardIndex}`],
                class: filteredData[`genres_${cardIndex}`],
                explicit_content: filteredData[`explicit_content_${cardIndex}`],
                copyrightYear: filteredData[`copyrightYear_${cardIndex}`],
                asset_rights_claim: filteredData[`asset_rights_claim_${cardIndex}`],
                others_artists: filteredData[`others_artists_${cardIndex}`],
                contributors: formGroups.map((_, i) => ({
                    name: filteredData[`contributor_name_${cardIndex}_${i}`],
                    role: filteredData[`contributor_role_${cardIndex}_${i}`],
                    percentage: filteredData[`contributor_percentage_${cardIndex}_${i}`],
                })).filter(contributor => contributor.name && contributor.role && contributor.percentage),
            };
            return card;
        });

        const data = { productId, cards: cardsData };

        try {
            const response = await saveSecondStepData(data);
            if (response && response.status === true) {
                toast.success("Release step 2/3 successfully saved!");
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                reset();
            } else {
                toast.error("An error occurred while creating your product In Step 2.");
            }
        } catch (error) {
            toast.error("An error occurred while creating your product In Step 2.");
        }
    };

  const handleStepThreeSubmit = async () => {
    const data = {
      productId,
      selectedDsps,
      selectedCountries,
    };
    try {
      const response = await saveThirdStepData(data);
      toast.success("Your product has been created successfully!");
      navigate(`${process.env.PUBLIC_URL}/app/releases/catalog/:layout`);
    } catch (error) {
      toast.error("An error occurred while creating your product In Step 3.");
    }
  };

  const handleCancel = async () => {
    const confirmed = await Swal.fire({
      title: "Are you sure?",
      text: "This will delete the product and all associated data.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (confirmed.isConfirmed) {
      window.location.reload();
    }
  };

  useEffect(() => {
    fetchArtists();
    fetchGenres();
    fetchDsp();
    getAllCountries();
  }, []);

  const handleBack = (event) => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    event.preventDefault();
  };

  return (
      <Fragment>
        <Breadcrumbs
            mainTitle="Releases"
            parent="Releases"
            title="Build Release"
            buttons={
              activeStep === 0
                  ? [
                    <Btn
                        className="btn btn-primary"
                        attrBtn={{
                          size: "l",
                          color: "primary",
                          onClick: handleNext,
                        }}
                    >
                      <i className="fa fa-arrow-circle-right me-1"></i>NextStep
                    </Btn>,
                  ]
                  : activeStep === 1
                      ? [

                          // <Btn
                          //     className="btn btn-primary"
                          //     attrBtn={{
                          //       type: "button",
                          //       size: "l",
                          //       color: "primary",
                          //       onClick: handleCancel,
                          //     }}
                          // >
                          //   <i className="fa fa-stop-circle-o me-1"></i>Cancel
                          // </Btn>,
                        <Btn
                            className="btn btn-primary"
                            attrBtn={{
                              size: "l",
                              color: "primary",
                              onClick: handleNextSecondStep,
                            }}
                        >
                          <i className="fa fa-arrow-circle-right me-1"></i>NextStep
                        </Btn>,
                        <Btn
                            className="btn btn-primary"
                            attrBtn={{
                              size: "l",
                              color: "primary",
                              onClick: (e) => {
                                addForm();
                              },
                            }}
                        >
                          <i className="fa fa-plus-square me-1"></i>Add Asset
                        </Btn>,
                      ]
                      : [
                        <Btn
                            className="btn btn-primary"
                            attrBtn={{
                              type: "button",
                              size: "l",
                              color: "primary",
                              onClick: handleCancel,
                            }}
                        >
                          <i className="fa fa-stop-circle-o me-1"></i>Cancel
                        </Btn>,
                        <Btn
                            className="btn btn-primary"
                            attrBtn={{
                              size: "l",
                              color: "primary",
                              outline: true,
                              type: "submit",
                              onClick: handleStepThreeSubmit,
                            }}
                        >
                          <i className="fa fa-check-circle-o me-2"></i>Submit
                        </Btn>,
                      ]
            }
        />
        <Container fluid={true}>
          <Stepper activeStep={activeStep} style={{ display: "none" }}>
            <Step label="Step One" />
            <Step label="Step Two" />
            <Step label="Step Three" />
          </Stepper>

          {activeStep === 0 && (
              <StepOne
                  register={register}
                  loading={StepsLoading}
                  errors={errors}
                  control={control}
                  activeStep={activeStep}
                  handleNext={handleSubmit(handleNext)}
                  artists={artists}
                  genres={genres}
                  setValue={setValue}
                  getValues={getValues}
                  clearErrors={clearErrors}
                  handleCancel={handleCancel}
              />
          )}
          {activeStep === 1 && (
              <StepTwo
                  register={register}
                  loading={StepsLoading}
                  errors={errors}
                  control={control}
                  cards={cards}
                  deleteForm={deleteForm}
                  handleBack={handleBack}
                  handleNextSecondStep={handleSubmit(handleNextSecondStep)}
                  setValue={setValue}
                  getValues={getValues}
                  artists={artists}
                  reset={reset}
                  resetField={resetField}
                  unregister={unregister}
                  genres={genres}
                  handleCancel={handleCancel}
                  clearErrors={clearErrors}
                  setFormGroups={setFormGroups} // Pass the setFormGroups prop
              />
          )}
          {activeStep === 2 && (
              <StepThree
                  loading={StepsLoading}
                  setValue={setValue}
                  getValues={getValues}
                  dsps={dsps}
                  countries={countries}
                  handleStepThreeSubmit={handleStepThreeSubmit}
                  handleCancel={handleCancel}
                  selectedDsps={selectedDsps}
                  setSelectedDsps={setSelectedDsps}
                  selectedCountries={selectedCountries}
                  setSelectedCountries={setSelectedCountries}
              />
          )}
        </Container>
      </Fragment>
  );
};

export default NewRelease;