import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import DspService from "../Services/dsp/DspService";

const useDspData = () => {
  return useQuery({
    queryKey: ["dsps"],
    queryFn: DspService.getAllDsps
  });
};

const useAddDsp = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: DspService.addDsp,
    onSuccess: () => {
      queryClient.invalidateQueries("dsps");
    },
    onError: (error) => {
      console.error("Failed to add DSP", error);
    },
  });
};

const useUpdateDsp = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ dspId, updatedDspData }) => DspService.updateDsp(dspId, updatedDspData),
    onSuccess: () => {
      queryClient.invalidateQueries("dsps");
    },
    onError: (error) => {
      console.error("Failed to update DSP", error);
    },
  });
};

const useDeleteDsp = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: DspService.deleteDsp,
    onSuccess: () => {
      queryClient.invalidateQueries("dsps");
    },
    onError: (error) => {
      console.error("Failed to delete DSP", error);
    },
  });
};

export { useDspData, useAddDsp, useUpdateDsp, useDeleteDsp };