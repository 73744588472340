import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import ReleaseService from "../Services/release/ReleaseService";

const useReleaseData = (productId) => {
    return useQuery({
        queryKey: ["assets"],
        queryFn: () => ReleaseService.getProductById(productId),
    });
};

const useAddRelease = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ReleaseService.saveSecondStepData,
        onSuccess: () => {
            queryClient.invalidateQueries(["assets"]);
        },
        onError: (error) => {
            console.error("Failed to add release", error);
        },
    });
};

const useUpdateRelease = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (data) => ReleaseService.updateAsset(data),
        onSuccess: () => {
            queryClient.invalidateQueries("assets");
        },
        onError: (error) => {
            console.error("Failed to update release", error);
        },
    });
};

const useDeleteRelease = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ReleaseService.deleteAssetById,
        onSuccess: () => {
            queryClient.invalidateQueries("assets");
        },
        onError: (error) => {
            console.error("Failed to delete release", error);
        },
    });
};

export { useReleaseData, useAddRelease, useUpdateRelease, useDeleteRelease };