import React, { Fragment, useState } from "react";
import DataTable from "react-data-table-component";
import { Btn, UL, LI, Image } from "../../../../AbstractElements";
import Swal from "sweetalert2";
import moment from "moment";
import AdditionalRowsModal from "./AdditionalRowsModal";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-hot-toast";
import EmptyDataTable from "../../../Dashboard/Common/EmptyDataTable";
import { useDspData, useUpdateDsp, useDeleteDsp } from "../../../../Hooks/useDspData";

const DspTable = ({
                    inUseFilter,
                    setInUseFilter,
                  }) => {
  const { data, isLoading, error } = useDspData();
  const dsps = data?.dsps || [];
  const updateDspMutation = useUpdateDsp();
  const deleteDspMutation = useDeleteDsp();
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleDelete, setToggleDelete] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { register, trigger, getValues, control, setValue } = useForm();

  const handleDelete = (dspId) => {
    Swal.fire({
      title: "Are you sure?",
      text: `Are you sure you want to delete this DSP?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteDspMutation.mutateAsync(dspId);
          toast.success("DSP deleted successfully");
        } catch (error) {
          toast.error("Failed to delete DSP. Please try again.");
        }
      }
    });
  };

  const handleUpdate = async () => {
    const isValid = await trigger();
    if (!isValid) {
      return;
    }
    const formData = getValues();
    try {
      await updateDspMutation.mutateAsync({ dspId: selectedRows.id, updatedDspData: formData });
      toast.success("DSP updated successfully!");
      handleCloseModal();
    } catch (error) {
      toast.error("Failed to update DSP. Please try again.");
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowMore = (row) => {
    setSelectedRows(row);
    setShowModal(true);
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row["name"],
      sortable: true,
    },
    {
      name: "URL",
      selector: (row) => row["url"],
      sortable: true,
    },
    {
      name: "Logo",
      cell: (row) => (
          <div>
            <UL attrUL={{ className: "d-inline-block" }}>
              <LI attrLI={{ className: "d-inline-block " }}>
                <Image
                    attrImage={{
                      className: "img-80",
                      src: row["logo"],
                      alt: "Dsp Image",
                    }}
                />
              </LI>
            </UL>
          </div>
      ),
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row["status"],
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => row["description"],
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
          <div className="todo">
            <div className="todo-list-wrapper">
              <div className="todo-list-container todo-list-footer">
                <ul className="simple-list">
                  <li className="task border-0">
                    <div className="task-container">
                    <span className="task-action-btn">
                      <span
                          className="action-box large icon-edit"
                          title="Update"
                          onClick={() => handleShowMore(row)}
                      >
                        <i className="icon">
                          <i className="fa fa-edit"></i>
                        </i>
                      </span>
                      <span
                          className="action-box large delete-btn"
                          title="Delete"
                          onClick={() => handleDelete(row.id)}
                      >
                        <i className="icon">
                          <i className="icon-trash"></i>
                        </i>
                      </span>
                    </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
      ),
      center: true,
    },
    {
      name: "Territories",
      selector: (row) => row["territories"],
      sortable: true,
    },
    {
      name: "Lead Time",
      selector: (row) => moment(row["lead_time"]).format("YYYY-MM-DD HH:mm:ss"),
      sortable: true,
    },
    {
      name: "XML File",
      selector: (row) => row["xml_file"],
      sortable: true,
    },
    {
      name: "Created At",
      selector: (row) =>
          moment(row["created_at"]).format("YYYY-MM-DD HH:mm:ss"),
      sortable: true,
    },
    {
      name: "Updated At",
      selector: (row) =>
          moment(row["updated_at"]).format("YYYY-MM-DD HH:mm:ss"),
      sortable: true,
    },
  ];

  const filteredData = dsps.filter((item) => {
    if (inUseFilter === null) {
      return true;
    } else {
      return item.in_use === (inUseFilter ? 1 : 0);
    }
  });

  const initialColumns = columns.slice(0, 6);

  return (
      <Fragment>
        {filteredData.length > 0 ? (
            <DataTable
                data={filteredData}
                columns={initialColumns}
                striped={true}
                center={true}
                pagination
                paginationPerPage={5}
                clearSelectedRows={toggleDelete}
                highlightOnHover
            />
        ) : (
            <EmptyDataTable />
        )}

        <AdditionalRowsModal
            showModal={showModal}
            handleCloseModal={handleCloseModal}
            data={filteredData}
            selectedRows={selectedRows}
            handleUpdate={handleUpdate}
            register={register}
            Controller={Controller}
            control={control}
            setValue={setValue}
        />
      </Fragment>
  );
};

export default DspTable;