import axios from "../../api/axios";

const WhitelistService = {
  getAllWhitelists: async () => {
    try {
      const response = await axios.get("/whitelist");
      return { success: true, data: response.data.whitelists };
    } catch (error) {
      console.error("Failed to fetch whitelists", error);
      throw error;
    }
  },

  getWhitelistById: async (id) => {
    try {
      const response = await axios.get(`/whitelist/${id}`);
      return { success: true, data: response.data };
    } catch (error) {
      console.error(`Failed to fetch whitelist with id ${id}`, error);
      throw error;
    }
  },

  createWhitelist: async (whitelistData) => {
    try {
      const response = await axios.post("/whitelist", whitelistData);
      return { success: true, data: response.data };
    } catch (error) {
      console.error("Failed to create whitelist", error);
      throw error;
    }
  },

  updateWhitelist: async (id, whitelistData) => {
    try {
      const response = await axios.put(`/whitelist/${id}`, whitelistData);
      return { success: true, data: response.data };
    } catch (error) {
      console.error(`Failed to update whitelist with id ${id}`, error);
      throw error;
    }
  },

  deleteWhitelist: async (id) => {
    try {
      const response = await axios.delete(`/whitelist/${id}`);
      return { success: true, data: response.data };
    } catch (error) {
      console.error(`Failed to delete whitelist with id ${id}`, error);
      throw error;
    }
  },
};

export default WhitelistService;