import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import WhitelistService from "../Services/tools/WhitelistService";

const useWhitelistData = () => {
  return useQuery({
    queryKey: ["whitelists"],
    queryFn: WhitelistService.getAllWhitelists,
  });
};

const useAddWhitelist = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: WhitelistService.createWhitelist,
    onSuccess: () => {
      queryClient.invalidateQueries("whitelists");
    },
    onError: (error) => {
      console.error("Failed to add whitelist", error);
    },
  });
};

const useUpdateWhitelist = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ whitelistId, updatedWhitelistData }) => WhitelistService.updateWhitelist(whitelistId, updatedWhitelistData),
    onSuccess: () => {
      queryClient.invalidateQueries("whitelists");
    },
    onError: (error) => {
      console.error("Failed to update whitelist", error);
    },
  });
};

const useDeleteWhitelist = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: WhitelistService.deleteWhitelist,
    onSuccess: () => {
      queryClient.invalidateQueries("whitelists");
    },
    onError: (error) => {
      console.error("Failed to delete whitelist", error);
    },
  });
};

export { useWhitelistData, useAddWhitelist, useUpdateWhitelist, useDeleteWhitelist };