import React, { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { v4 as uuidv4 } from 'uuid';
import { Btn } from "../../../../../AbstractElements";
import {
  Form,
  FormGroup,
  Input,
  Label,
  Col,
  InputGroup,
  InputGroupText,
} from "reactstrap";

export default function Contributors({
                                       handleNextSecondStep,
                                       control,
                                       errors,
                                       assetIndex,
                                       resetField,
                                       clearErrors,
                                       setValue,
                                       setFormGroups,
                                     }) {
  const { register } = useForm();

  const [formGroups, setLocalFormGroups] = useState([{ id: uuidv4() }]);
  const [contributors, setContributors] = useState([]);

  const handleAdd = () => {
    const newFormGroups = [...formGroups, { id: uuidv4() }];
    setLocalFormGroups(newFormGroups);
    setFormGroups(newFormGroups);
  };

  const handleDelete = (index) => {
    if (formGroups.length > 1) {
      const newFormGroups = formGroups.slice(0, -1);
      setLocalFormGroups(newFormGroups);
      setFormGroups(newFormGroups);
      resetField(`contributor_name_${assetIndex}_${index}`);
      resetField(`contributor_role_${assetIndex}_${index}`);
      resetField(`contributor_percentage_${assetIndex}_${index}`);
    }
  };

  useEffect(() => {
    register();
  }, [register]);

  console.log(contributors, "contributors");
  console.log(formGroups, "formGroups");

  return (
      <Form className="theme-form mt-3" onSubmit={handleNextSecondStep}>
        {formGroups.map(({ id }, i) => (
            <div className="row" key={id}>
              <FormGroup className="col-sm-3 mb-3">
                <Label className="col-form-label">Contributor Name</Label>
                <Controller
                    name={`contributor_name_${assetIndex}_${i}`}
                    control={control}
                    defaultValue={contributors[i]?.name || ""}
                    render={({ field }) => (
                        <Input
                            {...field}
                            type="text"
                            placeholder="Contributor Name"
                            className="form-control"
                            onChange={(e) => {
                              field.onChange(e);
                              clearErrors(`contributor_name_${assetIndex}_${i}`);
                            }}
                        />
                    )}
                />
              </FormGroup>
              <FormGroup className="col-sm-3 mb-3">
                <Label className="col-form-label">Role</Label>
                <Controller
                    name={`contributor_role_${assetIndex}_${i}`}
                    control={control}
                    defaultValue={contributors[i]?.role || ""}
                    render={({ field }) => (
                        <Input
                            {...field}
                            type="select"
                            placeholder="Select Type"
                            className="form-control"
                            onChange={(e) => {
                              field.onChange(e);
                              clearErrors(`contributor_role_${assetIndex}_${i}`);
                            }}
                        >
                          <option value="">Select...</option>
                          <option value="composer">Composer</option>
                          <option value="lyricist">Lyricist</option>
                          <option value="engineer">Mastering Engineer</option>
                          <option value="producer">Producer</option>
                        </Input>
                    )}
                />
              </FormGroup>
              <FormGroup className="col-sm-3 mb-3">
                <Label className="col-form-label">Percentage</Label>
                <InputGroup>
                  <Controller
                      name={`contributor_percentage_${assetIndex}_${i}`}
                      control={control}
                      defaultValue={contributors[i]?.percentage || ""}
                      render={({ field }) => (
                          <input
                              {...field}
                              className="form-control"
                              type="number"
                              placeholder="%"
                              onChange={(e) => {
                                field.onChange(e);
                                clearErrors(`contributor_percentage_${assetIndex}_${i}`);
                              }}
                          />
                      )}
                  />
                  <InputGroupText>{"%"}</InputGroupText>
                </InputGroup>
              </FormGroup>
              <Col className="m-t-40">
                <div style={{ display: "flex", gap: "10px" }}>
                  <FormGroup className="mb-3">
                    <Btn
                        attrBtn={{
                          type: "button",
                          size: "s",
                          color: "primary",
                          outline: true,
                          onClick: handleAdd,
                        }}
                    >
                      <i className="fa fa-plus-square me-1"></i>
                    </Btn>
                  </FormGroup>
                  {i > 0 && (
                      <FormGroup className="mb-3">
                        <Btn
                            attrBtn={{
                              type: "button",
                              size: "s",
                              color: "warning",
                              outline: true,
                              onClick: () => handleDelete(i),
                            }}
                        >
                          <i className="fa fa-minus-square"></i>
                        </Btn>
                      </FormGroup>
                  )}
                </div>
              </Col>
              <hr />
            </div>
        ))}
      </Form>
  );
}