import React, { Component } from 'react';
import { Container, Button } from 'reactstrap';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Error caught by ErrorBoundary:", error, errorInfo);
  }

  handleReload = () => {
    window.location.reload();
  };

  render() {
    if (this.state.hasError) {
      return (
        <Container style={{ textAlign: 'center', marginTop: '50px' }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ fontSize: '80px', color: 'red' }}>⚠️</div>
            <h4>Oops! Something went wrong.</h4>
            <p>An unexpected error has occurred. Please try refreshing the page or contact support if the problem persists.</p>
            <Button color="primary" onClick={this.handleReload}>
              Reload Page
            </Button>
          </div>
        </Container>
      );
    }

    return this.props.children; 
  }
}

export default ErrorBoundary;