//TODO: remove unnecessary imports
import { lazy } from "react";
//import Crypto from "../Components/Dashboard/Crypto";

// //Application



import AccountingContainer from "../Components/Application/Accounting";

// ? LazyLoad
// const SamplePage = React.lazy(() => import("../Components/Pages/Sample"));

import Default from "../Components/Dashboard/Default";
//import Ecommerce from "../Components/Dashboard/Ecommerce";
//import OnlineCourse from "../Components/Dashboard/OnlineCourse";
//import Social from "../Components/Dashboard/Social";

// //widgets
import Chart from "../Components/Widgets/Chart";
import General from "../Components/Widgets/General";

// //page-layout
// import FooterLight from '../Components/PageLayout/FooterLight';

// import FooterDark from '../Components/PageLayout/FooterDark';



// // Icons
import WeatherIcon from "../Components/Icons/WeatherIcon";
import FeatherIcon from "../Components/Icons/FeatherIcon";
import ThemifyIcon from "../Components/Icons/ThemifyIcon";
import IcoIcon from "../Components/Icons/IcoIcon";
import FontawesomeIcon from "../Components/Icons/FontawesomeIcon";
import FlagIcon from "../Components/Icons/FlagIcon";





// //Application
//import Newproject from "../Components/Application/Project/Newproject";
//import Project from "../Components/Application/Project/Project";
//* Admin
import User from "../Components/Application/Users/List";
import NewUser from "../Components/Application/Users/NewUser";
import Permissions from "../Components/Application/Users/Permissions";
import ManageLabels from "../Components/Application/ManageLabels";
//* Releases
import Catalog from "../Components/Application/Release/Catalog";
import ImportRelease from "../Components/Application/Release/Import/Index";
import NewRelease from "../Components/Application/Release/NewRelease";
import ReleasePage from "../Components/Application/Release/Catalog/ReleasePage";
//* Analytics
import Analytics from "../Components/Application/Analytics";
// import NewAnalytics from "../Components/Application/Analytics/NewAnalytics";

//* Label
import LabelsList from "../Components/Application/Labels/List";
import NewLabelPartner from "../Components/Application/Labels/AddArtist";

//* Artist
import NewStaff from "../Components/Application/Artist/Add";
import Artist from "../Components/Application/Artist/List";

//* Barcode
import BarcodeList from "../Components/Application/Barcode/List";

//* DSP
import DSPList from "../Components/Application/DSP/List";

//* SmartLink
import SmartLinkList from "../Components/Application/SmartLink/List";

//GeniusLink
import GeniusLayout from "../Components/Application/GeniuesLink/GeniusLayout";
import GeniusList from "../Components/Application/GeniuesLink/List";

//*Transfer
import PublicLink from "../Components/Application/SmartLink/Common/FileDisplayPage";


//* Invoices
import InvoicesContainer from "../Components/Application/Invoices";
import InvoiceDetails from "../Components/Application/Invoices/InvoiceDetails/InvoiceDetails";




// //Pages
import SamplePage from "../Components/Pages/Sample";
// // import SearchPage from '../Components/Application/SearchResult'



// //User app
import UsersCardssContain from "../Components/Application/Users/UsersCards";
import UsersEditContain from "../Components/Application/Profile/UsersEdit";



// // //Email
import MainExpenses from "../Components/Application/Expenses/Main-Expenses";
import ContributorExpenses from "../Components/Application/Expenses/Contrib-Expenses";
import ProductsContainer from "../Components/Application/Royalties";
import AssetContainer from "../Components/Application/Royalties/Assets";
import Contributors from "../Components/Application/Royalties/Contributors";
import ContributorsContainer from "../Components/Application/Royalties/Contributors";


import TicketsContainer from "../Components/Application/Ticket";
import TicketDetails from "../Components/Application/Ticket/TicketDetails";
import RedirectButton from "../Components/Application/Redirect";

import TodoContain from "../Components/Application/Todo";

import TagsContainer from "../Components/Application/Tools/Tags";
import ClaimList from "../Components/Application/Tools/Claim";
import WhitelistList from "../Components/Application/Tools/Whitelist";

// //Email


function IncomesContainer() {
  return null;
}

export const routes = [
  //todo: shared
  // // Dashboard
  {
    path: `${process.env.PUBLIC_URL}/dashboard/default/:layout`,
    Component: <Default />,
    roles: ["admin", "artist", "label", "staff"],
  },

  // // Releases
  {
    path: `${process.env.PUBLIC_URL}/app/releases/catalog/:layout`,
    Component: <Catalog />,
    roles: ["admin", "artist", "label", "staff"],
  },
  {
    path: `${process.env.PUBLIC_URL}/app/releases/import/:layout`,
    Component: <ImportRelease />,
    roles: ["admin", "artist", "label", "staff"],
  },
  {
    path: `${process.env.PUBLIC_URL}/app/releases/new-catalog/:layout`,
    Component: <NewRelease />,
    roles: ["admin", "artist", "label", "staff"],
  },
  {
    path: `${process.env.PUBLIC_URL}/app/releases/release-page/:id/:layout`,
    Component: <ReleasePage />,
    roles: ["admin", "artist", "label", "staff"],
  },

  // //SmartLink
  {
    path: `${process.env.PUBLIC_URL}/app/transfer/:layout`,
    Component: <SmartLinkList />,
    roles: ["admin", "artist", "label", "staff"],
  },
  //  { path: `${process.env.PUBLIC_URL}/app/transfer/:param/:layout`, Component: <PublicLink/> },
  // //GeniusLink
  /*{
    path: `${process.env.PUBLIC_URL}/app/geniuslink/geniuslink-list/:layout`,
    Component: <GeniusLayout />,
    roles: ["admin", "artist", "label", "staff"],
  },
  {
    path: `${process.env.PUBLIC_URL}/app/geniuslink/:layout`,
    Component: <GeniusList />,
    roles: ["admin", "artist", "label", "staff"],
  },*/

  ////Analytics
  {
    path: `${process.env.PUBLIC_URL}/app/analytics/manage/:layout`,
    Component: <Analytics />,
    roles: ["admin", "artist", "label", "staff"],
  },
  //: admins only
  //// Users
  {
    path: `${process.env.PUBLIC_URL}/app/users/users-list/:layout`,
    Component: <User />,
    roles: ["admin", "staff"],
  },
  {
    path: `${process.env.PUBLIC_URL}/app/users/new-user/:layout`,
    Component: <NewUser />,
    roles: ["admin", "staff"],
  },
  {
    path: `${process.env.PUBLIC_URL}/app/users/permissions/:layout`,
    Component: <Permissions />,
    roles: ["admin"],
  },
  {
    path: `${process.env.PUBLIC_URL}/app/labels/admin-labels/:layout`,
    Component: <ManageLabels />,
    roles: ["admin"],
  },

  ////Barcode
  {
    path: `${process.env.PUBLIC_URL}/app/barcode/barcode-list/:layout`,
    Component: <BarcodeList />,
    roles: ["admin"],
  },

  ////DSP
  {
    path: `${process.env.PUBLIC_URL}/app/dsp/dsp-list/:layout`,
    Component: <DSPList />,
    roles: ["admin"],
  },

  {
    path: `${process.env.PUBLIC_URL}/app/labels/labels-list/:layout`,
    Component: <LabelsList />,
    roles: ["admin", "label"],
  },
  {
    path: `${process.env.PUBLIC_URL}/app/labels/new-partner/:layout`,
    Component: <NewLabelPartner />,
    roles: ["admin", "label"],
  },

  ///// Accountings
  {
    path: `${process.env.PUBLIC_URL}/app/accounting/:layout`,
    Component: <AccountingContainer />,
    roles: ["admin", "artist", "label", "staff"],
  },
  {
    path: `${process.env.PUBLIC_URL}/app/expenses/main-expenses/:layout`,
    roles: ["admin", "artist", "label", "staff"],
    Component: <MainExpenses />,
  },

  ///// Invoices
   {
    path: `${process.env.PUBLIC_URL}/app/invoices/invoices-list/:layout`,
    Component: <InvoicesContainer />,
    roles: ["admin", "artist", "label", "staff"],
   },
   {
    path: `${process.env.PUBLIC_URL}/app/invoices/invoice-details/:uuid/:layout`,
    Component: <InvoiceDetails />,
    roles: ["admin", "artist", "label", "staff"],
   },
  {
    path: `${process.env.PUBLIC_URL}/app/expenses/contrib-expenses/:layout`,
    Component: <ContributorExpenses />,
    roles: ["admin", "artist", "label", "staff"],
  },

/*  {
    path: `${process.env.PUBLIC_URL}/app/tickets/:layout`,
    Component: <TicketsContainer />,
  },

  {
    path: `${process.env.PUBLIC_URL}/app/tickets/ticket-details/:id/:layout`,
    Component: <TicketDetails />,
  },*/

  {
    path: `${process.env.PUBLIC_URL}/app/tickets/redirect/:layout`,
    Component: <RedirectButton />,
    roles: ["admin", "label"],
  },

  // {
  //   path: `${process.env.PUBLIC_URL}/app/todo/:layout`,
  //   Component: <TodoContain />,
  //   roles: ["admin", "artist", "label", "staff"],
  // },



  //: Artist only

  // // Artist Staffs
  {
    path: `${process.env.PUBLIC_URL}/app/artist/artist-list/:layout`,
    Component: <Artist />,
    roles: ["admin", "artist"],
  },
  {
    path: `${process.env.PUBLIC_URL}/app/artist/new-staff/:layout`,
    Component: <NewStaff />,
    roles: ["admin", "artist"],
  },

  {
    path: `${process.env.PUBLIC_URL}/widgets/general/:layout`,
    Component: <General />,
  },
  {
    path: `${process.env.PUBLIC_URL}/widgets/chart/:layout`,
    Component: <Chart />,
  },
  

  {
    path: `${process.env.PUBLIC_URL}/icons/flag_icons/:layout`,
    Component: <FlagIcon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/icons/fontawesome_icon/:layout`,
    Component: <FontawesomeIcon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/icons/ico_icon/:layout`,
    Component: <IcoIcon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/icons/themify_icons/:layout`,
    Component: <ThemifyIcon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/icons/feather_icons/:layout`,
    Component: <FeatherIcon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/icons/weather_icons/:layout`,
    Component: <WeatherIcon />,
  },

  {
    path: `${process.env.PUBLIC_URL}/app/users/account/:layout`,
    Component: <UsersEditContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/users/cards/:layout`,
    Component: <UsersCardssContain />,
  },

  {
    path: `${process.env.PUBLIC_URL}/app/royalties`,
    Component: <ProductsContainer />,
  },

  {
    path: `${process.env.PUBLIC_URL}/app/products/:id/assets`,
    Component: <AssetContainer />,
    roles: ["admin", "staff"],
  },
  {
    path: `${process.env.PUBLIC_URL}/app/contributors`,
    Component: <Contributors/>,
    roles: ["admin", "staff"],
  },
  {
    path: `${process.env.PUBLIC_URL}/app/products/:id/assets/:assetId/contributors`,
    Component: <ContributorsContainer />,
    roles: ["admin", "staff"],
  },
  {
    path: `${process.env.PUBLIC_URL}/app/income/income-list`,
    Component: <IncomesContainer />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/tools/tags/:layout`,
    Component: <TagsContainer />,
  },

  {
    path: `${process.env.PUBLIC_URL}/app/tools/claim/:layout`,
    Component: <ClaimList />,
    roles: ["admin", "label" , "artist"],
  },

  {
    path: `${process.env.PUBLIC_URL}/app/tools/whitelist/:layout`,
    Component: <WhitelistList />,
    roles: ["admin", "label"],
  },


  // //page
  {
    path: `${process.env.PUBLIC_URL}/pages/sample-page/:layout`,
    Component: <SamplePage />,
  },

];
