import React, { Fragment, useState } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { Breadcrumbs, Btn } from "../../../../AbstractElements";
import HeaderCard from "../../../Common/Component/HeaderCard";
import BarcodeTable from "./BarcodeTable";
import { grid } from "ldrs";
import GridLoader from "../../../Loaders/GridLoader";
import { useBarcodeData, useImportBarcode, useDeleteBarcode } from "../../../../Hooks/useBarcodeData";

const BarcodeList = () => {
    grid.register();

    const [inUseFilter, setInUseFilter] = useState(null);

    const { data, isLoading } = useBarcodeData();
    const importMutation = useImportBarcode();
    const deleteMutation = useDeleteBarcode();

    const handleFilterUsed = () => {
        setInUseFilter(true);
    };

    const handleFilterNotUsed = () => {
        setInUseFilter(false);
    };

    const clearFilters = () => {
        setInUseFilter(null);
    };

    return (
        <Fragment>
            <Breadcrumbs
                parent="IPC Barcode"
                title="Barcode"
                mainTitle="barcodes Data"
                buttons={[
                    <Btn
                        attrBtn={{
                            size: "sm",
                            color: "primary",
                            onClick: () => document.getElementById("file-upload").click(),
                        }}
                    >
                        <i className="fa fa-download me-1"></i>Import
                    </Btn>,
                    <Btn
                        attrBtn={{
                            size: "sm",
                            color: "primary",
                            onClick: handleFilterUsed,
                        }}
                    >
                        <i className="fa fa-check-circle me-1"></i>Used
                    </Btn>,
                    <Btn
                        attrBtn={{
                            size: "sm",
                            color: "primary",
                            onClick: handleFilterNotUsed,
                        }}
                    >
                        <i className="fa fa-times-circle me-1"></i>Not Used
                    </Btn>,
                    <Btn
                        attrBtn={{
                            size: "sm",
                            color: "primary",
                            onClick: clearFilters,
                        }}
                    >
                        <i className="fa fa-eraser me-1"></i>Clear Filters
                    </Btn>,
                ]}
            />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <HeaderCard title="Manage your Barcodes" />
                            <CardBody>
                                {isLoading ? (
                                    <GridLoader />
                                ) : (
                                    <BarcodeTable
                                        upcs={data?.upcs}
                                        importData={importMutation.mutate}
                                        inUseFilter={inUseFilter}
                                        setInUseFilter={setInUseFilter}
                                        deleteUPC={deleteMutation.mutate}
                                    />
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

export default BarcodeList;