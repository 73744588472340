import { Breadcrumbs, H5 } from '../../../AbstractElements';
import AccountingTable from './AccountingTable';
import AccountingFilter from './AccountingFilter';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import React, { Fragment, useState, useEffect, useMemo } from 'react';
import Select from "react-select";
import makeAnimated from "react-select/animated";
import YoutubeContainer from './Youtube/YoutubeContainer';
import StreamingContainer from './Streaming/StreamingContainer';
import { useAuth } from '../../../_helper/Auth/AuthProvider';
import EmptyProvider from './NoProvider';
import { useLabelArtists } from '../../../Hooks/useAccountingData';
import {lighten} from "polished";

const AccountingContainer = () => {
    const [youtubeData, setYoutubeData] = useState([]);
    const [streamingData, setStreamingData] = useState([]);
    const { userData } = useAuth();
    const userRole = userData?.role;
    const color1 = userData?.label?.color1 || "#900C3F";
    const color2 = userData?.label?.color2 || "#581845";
    const lighterColor2 = lighten(0.4, color2);

    const animatedComponents = makeAnimated();

    const providerOptions = [
        { value: 'youtube', label: 'YouTube' },
        { value: 'streaming', label: 'Streaming' },
    ];
    const [dataProvider, setDataProvider] = useState(providerOptions.find(option => option.value === 'youtube'));

    const handleProviderChange = (selectedOption) => {
        setDataProvider(selectedOption);
    };

    const { data: labelArtists, isLoading: isLabelArtistsLoading, error: labelArtistsError } = useLabelArtists(userRole === 'label' ? userData : null);

    const YoutubeContainerMemo = useMemo(() => (
        <YoutubeContainer
            userRole={userRole}
            userData={userData}
            artistPermitted={true}
            labelArtists={labelArtists}
            isLabelArtistsLoading={isLabelArtistsLoading}
            labelArtistsError={labelArtistsError}
        />
    ), [userRole, labelArtists, isLabelArtistsLoading, labelArtistsError]);

    return (
        <Fragment>
            <Breadcrumbs
                mainTitle='Accounting'
                parent='Accounting'
                title='Accounting Management'
                buttons={[
                    <Select
                        onChange={handleProviderChange}
                        options={providerOptions}
                        defaultValue={dataProvider}
                        placeholder="Select provider..."
                        isClearable
                        components={animatedComponents}
                        closeMenuOnSelect={true}
                        className="mt-2"
                        styles={{
                            control: (styles, { isFocused, menuIsOpen }) => ({
                                ...styles,


                                //...(menuIsOpen && { borderColor: color2 }),
                                width: "300px",
                                fontSize: "15px",
                            }),
                            menu: (styles) => ({ ...styles }),
                            option: (styles, { isFocused, isSelected }) => ({
                                ...styles,
                                backgroundColor: isSelected ? color2 : lighterColor2,
                                //color: '#00000',
                            }),
                            //singleValue: (styles) => ({ ...styles, color: color2 }),
                        }}
                    />,
                ]}
            />
            <Container fluid={true} className="product-wrapper" id="product-wrapper">
                {!dataProvider && <EmptyProvider />}
                {dataProvider?.value === 'youtube' && YoutubeContainerMemo}
                {dataProvider?.value === 'streaming' && (
                    <StreamingContainer
                        userRole={userRole}
                        userData={userData}
                        labelArtists={labelArtists}
                        isLabelArtistsLoading={isLabelArtistsLoading}
                        labelArtistsError={labelArtistsError}
                    />
                )}
            </Container>
        </Fragment>
    );
};

export default AccountingContainer;