import React, { useState, useRef, useContext, useEffect } from "react";
import { Card, CardBody, Col, Badge, Button } from "reactstrap";
import { Btn, Image, H6, UL, LI } from "../../../../AbstractElements";
import user from "../../../../assets/images/user/user.png";
import { useAuth } from "../../../../_helper/Auth/AuthProvider";
import UserContext from "../../../../_helper/User";
import Themecustomizer from "../../../../Layout/ThemeCustomizer";
import { getProfileImageUrl } from "../../../../_helper/Images/ImageHelper";
import { toast } from "react-hot-toast";
import { ArrowDownRight, ArrowRight } from "react-feather";
const ProfileSideBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const { userData } = useAuth();
  const { uploadImage } = useContext(UserContext);
  const fileInputRef = useRef();

  useEffect(() => {
    if (selectedImage) {
      toast.success("Image uploaded successfully", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  }, [selectedImage]);

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const profilePictureUrl = getProfileImageUrl(userData.image);

  const handleImageChange = async (e) => {
    if (e.target.files && e.target.files[0]) {
      let img = e.target.files[0];
      const response = await uploadImage(img);
      if (response && response.success) {
        setSelectedImage(response.url);
      } else {
        toast.error("An error occurred while uploading image");
        console.error(
          "Failed to upload image",
          response ? response.error : "No response from uploadImage"
        );
      }
    }
  };

  return (
    <Col xl="3" className="xl-30 box-col-12">
      <div className="md-sidebar">
        <Btn
          attrBtn={{
            onClick: () => setIsOpen(!isOpen),
            color: "primary",
            size: "xs",
            // outline: true,
            className: "md-sidebar-toggle",
          }}
        >
          <ArrowRight />
        </Btn>
        <div
          className={`md-sidebar-aside job-left-aside custom-scrollbar ${
            isOpen && "open"
          } `}
        >
          <div className="email-left-aside">
            <Card className="social-profile">
              <CardBody>
                <div className="social-img-wrap">
                <div className="social-img" style={{ cursor: 'pointer' }} onClick={handleImageClick}>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                      ref={fileInputRef}
                      style={{ display: "none" }}
                    />
                    <Image
                      attrImage={{
                        src: profilePictureUrl || user,
                        alt: "profile",
                      }}
                    />
                  </div>

                  {/* <div className="edit-icon">
                    <Button
                      className="primary-color"
                      type="button"
                      onClick={handleImageClick}
                    >
                      Upload Image
                    </Button>
                  </div> */}
                     <div className="edit-icon">
                  <div
                    className="icon-wrapper"
                    onClick={handleImageClick}
                    style={{ cursor: "pointer" }}
                  >
                    <i className="icofont icofont-pencil-alt-5"></i>
                  </div>
                </div>
                </div>
             
                <div className="social-details">
                  <H6 attrH6={{ className: "mb-1" }}>{userData.fullname}</H6>
                  <span className="f-light">{userData.role}</span>
                  <SocialLinks />
                </div>
              </CardBody>
            </Card>

            <Themecustomizer />
          </div>
        </div>
      </div>
    </Col>
  );
};

export default ProfileSideBar;

const SocialLinks = () => {
  return (
    <UL
      attrUL={{
        horizontal: true,
        className: "d-flex justify-content-center card-social",
      }}
    >
      <LI>
        <a href="https://www.facebook.com/" target="_blank" rel="noreferrer">
          <i className="fa fa-facebook" />
        </a>
      </LI>
      <LI>
        <a href="https://accounts.google.com/" target="_blank" rel="noreferrer">
          <i className="fa fa-google-plus" />
        </a>
      </LI>
      <LI>
        <a href="https://twitter.com/" target="_blank" rel="noreferrer">
          <i className="fa fa-twitter" />
        </a>
      </LI>
      <LI>
        <a href="https://www.instagram.com/" target="_blank" rel="noreferrer">
          <i className="fa fa-instagram" />
        </a>
      </LI>
      <LI>
        <a href="https://rss.app/" target="_blank" rel="noreferrer">
          <i className="fa fa-rss" />
        </a>
      </LI>
    </UL>
  );
};
