import React, { Fragment, useState } from "react";
import { Container, Row, Col, Card, CardBody, Input, Button, Spinner, Badge } from "reactstrap";
import { Breadcrumbs } from "../../../../AbstractElements";
import TagsService from "../../../../Services/tools/TagsService";
import { useSnackbar } from 'notistack';


const TagsContainer = () => {
  const [keyword, setKeyword] = useState("");
  const [type, setType] = useState("youtubeTags");
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const handleGenerate = async () => {
    if (keyword.length < 3) {
      setError("Keyword must be at least 3 characters long.");
      return;
    }
    if (!type) {
      setError("Please select a tag type.");
      return;
    }

    setIsLoading(true);
    setError(null);
    try {
      const response = await TagsService.generateTags(keyword, type);
      setData(response.data.data);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCopy = (tag) => {
    navigator.clipboard.writeText(tag).then(() => {
      enqueueSnackbar(`Copied: ${tag}`, { variant: 'success' });
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  };

  return (
    <Fragment>
      <Breadcrumbs
        parent="Tools"
        title="tools"
        mainTitle="Tags"
      />
      <Container fluid={true}>
        <Row className="my-4">
          <Col sm="12">
            <Card>
              <CardBody>
                <Row className="mb-4">
                  <Col>
                    <p>
                      With the power of AI, you can generate relevant tags for your content. 
                      Simply enter a keyword, choose the type of tags you want, and click "Generate" to get started.
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Input
                      type="text"
                      placeholder="Enter keyword"
                      value={keyword}
                      onChange={(e) => setKeyword(e.target.value)}
                    />
                  </Col>
                  <Col md={4}>
                    <Input
                      type="select"
                      value={type}
                      onChange={(e) => setType(e.target.value)}
                    >
                      <option value="youtubeTags">YouTube Tags</option>
                      <option value="tiktokTags">TikTok Tags</option>
                    </Input>
                  </Col>
                  <Col md={2}>
                    <Button color="primary" onClick={handleGenerate}>
                      Generate
                    </Button>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col className="d-flex justify-content-center">
                    {isLoading && <Spinner color="primary" />}
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col>
                    {error && <div className="text-danger">{error}</div>}
                    {data && data.tags && (
                      <div>
                        <h5>Generated Tags:</h5>
                        <div>
                          {data.tags.map((tag, index) => (
                            <Badge 
                              key={index} 
                              color="secondary" 
                              className="mr-1" 
                              onClick={() => handleCopy(tag)}
                              style={{ cursor: 'pointer' }}
                            >
                              {tag}
                            </Badge>
                          ))}
                        </div>
                      </div>
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default TagsContainer;