import React, { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { Btn } from "../../../../../../../AbstractElements";
import {
    Form,
    FormGroup,
    Input,
    Label,
    Col,
    InputGroup,
    InputGroupText,
} from "reactstrap";

export default function ContributorUpdate({
                                              handleNextSecondStep,
                                              selectedAsset,
                                              clearErrors,
                                              resetField,
                                              setValue,
                                              control,
                                              errors,
                                              setFormGroups // Receive the setFormGroups prop
                                          }) {
    const [formGroups, setLocalFormGroups] = useState([]);

    useEffect(() => {
        if (selectedAsset && selectedAsset.contributors && selectedAsset.contributors.length > 0) {
            const newFormGroups = Array.from({ length: selectedAsset.contributors.length }, (_, i) => i);
            setLocalFormGroups(newFormGroups);
            setFormGroups(newFormGroups); // Update the formGroups in the parent component
            selectedAsset.contributors.forEach((contributor, index) => {
                setContributorValues(index, contributor);
            });
        } else {
            setLocalFormGroups([0]);
            setFormGroups([0]);
            setContributorValues(0, { id: "", name: "", role: "", percentage: "" });
        }
    }, [selectedAsset]);

    const handleAdd = () => {
        const newIndex = formGroups.length;
        const newFormGroups = [...formGroups, newIndex];
        setLocalFormGroups(newFormGroups);
        setFormGroups(newFormGroups);
        setContributorValues(newIndex, { id: "", name: "", role: "", percentage: "" });
    };

    const handleDelete = (index) => {
        const newFormGroups = formGroups.filter((_, i) => i !== index);
        setLocalFormGroups(newFormGroups);
        setFormGroups(newFormGroups);
        resetField(`contributor_id_${index}`);
        resetField(`contributor_name_${index}`);
        resetField(`contributor_role_${index}`);
        resetField(`contributor_percentage_${index}`);
    };

    const setContributorValues = (index, contributor) => {
        setValue(`contributor_id_${index}`, contributor?.id || "");
        setValue(`contributor_name_${index}`, contributor?.name || "");
        setValue(`contributor_role_${index}`, contributor?.role || "");
        setValue(`contributor_percentage_${index}`, contributor?.percentage || "");
    };

    return (
        <div key={selectedAsset?.id}>
            <Form className="theme-form mt-3" onSubmit={handleNextSecondStep}>
                {formGroups.map((index) => (
                    <div className="row" key={index}>
                        <FormGroup>
                            <Controller
                                name={`contributor_id_${index}`}
                                control={control}
                                render={({ field }) => (
                                    <input {...field} type="hidden" />
                                )}
                            />
                        </FormGroup>
                        <FormGroup className="col-sm-3 mb-3">
                            <Label className="col-form-label">Contributor Name</Label>
                            <Controller
                                name={`contributor_name_${index}`}
                                control={control}
                                render={({ field }) => (
                                    <Input
                                        {...field}
                                        type="text"
                                        placeholder="Contributor Name"
                                        className="form-control"
                                        onChange={(e) => {
                                            field.onChange(e);
                                            clearErrors(`contributor_name_${index}`);
                                        }}
                                    />
                                )}
                            />
                            {errors && errors[`contributor_name_${index}`] && (
                                <span style={{ color: "red" }}>
                                    {errors[`contributor_name_${index}`].message}
                                </span>
                            )}
                        </FormGroup>
                        <FormGroup className="col-sm-3 mb-3">
                            <Label className="col-form-label">Role</Label>
                            <Controller
                                name={`contributor_role_${index}`}
                                control={control}
                                render={({ field }) => (
                                    <Input
                                        {...field}
                                        type="select"
                                        placeholder="Select Type"
                                        className="form-control"
                                        onChange={(e) => {
                                            field.onChange(e);
                                            clearErrors(`contributor_role_${index}`);
                                        }}
                                    >
                                        <option value="">Select...</option>
                                        <option value="composer">Composer</option>
                                        <option value="lyricist">Lyricist</option>
                                        <option value="engineer">Mastering Engineer</option>
                                        <option value="producer">Producer</option>
                                    </Input>
                                )}
                            />
                        </FormGroup>
                        <FormGroup className="col-sm-3 mb-3">
                            <Label className="col-form-label">Percentage</Label>
                            <InputGroup>
                                <Controller
                                    name={`contributor_percentage_${index}`}
                                    control={control}
                                    render={({ field }) => (
                                        <input
                                            {...field}
                                            className="form-control"
                                            type="number"
                                            placeholder="%"
                                            onChange={(e) => {
                                                field.onChange(e);
                                                clearErrors(`contributor_percentage_${index}`);
                                            }}
                                        />
                                    )}
                                />
                                <InputGroupText>{"%"}</InputGroupText>
                            </InputGroup>
                        </FormGroup>
                        <Col className="m-t-40">
                            <div style={{ display: "flex", gap: "10px" }}>
                                <FormGroup className="mb-3">
                                    <Btn
                                        attrBtn={{
                                            type: "button",
                                            size: "s",
                                            color: "primary",
                                            outline: true,
                                            onClick: handleAdd,
                                        }}
                                    >
                                        <i className="fa fa-plus-square me-1"></i>
                                    </Btn>
                                </FormGroup>
                                {formGroups.length > 1 && (
                                    <FormGroup className="mb-3">
                                        <Btn
                                            attrBtn={{
                                                type: "button",
                                                size: "s",
                                                color: "warning",
                                                outline: true,
                                                onClick: () => handleDelete(index),
                                            }}
                                        >
                                            <i className="fa fa-minus-square"></i>
                                        </Btn>
                                    </FormGroup>
                                )}
                            </div>
                        </Col>
                        <hr />
                    </div>
                ))}
            </Form>
        </div>
    );
}