import React, { Fragment, useState } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { Breadcrumbs, Btn } from "../../../../AbstractElements";
import HeaderCard from "../../../Common/Component/HeaderCard";
import DspTable from "./DspTable";
import AddDspModal from "../NewDSP/AddDspModal";

const DspList = () => {
    const [inUseFilter, setInUseFilter] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const handleShowAddDspModal = () => {
        setShowModal(true);
    };

    return (
        <Fragment>
            <Breadcrumbs
                parent="DSP Management"
                title="DSPs"
                mainTitle="DSP Data"
                buttons={[
                    <Btn
                        attrBtn={{
                            size: "sm",
                            color: "primary",
                            onClick: handleShowAddDspModal,
                        }}
                    >
                        <i className="fa fa-plus me-1"></i>Add DSP
                    </Btn>,
                ]}
            />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <HeaderCard title="Manage your DSPs" />
                            <CardBody>
                                <DspTable
                                    inUseFilter={inUseFilter}
                                    setInUseFilter={setInUseFilter}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <AddDspModal
                show={showModal}
                handleClose={() => setShowModal(false)}
            />
        </Fragment>
    );
};

export default DspList;