import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardHeader, CardTitle, CardBody } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { H6, Alerts,H4, P } from '../../../../AbstractElements';
import Chart from 'react-apexcharts'
import { generateStreamByAsset } from './DownloadsData';
import AnalyticsService from '../../../../Services/analytics/AnalyticsService';
import GridLoader from '../../../Loaders/GridLoader';

const columns = [
  {
    name: 'Title',
    selector: row => row.title,
  },
  {
    name: 'Streams',
    selector: row => row.downloads,
  },
  {
    name: 'Streams Change',
    selector: row => row.downloads_change,
  },
];


const DownloadByAsset = ({selectedTimeRange}) => {
  const [loading, setLoading] = useState(true);
  const [edata, setEdata] = useState([]);
  const [error, setError] = useState(null);


  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await AnalyticsService.getDownloadsData(selectedTimeRange);
      setEdata(response.Downloads_by_asset);
      setError(null);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Error fetching data. Please try again later.'); 
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    fetchData(selectedTimeRange);
  }, [selectedTimeRange]);

  const downloadData = generateStreamByAsset(edata);

  return (
    <Container>
    {loading ? (
    <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
      <GridLoader />
    </div>
  ) : error ? (
    <div className="text-center">
      <Alerts attrAlert={{ color: 'primary ' }} >
        <H4 attrH4={{ className: 'alert-heading' }} >Error</H4>
        <P>{error}</P>
        <hr />
        <P attrPara={{ className: 'mb-0' }}>If you need immediate assistance, please contact support at support@reddiamond.com.</P>
      </Alerts>
    </div>
  ) : (
      <>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <CardTitle>
                <h6>Downloads Chart</h6>
              </CardTitle>
            </CardHeader>
            <CardBody>
                {downloadData.series.length ? (
                  <Chart options={downloadData.options} series={downloadData.series} type="line" height={350} />
                ) : (
                  <div className="text-center">Not available yet</div>
                )}
              </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <CardTitle>
                <H6>Asset Downloads</H6>
              </CardTitle>
            </CardHeader>
            <CardBody>
                <DataTable columns={columns} data={edata} pagination={true} paginationPerPage={10}/>
            </CardBody>
          </Card>
        </Col>
      </Row>
      </>
  )}
    </Container>
  );
};

export default DownloadByAsset;
