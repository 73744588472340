import React, { useEffect, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { Controller } from "react-hook-form";
import { Form, FormGroup, Input, Label, Row } from "reactstrap";
import { useAuth } from "../../../../../../../_helper/Auth/AuthProvider";
import {lighten} from "polished";

import Select from "react-select";
import makeAnimated from "react-select/animated";
export default function MetadataUpdate({
  i,
  artists,
  genres,
  handleNextSecondStep,
  control,
  errors,
  clearErrors,
  selectedAsset,
  fetchedProduct,
  setValue,
}) {
  useEffect(() => {
    if (fetchedProduct && selectedAsset) {
      let parsedOthersArtists = [];
      try {
        if (typeof selectedAsset.others_artists === 'string') {
          parsedOthersArtists = JSON.parse(selectedAsset.others_artists || '[]');
        } else if (Array.isArray(selectedAsset.others_artists)) {
          parsedOthersArtists = selectedAsset.others_artists;
        }
      } catch (error) {
        console.error('Error parsing others_artists:', error);
        parsedOthersArtists = [];
      }
  
      const selectedFromDB = (selectedAsset.other_artists || []).map(
        (artist) => ({
          value: artist?.id || '',
          label: artist?.name || '',
          selected: artist?.Selected === 1,
        })
      );
  
      const parsedArtistOptions = (parsedOthersArtists || []).map((artist) => ({
        value: artist?.value || '',
        label: artist?.label || '',
        selected: false,
      }));
  
      const selectedArtists = selectedFromDB.filter((artist) => artist.selected);
      const notSelectedArtists = selectedFromDB.filter((artist) => !artist.selected);
  
      const combinedArtists = [
        ...selectedArtists,
        ...parsedArtistOptions.filter(
          (parsedArtist) => 
            !selectedArtists.some((selectedArtist) => selectedArtist.value === parsedArtist.value)
        )
      ];
  
      const uniqueArtists = [
        ...selectedArtists,
        ...notSelectedArtists,
        ...parsedArtistOptions.filter(
          (parsedArtist) => 
            !selectedArtists.some((selectedArtist) => selectedArtist.value === parsedArtist.value) &&
            !notSelectedArtists.some((notSelectedArtist) => notSelectedArtist.value === parsedArtist.value)
        )
      ];
  
      setSelectedArtists(combinedArtists);
      setOtherArtists(uniqueArtists);
  
      setValue(`others_artists_${i}`, 
        combinedArtists.map(({ value, label }) => ({ value, label }))
      );
  
      setValue(`assetId_${i}`, selectedAsset?.id || "");
      setValue(`metadata_language_${i}`, selectedAsset?.metadata_language || "");
      setValue(`title_${i}`, selectedAsset?.title || "");
      setValue(`artist_${i}`, selectedAsset?.assetartist?.id_user || "");
      setValue(`copyrightYear_${i}`, selectedAsset?.copy_right_year || "");
      setValue(`genres_${i}`, selectedAsset?.genre || "");
      setValue(
        `asset_rights_claim_${i}`,
        selectedAsset?.asset_rights_claim || ""
      );
      setValue(`explicit_content_${i}`, selectedAsset?.explicit_content || "");
      setValue(`type_${i}`, selectedAsset?.type || "");
    }
  }, [fetchedProduct, selectedAsset, i, setValue]);

  const [selectedArtists, setSelectedArtists] = useState([]);
  const [otherArtists, setOtherArtists] = useState([]);

  const { userData } = useAuth();
  const color1 = userData?.label?.color1 || "#900C3F";
  const color2 = userData?.label?.color2 || "#FF5E6C";
  const lighterColor2 = lighten(0.4, color2);

  console.log("selected asset", selectedAsset);


  const animatedComponents = makeAnimated();
  return (
    <Form className="theme-form mt-3" onSubmit={handleNextSecondStep}>
      <div className="row">
        <FormGroup className="col-sm-3 mb-3">
          <Label className="col-form-label">Title</Label>

          <Controller
            name={`assetId_${i}`}
            control={control}
            defaultValue={selectedAsset?.id || ""}
            rules={{ required: "Title is required" }}
            render={({ field }) => (
              <input
                {...field}
                className="form-control"
                type="hidden"
                placeholder="Asset"
                onChange={(e) => {
                  field.onChange(e);
                  clearErrors(`assetId_${i}`);
                }}
              />
            )}
          />
          <Controller
            name={`title_${i}`}
            control={control}
            defaultValue={selectedAsset?.title || ""}
            rules={{ required: "Title is required" }}
            render={({ field }) => (
              <input
                {...field}
                className="form-control"
                type="text"
                placeholder="Title"
                onChange={(e) => {
                  field.onChange(e);
                  clearErrors(`title_${i}`);
                }}
              />
            )}
          />
          <span style={{ color: "red" }}>
            {errors && errors[`title_${i}`] && errors[`title_${i}`].message}
          </span> 
        </FormGroup>

        <FormGroup className="col-sm-3 mb-2">
          <Label className="col-form-label">Artist</Label>
          <Controller
            name={`artist_${i}`}
            control={control}
            defaultValue={selectedAsset?.Assetartist?.id_user}
            rules={{ required: true }}
            render={({ field }) => (
              <Input
                {...field}
                type="select"
                // disabled={fetchedProduct?.status !== "Draft"}
                placeholder="Select Artist"
                className="form-control "
                onChange={(e) => {
                  field.onChange(e);
                  clearErrors("artist");
                }}
              >
                <option key="artist-option" value="">
                  Select Artist...
                </option>
                {artists.map((artist) => (
                  <option key={artist.id_user} value={artist.id_user}>
                    {artist.name}
                  </option>
                ))}
              </Input>
            )}
          />
           <span style={{ color: "red" }}>
                {errors && errors.artist && "Artist is required"}
            </span> 
        </FormGroup>

        {/*  <FormGroup className="col-sm-3 mb-3">
          <Label className="col-form-label">ISRC</Label>
          <Controller
            name={`ISRC_${i}`}
            control={control}
            defaultValue=""
            rules={{ required: "ISRC is required" }}
            render={({ field }) => (
              <input
                {...field}
                className="form-control"
                type="text"
                placeholder="TN-A0E-24-00001"
              />
            )}
          />
          <span style={{ color: "red" }}>
            {errors[`ISRC_${i}`] && errors[`ISRC_${i}`].message}
          </span>
        </FormGroup> */}

        <FormGroup className="col-sm-3 mb-3">
          <Label className="col-form-label">Copyright Year</Label>
          <Controller
            name={`copyrightYear_${i}`}
            control={control}
            defaultValue={selectedAsset?.copy_right_year || ""}
            /* rules={{ required: "Copyright Year is required" }} */
            render={({ field }) => (
              <input
                {...field}
                className="form-control"
                type="number"
                placeholder="Copyright Year"
                onChange={(e) => {
                  field.onChange(e);
                  clearErrors(`copyrightYear_${i}`);
                }}
              />
            )}
          />
          {/*  <span style={{ color: "red" }}>
            {errors[`copyrightYear_${i}`] &&
              errors[`copyrightYear_${i}`].message}
          </span> */}
        </FormGroup>

        <FormGroup className="col-sm-3 mb-3">
          <Label className="col-form-label">Class</Label>
          <Controller
            name={`genres_${i}`}
            control={control}
            defaultValue={fetchedProduct?.genre}
            /* rules={{ required: true }} */
            render={({ field }) => (
              <Input
                {...field}
                type="select"
                // disabled={fetchedProduct?.status !== "Draft"}
                placeholder="Select genres"
                className="form-control"
                onChange={(e) => {
                  field.onChange(e);
                  clearErrors("genres");
                }}
              >
                <option value="">Select...</option>
                {genres.map((genre) => (
                  <option key={genre.id_genre} value={genre.id_genre}>
                    {genre.genre}
                  </option>
                ))}
              </Input>
            )}
          />
          {/* <span style={{ color: "red" }}>
                  {errors && errors.genres && "Genre is required"}
                </span> */}
        </FormGroup>
      </div>

      <div className="row">
          <FormGroup className="col-sm-3 mb-3">
              <Label className="col-form-label">Asset Rights Claim</Label>
              <Controller
                  name={`asset_rights_claim_${i}`}
                  control={control}
                  defaultValue={selectedAsset?.asset_rights_claim || ""}
                  render={({ field }) => (
                      <Input
                          {...field}
                          type="select"
                          className="form-control"
                          onChange={(e) => {
                              field.onChange(e);
                              clearErrors(`asset_rights_claim_${i}`);
                          }}
                      >
                          <option value="">Select...</option>
                          <option value="Monetize">Monetize</option>
                          <option value="Report">Report</option>
                          <option value="Block">Block</option>
                          <option value="No Claim">No Claim</option>
                      </Input>
                  )}
              />
          </FormGroup>

        {/*         <FormGroup className="col-sm-3 mb-3">
          <Label className="col-form-label">Status</Label>
          <Controller
            name={`status_${i}`}
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                {...field}
                type="select"
                placeholder="Select Status"
                className="form-control"
                onChange={(e) => {
                  field.onChange(e);
                  clearErrors(`status_${i}`);
                }}
              >
                <option value="">Select...</option>
                <option value="Approved">Approved</option>
                <option value="Delivered">Delivered</option>
                <option value="Draft">Draft</option>
                <option value="Takedown">Takedown</option>
              </Input>
            )}
          />
          <span style={{ color: "red" }}>
            {errors && errors[`status_${i}`] && errors[`status_${i}`].message}
          </span>
        </FormGroup> */}

        <FormGroup className="col-sm-3 mb-3">
          <Label className="col-form-label">Explicit Content</Label>
          <Controller
            name={`explicit_content_${i}`}
            control={control}
            defaultValue={selectedAsset?.explicit_content || ""}
            /* rules={{ required: "Explicit Content is required" }} */
            render={({ field }) => (
              <Input
                {...field}
                type="select"
                placeholder="Explicit Content "
                className="form-control"
                onChange={(e) => {
                  field.onChange(e);
                  clearErrors(`explicit_content_${i}`);
                }}
              >
                <option value="">Select...</option>
                <option value="Explicit">Explicit</option>
                <option value="No Explicit">No Explicit</option>
              </Input>
            )}
          />
          {/* <span style={{ color: "red" }}>
            {errors[`explicit_content_${i}`] &&
              errors[`explicit_content_${i}`].message}
          </span> */}
        </FormGroup>

        <FormGroup className="col-sm-3 mb-3">
          <Label className="col-form-label">Type</Label>
          <Controller
            name={`type_${i}`}
            control={control}
            defaultValue={selectedAsset?.type || ""}
            /* rules={{ required: "Type is required" }} */
            render={({ field }) => (
              <Input
                {...field}
                type="select"
                placeholder="Select Type"
                className="form-control"
                onChange={(e) => {
                  field.onChange(e);
                  clearErrors(`type_${i}`);
                }}
              >
                <option value="None">Select...</option>
                <option value="Album">Album</option>
                <option value="Single">Single</option>
              </Input>
            )}
          />
          {/* <span style={{ color: "red" }}>
            {errors[`type_${i}`] && errors[`type_${i}`].message}
          </span> */}
        </FormGroup>

        <FormGroup className="col-sm-3 mb-3">
          <Label className="col-form-label">Metadata language</Label>
          <Controller
            name={`metadata_language_${i}`}
            control={control}
            defaultValue={selectedAsset?.metadata_language || ""}
             /* rules={{ required: "MetaData language is required" }} */
            render={({ field }) => (
              <select 
              {...field} 
              className="form-control"
              onChange={(e) => {
                field.onChange(e);
                clearErrors(`metadata_language_${i}`);
              }}
              >
                <option value="">Select Language</option>
                <option value="en">English</option>
                <option value="es">Spanish</option>
                <option value="fr">French</option>
                <option value="de">German</option>
                <option value="it">Italian</option>
                <option value="zh">Chinese</option>
                <option value="ja">Japanese</option>
                <option value="ko">Korean</option>
                <option value="ar">Arabic</option>
                <option value="ru">Russian</option>
                
              </select>
            )}
          />


           <span style={{ color: "red" }}>
            {errors && errors[`metadata_language_${i}`] && errors[`metadata_language_${i}`].message}
          </span> 
        </FormGroup>


        {/*//TODO: Test when Tags are null */}
      {/* <FormGroup className="col-sm-3 mb-2">
        <Label className="col-form-label">Tags</Label>
        <Controller
          name={`tags_${i}`}
          control={control}
          rules={{ required: "Tags are required" }} 
          render={({ field }) => (
            <Typeahead
              {...field}
              id={`tags_${i}`}
              allowNew
              multiple
              defaultSelected={(selectedAsset?.tags || []).map((label) => ({ label }))}
              newSelectionPrefix="Add a new item: "
              options={[]}
              onChange={(selected) => {
                // Extracting labels from selected options
                const selectedLabels = selected.map((option) => option.label || option);
                // Updating form value
                setValue(`tags_${i}`, selectedLabels);
                // Clearing errors
                clearErrors(`tags_${i}`);
              }}
              placeholder="Type a Tag .."
            />
          )}
        />
          <span style={{ color: "red" }}>
          {errors[`tags_${i}`] && errors[`tags_${i}`].message}
        </span> 
      </FormGroup> */}
      </div>

      <Row>
        <FormGroup className="col-sm-6 mb-3">
          <Label>Other Artists</Label>
          {fetchedProduct?.status !== "Takedown" ? (
            <Controller
              name={`others_artists_${i}`}
              control={control}
              defaultValue={[]}
              /* rules={{ required: true }}  */
              render={({ field }) => (
                <Select
                  {...field}
                  isMulti={true}
                  className="basic-multi-select from-control"
                  classNamePrefix="select"
                  isLoading={false}
                  // isDisabled={fetchedProduct?.status !== "Draft"}
                  components={animatedComponents}
                  closeMenuOnSelect={false}
                  options={[
                    // Map artists to label-value pairs
                    ...artists.map((artist) => ({
                      value: artist.id_user,
                      label: artist.name,
                    })),
                    // Map otherArtists to label-value pairs
                    ...otherArtists.map((artist) => ({
                      value: artist.value,
                      label: artist.label,
                    })),
                  ]}
                  value={selectedArtists}
                  onChange={(selectedOptions) => {
                    // Combine existing and new selected options
                    const combinedOptions = [
                      ...selectedOptions,
                      ...otherArtists.filter(
                        existing => !selectedOptions.some(selected => selected.value === existing.value)
                      )
                    ];
                  
                    // Map selected options to their values
                    const selectedArtistsIds = combinedOptions.map(
                      ({ value, label }) => ({ value, label })
                    );
                  
                    setSelectedArtists(combinedOptions);
                    setValue(`others_artists_${i}`, selectedArtistsIds);
                  
                    // Update otherArtists state
                    setOtherArtists(
                      combinedOptions.filter(
                        (option) => !artists.some((artist) => artist.id_user === option.value)
                      )
                    );
                  }}
                
                />
              )}
            />
          ) : (
            <div>
              {selectedArtists.map((artist, index) => (
                <span key={index} className="badge bg-primary me-1 mb-1">
                  {artist.label}
                </span>
              ))}
              {selectedArtists.length === 0 && <p>No artists selected</p>}
            </div>
          )}
          {/* <span style={{ color: "red" }}>
              {errors && errors.other_artists && "Other Artists are required"}
            </span> */}
        </FormGroup>
      </Row>

      {/*<FormGroup className="mb-3">
        <Label className="col-form-label">Copyright Text :</Label>
        <Controller
          name={`copyrightLine_${i}`}
          control={control}
          defaultValue={selectedAsset?.copy_right_line || ""}
           rules={{ required: "Copyright Line is required" }}
          render={({ field }) => (
            <Input
              {...field}
              className="form-control"
              placeholder="Copyright Line"
              onChange={(e) => {
                field.onChange(e);
                clearErrors(`copyrightLine_${i}`);
              }}
            />
          )}
        />
         <span style={{ color: "red" }}>
          {errors[`copyrightLine_${i}`] && errors[`copyrightLine_${i}`].message}
        </span>
      </FormGroup>*/}

      {/* <Label className="col-form-label">Other Artists</Label>
                       <Typeahead
                         id="multiple-typeahead"
                         clearButton
                         defaultSelected={options.slice(0, 5)}
                         labelKey={"name"}
                         options={options}
                         placeholder="Choose an artist..."
                   /> */}
      <div className="mb-2"></div>
    </Form>
  );
}
