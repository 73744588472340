import axios from "../../api/axios";
import { SHA256 } from 'crypto-js';
import AES from 'crypto-js/aes';
import Utf8 from 'crypto-js/enc-utf8';

const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY; 

const encryptToken = (token) => {
  return AES.encrypt(token, encryptionKey).toString();
};

const hashToken = (token) => {
  return SHA256(token).toString();
};

const AuthService = {
  isAuthenticated: () => {
    return !!localStorage.getItem("authToken");
  },

  login: async (email, password) => {
    try {
      const response = await axios.post("/login", { email, password });
      const { user, token, trusted_device , profile_completed } = response.data;
  
      const encryptedToken = encryptToken(token);
      // console.log('Encrypted token: ', encryptedToken);
      localStorage.setItem("authToken", `encrypted:${encryptedToken}`);
      localStorage.setItem("authenticated", true);
  
      return { success: true, user, trusted_device, profile_completed };
    } catch (error) {
      console.error("Login failed", error);
      return { success: false, error };
    }
  },

  getUser: async () => {
    try {
      const response = await axios.get("users/me");
      const { user } = response.data.user;
      // console.log("user data from service", user)
      return { success: true, user: response.data.user , isCompleted: response.data.completed, sttHc: response.data.sttHc};
    } catch (error) {
      console.error("Failed to fetch user data", error);
      return { success: false, error };
    }
  },

  verifyEmail: async (token) => {
    try {
      const response = await axios.get(`/email/verify/${token}`);
      return { success: true, message: response.data.message };
    } catch (error) {
      console.error("Email verification failed", error);
      return { success: false, error };
    }
  },
  checkPassword: async (password) => {
    try {
      const response = await axios.post('users/check_password', {
        password: password
      });
      console.log("response from service", response.data.isPasswordCorrect)
      return response.data.isPasswordCorrect;
      
    } catch (error) {
      console.error("Password check failed", error);
      return false;
    }
  },

  getSecuritySettings: async () => {
    try {
      const response = await axios.get('users/security_settings');
      // console.log("response from service", response)
      return response.data;
    } catch (error) {
      console.error("Failed to get security settings", error);
      return null;
    }
  },

  getBankDetails: async () => {
    try {
      const response = await axios.get('users/bank_details');
      return { success: true, data: response.data.bankDetails };
    } catch (error) {
      console.error('Error fetching bank details', error);
      return { success: false, error };
    }
  },

  updateSecurityLayers: async (newPassword, isEmailOTP, isSmsOTP, isPinOTP, pinValue) => {
    try {
      const response = await axios.post('users/update_security_layers', {
        new_password: newPassword,
        isEmailOTP: isEmailOTP,
        isSmsOTP: isSmsOTP,
        isPinOTP: isPinOTP,
        pin: pinValue
      });
      return response.data;
    } catch (error) {
      console.error("Failed to update security layers", error);
      return null;
    }
  },
  logout: () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("authenticated");
  },
};

export default AuthService;
