import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { Form, FormGroup, Input, Label, Row } from "reactstrap";
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import { useAuth } from "../../../../../_helper/Auth/AuthProvider";
import { lighten } from "polished";

export default function Metadata({
                                     i,
                                     artists,
                                     genres,
                                     handleNextSecondStep,
                                     control,
                                     errors,
                                     clearErrors,
                                     fetchedProduct,
                                     setValue,
                                 }) {
    const { userData } = useAuth();
    const color1 = userData?.label?.color1 || "#900C3F";
    const color2 = userData?.label?.color2 || "#581845";
    const lighterColor2 = lighten(0.4, color2);

    useEffect(() => {
        if (fetchedProduct) {
            const selectedFromDB = (fetchedProduct.other_artists || []).map(artist => ({
                value: artist.id,
                label: artist.name,
                selected: artist.Selected === 1
            }));

            let myselect = [];
            if (Array.isArray(fetchedProduct.others_artists) && fetchedProduct.others_artists.length > 0) {
                myselect = fetchedProduct.others_artists.map(artist => ({
                    value: artist.value,
                    label: artist.label,
                    selected: false
                }));
            }

            const selectedArtists = selectedFromDB.filter(artist => artist.selected);
            const notSelectedArtists = selectedFromDB.filter(artist => !artist.selected);

            const combinedArtists = [...selectedArtists, ...myselect];
            const uniqueArtists = [...selectedArtists, ...notSelectedArtists];

            setSelectedArtists(combinedArtists);
            setOtherArtists(uniqueArtists);
        }
    }, [fetchedProduct]);

    const [selectedArtists, setSelectedArtists] = useState([]);
    const [otherArtists, setOtherArtists] = useState([]);
    const animatedComponents = makeAnimated();

    return (
        <Form className="theme-form mt-3" onSubmit={handleNextSecondStep}>
            <div className="row">
                <FormGroup className="col-sm-3 mb-3">
                    <Label className="col-form-label">Title</Label>
                    <Controller
                        name={`assetId_${i}`}
                        control={control}
                        render={({ field }) => (
                            <input
                                {...field}
                                className="form-control"
                                type="hidden"
                                placeholder="Asset"
                                onChange={(e) => {
                                    field.onChange(e);
                                    clearErrors(`assetId_${i}`);
                                }}
                            />
                        )}
                    />
                    <Controller
                        name={`title_${i}`}
                        control={control}
                        rules={{ required: "Title is required" }}
                        render={({ field }) => (
                            <input
                                {...field}
                                className="form-control"
                                type="text"
                                placeholder="Title"
                            />
                        )}
                    />
                    {errors && errors[`title_${i}`] && <span className="text-danger">{errors[`title_${i}`].message}</span>}
                </FormGroup>

                <FormGroup className="col-sm-3 mb-2">
                    <Label className="col-form-label">Artist</Label>
                    <Controller
                        name={`artist_${i}`}
                        control={control}
                        rules={{ required: "Artist is required" }}
                        render={({ field }) => (
                            <Input
                                {...field}
                                type="select"
                                placeholder="Select Artist"
                                className="form-control"
                                onChange={(e) => {
                                    field.onChange(e);
                                    clearErrors("artist");
                                }}
                            >
                                <option key="artist-option" value="">
                                    Select Artist...
                                </option>
                                {artists.map((artist) => (
                                    <option key={artist.id_user} value={artist.id_user}>
                                        {artist.name}
                                    </option>
                                ))}
                            </Input>
                        )}
                    />
                    {errors && errors[`artist_${i}`] && <span className="text-danger">{errors[`artist_${i}`].message}</span>}
                </FormGroup>

                <FormGroup className="col-sm-3 mb-3">
                    <Label className="col-form-label">Copyright Year</Label>
                    <Controller
                        name={`copyrightYear_${i}`}
                        control={control}
                        render={({ field }) => (
                            <input
                                {...field}
                                className="form-control"
                                type="text"
                                placeholder="Copyright Year"
                                onChange={(e) => {
                                    field.onChange(e);
                                    clearErrors(`copyrightYear_${i}`);
                                }}
                            />
                        )}
                    />
                </FormGroup>

                <FormGroup className="col-sm-3 mb-3">
                    <Label className="col-form-label">Class</Label>
                    <Controller
                        name={`genres_${i}`}
                        control={control}
                        render={({ field }) => (
                            <Input
                                {...field}
                                type="select"
                                placeholder="Select genres"
                                className="form-control"
                                onChange={(e) => {
                                    field.onChange(e);
                                    clearErrors("genres");
                                }}
                            >
                                <option value="">Select...</option>
                                {genres.map((genre) => (
                                    <option key={genre.id_genre} value={genre.id_genre}>
                                        {genre.genre}
                                    </option>
                                ))}
                            </Input>
                        )}
                    />
                </FormGroup>
            </div>

            <div className="row">
                <FormGroup className="col-sm-3 mb-3">
                    <Label className="col-form-label">Asset Rights Claim</Label>
                    <Controller
                        name={`asset_rights_claim_${i}`}
                        control={control}
                        render={({ field }) => (
                            <Input
                                {...field}
                                type="select"
                                className="form-control"
                                onChange={(e) => {
                                    field.onChange(e);
                                    clearErrors(`asset_rights_claim_${i}`);
                                }}
                            >
                                <option value="">Select...</option>
                                <option value="Monetize">Monetize</option>
                                <option value="Report">Report</option>
                                <option value="Block">Block</option>
                                <option value="No Claim">No Claim</option>
                            </Input>
                        )}
                    />
                </FormGroup>

                <FormGroup className="col-sm-3 mb-3">
                    <Label className="col-form-label">Explicit Content</Label>
                    <Controller
                        name={`explicit_content_${i}`}
                        control={control}
                        render={({ field }) => (
                            <Input
                                {...field}
                                type="select"
                                placeholder="Explicit Content"
                                className="form-control"
                                onChange={(e) => {
                                    field.onChange(e);
                                    clearErrors(`explicit_content_${i}`);
                                }}
                            >
                                <option value="">Select...</option>
                                <option value="Explicit">Explicit</option>
                                <option value="No Explicit">No Explicit</option>
                            </Input>
                        )}
                    />
                </FormGroup>

                <FormGroup className="col-sm-3 mb-3">
                    <Label className="col-form-label">Type</Label>
                    <Controller
                        name={`type_${i}`}
                        control={control}
                        render={({ field }) => (
                            <Input
                                {...field}
                                type="select"
                                placeholder="Select Type"
                                className="form-control"
                                onChange={(e) => {
                                    field.onChange(e);
                                    clearErrors(`type_${i}`);
                                }}
                            >
                                <option value="None">Select...</option>
                                <option value="Album">Album</option>
                                <option value="Single">Single</option>
                            </Input>
                        )}
                    />
                </FormGroup>

                <FormGroup className="col-sm-3 mb-3">
                    <Label className="col-form-label">Metadata language</Label>
                    <Controller
                        name={`metadata_language_${i}`}
                        control={control}
                        render={({ field }) => (
                            <select
                                {...field}
                                className="form-control"
                                onChange={(e) => {
                                    field.onChange(e);
                                    clearErrors(`metadata_language_${i}`);
                                }}
                            >
                                <option value="">Select Language</option>
                                <option value="en">English</option>
                                <option value="es">Spanish</option>
                                <option value="fr">French</option>
                                <option value="de">German</option>
                                <option value="it">Italian</option>
                                <option value="zh">Chinese</option>
                                <option value="ja">Japanese</option>
                                <option value="ko">Korean</option>
                                <option value="ar">Arabic</option>
                                <option value="ru">Russian</option>
                            </select>
                        )}
                    />
                </FormGroup>
            </div>

            <Row>
                <FormGroup className="col-sm-12 mb-3">
                    <Label>Other Artists</Label>
                    {fetchedProduct?.status !== "Takedown" ? (
                        <Controller
                            name={`others_artists_${i}`}
                            control={control}
                            defaultValue={[]}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    isMulti={true}
                                    className="basic-multi-select from-control"
                                    classNamePrefix="select"
                                    isLoading={false}
                                    closeMenuOnSelect={false}
                                    options={[
                                        ...artists.map(artist => ({
                                            value: artist.id_user,
                                            label: artist.name
                                        })),
                                        ...otherArtists.map(artist => ({
                                            value: artist.value,
                                            label: artist.label
                                        }))
                                    ]}
                                    value={field.value}
                                    onChange={selectedOptions => {
                                        const selectedArtistsIds = selectedOptions.map(({ value, label }) => ({ value, label }));
                                        setSelectedArtists(selectedOptions);
                                        setValue(`others_artists_${i}`, selectedArtistsIds);
                                        setOtherArtists(selectedOptions.filter(option => !artists.some(artist => artist.id_user === option.value)));
                                    }}
                                />
                            )}
                        />
                    ) : (
                        <div>
                            {selectedArtists.map((artist, index) => (
                                <span key={index} className="badge bg-primary me-1 mb-1">{artist.label}</span>
                            ))}
                            {selectedArtists.length === 0 && <p>No artists selected</p>}
                        </div>
                    )}
                </FormGroup>
            </Row>
        </Form>
    );
}