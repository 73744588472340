import React, { Fragment, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Pagination,
  PaginationItem,
  PaginationLink,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Spinner,
} from "reactstrap";
import {
  CheckCircle,
  // PlusCircle,
  Users,
  UserCheck,
  Briefcase,
  Music,
} from "react-feather";
import { Link } from "react-router-dom";
import {
  ALL,
  CreateNewUser,
  Admin,
  Label as label,
  Staff,
  Artist,
  Previous,
  Next,
} from "../../../../Constant";
import {
  Breadcrumbs,
  Btn,
  H6,
  Alerts,
  P,
  H4,
} from "../../../../AbstractElements";
import UserContext from "../../../../_helper/User";
import CusClass from "../Common/CusClass";
import CustomizerContext from "../../../../_helper/Customizer";
import { useAuth } from "../../../../_helper/Auth/AuthProvider";
import { ToastContainer, toast } from "react-toastify";
import GridLoader from "../../../Loaders/GridLoader";
// import TailChase from "../../../Loaders/TailChase";
const User = () => {
  const navigate = useNavigate();

  const { userData } = useAuth();

  const { layoutURL } = useContext(CustomizerContext);
  const [activeTab, setActiveTab] = useState("1");
  const {
    allData = [],
    getAllUsersData,
    updatePersonalDetailsByadmin,
    paginationData,
    error,
  } = useContext(UserContext);
  const [searchTerm, setSearchTerm] = useState("");
  const [modal, setModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loading, setLoading] = useState(false);
  //For User List Loader
  const [loadingFetch, setLoadingFetch] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  // const [usersPerPage] = useState(10); // Number of users per page
  // const [totalUsers, setTotalUsers] = useState(0);

  const toggleModal = () => {
    setModal(!modal);
  };

  //todo: Migrate to Fuse.js
  //? Search Functionality
  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredUsers = allData.filter((user) =>
    user.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const openEditModal = (user) => {
    setSelectedUser(user);
    toggleModal();
  };
  // console.log("paginationData", paginationData);

  const updateUser = async () => {
    try {
      setLoading(true);
      if (selectedUser) {
        const updatedUserData = {
          id: selectedUser.id_user,
          name: document.getElementById("editName").value,
          email: document.getElementById("editEmail").value,
          username: document.getElementById("editUsername").value,
          isSecured: document.getElementById("editStatus").value === "1",
          isHC: document.querySelector('input[name="isHC"]:checked').value === "true",
        };

        await updatePersonalDetailsByadmin(updatedUserData);
        toast.success("User updated successfully");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        console.error("No user selected for update");
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  };

  const fetchData = async (page, role = "") => {
    setLoadingFetch(true);
    try {
      await getAllUsersData(page, role);
      setLoadingFetch(false);
    } catch (error) {
      console.error(error);
      setLoadingFetch(false);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  // const indexOfLastUser = currentPage * usersPerPage;
  // const indexOfFirstUser = indexOfLastUser - usersPerPage;
  // const currentUsers = allData.slice(indexOfFirstUser, indexOfLastUser);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <Fragment>
        <Breadcrumbs
          parent="Users"
          title="Manage"
          mainTitle="Users"
          buttons={[
            <Btn
              attrBtn={{
                size: "l",
                color: "primary",
                // outline: true,
                onClick: () => {
                  navigate(
                    `${process.env.PUBLIC_URL}/app/users/new-user/${layoutURL}`
                  );
                },
              }}
            >
              <i className="fa fa-plus me-1"></i>
              {CreateNewUser}
            </Btn>,
          ]}
        />
        <Container fluid={true}>
          {error ? (
            <div className="text-center">
              <Alerts attrAlert={{ color: "primary " }}>
                <H4 attrH4={{ className: "alert-heading" }}>Error</H4>
                <P>{JSON.stringify(error.message)}</P>
                <hr />
                <P attrPara={{ className: "mb-0" }}>
                  If you need immediate assistance, please contact support at
                  support@reddiamond.com.
                </P>
              </Alerts>
            </div>
          ) : (
            <Row className="justify-content-center">
              <Col md="12" className="project-list">
                <Card>
                  <Row>
                    <Col md="6">
                      <Nav tabs className="border-tab">
                        <NavItem>
                          <NavLink
                            className={activeTab === "1" ? "active" : ""}
                            onClick={() => {
                              setActiveTab("1");
                              fetchData(1); // Fetch all users
                            }}
                          >
                            <Users />
                            {ALL}
                          </NavLink>
                        </NavItem>
                        {userData.role === "admin" && (
                          // Render the "Admin" tab only if the user is not a label
                          <>
                            {" "}
                            <NavItem>
                              <NavLink
                                className={activeTab === "2" ? "active" : ""}
                                onClick={() => {
                                  setActiveTab("2");
                                  fetchData(1, "admin"); // Fetch admin users when the "Admin" tab is clicked
                                }}
                              >
                                <UserCheck />
                                {Admin}
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={activeTab === "3" ? "active" : ""}
                                onClick={() => {
                                  setActiveTab("3");
                                  fetchData(1, "artist");
                                }}
                              >
                                <Music />
                                {Artist}
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={activeTab === "4" ? "active" : ""}
                                onClick={() => {
                                  setActiveTab("4");
                                  fetchData(1, "label");
                                }}
                              >
                                <CheckCircle />
                                {label}
                              </NavLink>
                            </NavItem>
                          </>
                        )}

                        {/* {userData.role !== "label" && (            
                      )} */}
                        <NavItem>
                          <NavLink
                            className={activeTab === "5" ? "active" : ""}
                            onClick={() => {
                              setActiveTab("5");
                              fetchData(1, "staff");
                            }}
                          >
                            <Briefcase />
                            {Staff}
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </Col>

                    {/* <Col md="6">
                    <div className="text-end">
                      <Link
                        className="btn btn-primary"
                        style={{ color: "white" }}
                        to={`${process.env.PUBLIC_URL}/app/users/new-user/${layoutURL}`}
                      >
                        {" "}
                        <PlusCircle />
                        {CreateNewUser}
                      </Link>
                    </div>
                  </Col> */}
                  </Row>
                </Card>
              </Col>
              <Col md="12">
                <Card>
                  <CardBody>
                    <Form className="theme-form">
                      <FormGroup>
                        {/*   <Label className="col-form-label pt-0" >LABEL </Label>*/}
                        <Input
                          className="form-control"
                          type="text"
                          placeholder="Search Users"
                          value={searchTerm}
                          onChange={handleInputChange}
                        />
                      </FormGroup>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
              <Col sm="12">
                <Card>
                  <CardBody>
                    {loadingFetch ? (
                      <GridLoader />
                    ) : (
                      <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                          <Row>
                            {allData.length > 0 ? (
                              allData.map((item, i) => (
                                <CusClass
                                  item={item}
                                  key={i}
                                  onEdit={openEditModal}
                                />
                              ))
                            ) : (
                              <Col md="12" className="text-center">
                                <p className="f-light">No users found</p>
                              </Col>
                            )}
                          </Row>
                        </TabPane>
                        <TabPane tabId="2">
                          <Row>
                            {filteredUsers.some(
                              (item) => item.role === "admin"
                            ) ? (
                              filteredUsers.map((item, i) =>
                                item.role === "admin" ? (
                                  <CusClass
                                    item={item}
                                    key={i}
                                    onEdit={openEditModal}
                                  />
                                ) : (
                                  ""
                                )
                              )
                            ) : (
                              <Col md="12" className="text-center">
                                <p className="f-light">No admin found</p>
                              </Col>
                            )}
                          </Row>
                        </TabPane>
                        <TabPane tabId="3">
                          <Row>
                            {filteredUsers.some(
                              (item) => item.role === "artist"
                            ) ? (
                              filteredUsers.map((item, i) =>
                                item.role === "artist" ? (
                                  <CusClass
                                    item={item}
                                    key={i}
                                    onEdit={openEditModal}
                                  />
                                ) : (
                                  ""
                                )
                              )
                            ) : (
                              <Col md="12" className="text-center">
                                <p className="f-light">No artist found</p>
                              </Col>
                            )}
                          </Row>
                        </TabPane>
                        <TabPane tabId="4">
                          <Row>
                            {filteredUsers.some(
                              (item) => item.role === "label"
                            ) ? (
                              filteredUsers.map((item, i) =>
                                item.role === "label" ? (
                                  <CusClass
                                    item={item}
                                    key={i}
                                    onEdit={openEditModal}
                                  />
                                ) : (
                                  ""
                                )
                              )
                            ) : (
                              <Col md="12" className="text-center">
                                <p className="f-light">No label found</p>
                              </Col>
                            )}
                          </Row>
                        </TabPane>
                        <TabPane tabId="5">
                          <Row>
                            {filteredUsers.some(
                              (item) => item.role === "staff"
                            ) ? (
                              filteredUsers.map((item, i) =>
                                item.role === "staff" ? (
                                  <CusClass
                                    item={item}
                                    key={i}
                                    onEdit={openEditModal}
                                  />
                                ) : (
                                  ""
                                )
                              )
                            ) : (
                              <Col md="12" className="text-center">
                                <p className="f-light">No staff found</p>
                              </Col>
                            )}
                          </Row>
                        </TabPane>
                      </TabContent>
                    )}
                    <div className="d-flex justify-content-center">
                      <Pagination aria-label="..." className="mt-3">
                        <ul className="pagination pagination-primary">
                          <PaginationItem disabled={currentPage === 1}>
                            <PaginationLink
                              onClick={() => paginate(currentPage - 1)}
                            >
                              {Previous}
                            </PaginationLink>
                          </PaginationItem>
                          {paginationData &&
                            Array.from({
                              length: paginationData.last_page,
                            }).map((item, index) => (
                              <PaginationItem
                                key={index}
                                active={index + 1 === currentPage}
                              >
                                <PaginationLink
                                  onClick={() => paginate(index + 1)}
                                >
                                  {index + 1}
                                </PaginationLink>
                              </PaginationItem>
                            ))}
                          <PaginationItem
                            disabled={
                              !allData ||
                              (paginationData
                                ? currentPage * paginationData.per_page >=
                                  paginationData.total
                                : true)
                            }
                          >
                            <PaginationLink
                              onClick={() => paginate(currentPage + 1)}
                            >
                              {Next}
                            </PaginationLink>
                          </PaginationItem>
                        </ul>
                      </Pagination>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
        {/* Edit User Modal */}
        <Col md="12" className="project-list">
          <Modal isOpen={modal} toggle={toggleModal} size="lg">
            <ModalHeader toggle={toggleModal}>Edit User</ModalHeader>
            <ModalBody>
              {selectedUser && (
                  <Form>
                    <Row>
                      <Col md="3">
                        <FormGroup>
                          <Label for="editName">Name</Label>
                          <Input
                              type="text"
                              name="editName"
                              id="editName"
                              defaultValue={selectedUser.name}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup>
                          <Label for="editEmail">Email</Label>
                          <Input
                              type="email"
                              name="editEmail"
                              id="editEmail"
                              defaultValue={selectedUser.email}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup>
                          <Label for="editUsername">Username</Label>
                          <Input
                              type="text"
                              name="editUsername"
                              id="editUsername"
                              defaultValue={selectedUser.username}
                          />
                        </FormGroup>
                      </Col>
                      {selectedUser.role === "label" && (
                          <Col md="3">
                            <FormGroup>
                              <Label for="isHC">Have Ticket Account</Label>
                              <div className="d-flex align-items-center">
                                <div className="radio radio-primary ms-2 me-4">
                                  <input
                                      type="radio"
                                      name="isHC"
                                      id="radio_yes"
                                      value="true"
                                      defaultChecked={selectedUser.isHC === true}
                                  />
                                  <Label for="radio_yes">Yes</Label>
                                </div>
                                <div className="radio radio-primary ms-2 me-4">
                                  <input
                                      type="radio"
                                      name="isHC"
                                      id="radio_no"
                                      value="false"
                                      defaultChecked={selectedUser.isHC === false}
                                  />
                                  <Label for="radio_no">No</Label>
                                </div>
                              </div>
                            </FormGroup>
                          </Col>
                      )}
                    </Row>
                    <FormGroup>
                      <Label for="editStatus">Account Status</Label>
                      <Input
                          type="select"
                          name="editStatus"
                          id="editStatus"
                          defaultValue={selectedUser.status_compte ? "1" : "0"}
                      >
                        <option value="1">Activated</option>
                        <option value="0">Disabled</option>
                      </Input>
                    </FormGroup>
                  </Form>
              )}
            </ModalBody>
            <ModalFooter className="d-flex justify-content-center">
              <Button color="primary" onClick={updateUser}>
                {loading ? <Spinner size="sm" color="light" /> : "Save"}
              </Button>{" "}
              <Button color="dark" onClick={toggleModal}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </Col>
        {/* <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          transition=
        /> */}
      </Fragment>
    </>
  );
};

export default User;
