import React, { useState } from "react";
import {
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Modal,
    Button,
    ModalFooter,
    ModalHeader,
    ModalBody,
} from "reactstrap";
import { Btn } from "../../../../../../AbstractElements";
import Metadata from "./UpdateTabs/MetadataUpdate";
import ContributorUpdate from "./UpdateTabs/ContributorUpdate";
import Files from "./UpdateTabs/FileUpdate";
import GridLoader from "../../../../../Loaders/GridLoader";

export default function UpdateAsset({
                                        show,
                                        handleClose,
                                        UpdateAssetRecord,
                                        genres,
                                        artists,
                                        i,
                                        selectedAsset,
                                        fetchedProduct,
                                        control,
                                        errors,
                                        setValue,
                                        loading,
                                        clearErrors,
                                        resetField,
                                        setFormGroups
                                    }) {
    const [activeTab, setActiveTab] = useState("1");
    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    return (
        <Modal isOpen={show} toggle={handleClose} size="lg">
            <ModalHeader toggle={handleClose}>Update Asset</ModalHeader>
            <ModalBody>
                {loading ? (
                    <GridLoader />
                ) : (
                    <>
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    className={activeTab === "1" ? "active" : ""}
                                    onClick={() => {
                                        toggle("1");
                                    }}
                                >
                                    Metadata
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={activeTab === "2" ? "active" : ""}
                                    onClick={() => {
                                        toggle("2");
                                    }}
                                >
                                    Files
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={activeTab === "3" ? "active" : ""}
                                    onClick={() => {
                                        toggle("3");
                                    }}
                                >
                                    Contributors
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="1">
                                <Metadata
                                    i={0}
                                    artists={artists}
                                    genres={genres}
                                    control={control}
                                    errors={errors}
                                    clearErrors={clearErrors}
                                    selectedAsset={selectedAsset}
                                    fetchedProduct={fetchedProduct}
                                    setValue={setValue}
                                    handleNextSecondStep={UpdateAssetRecord}
                                />
                            </TabPane>
                            <TabPane tabId="2">
                                <Files
                                    i={0}
                                    artists={artists}
                                    genres={genres}
                                    control={control}
                                    errors={errors}
                                    setValue={setValue}
                                    clearErrors={clearErrors}
                                    selectedAsset={selectedAsset}
                                    handleNextSecondStep={UpdateAssetRecord}
                                />
                            </TabPane>
                            <TabPane tabId="3">
                                <ContributorUpdate
                                    i={0}
                                    artists={artists}
                                    genres={genres}
                                    assetIndex={0}
                                    control={control}
                                    errors={errors}
                                    resetField={resetField}
                                    clearErrors={clearErrors}
                                    selectedAsset={selectedAsset}
                                    setValue={setValue}
                                    handleNextSecondStep={UpdateAssetRecord}
                                    setFormGroups={setFormGroups}
                                />
                            </TabPane>
                        </TabContent>
                    </>
                )}
            </ModalBody>
            <ModalFooter className="d-flex justify-content-center">
                <Button color="primary" onClick={handleClose}>
                    Close
                </Button>
                <Btn
                    attrBtn={{
                        size: "l",
                        color: "primary",
                        onClick: UpdateAssetRecord,
                    }}
                    type="button"
                >
                    <i className="fa fa-edit me-1"></i>
                    Update Asset
                </Btn>
            </ModalFooter>
        </Modal>
    );
}