import React, { Fragment, useContext, useEffect } from "react";
import { useNavigate } from "react-router";
import { Button, Card, Row } from "reactstrap";
import { Badges, H4, Image, LI, P, UL } from "../../../../AbstractElements";
import CustomizerContext from "../../../../_helper/Customizer";
import { getProductCoverUrl } from "../../../../_helper/Images/ImageHelper";
import { useReleaseContext } from "../../../../_helper/Release/ReleaseProvider"; // Import the useReleaseContext hook
import GridLoader from "../../../Loaders/GridLoader";
// import ProductModal from "../../Ecommerce/Products/ProductModal";
import Swal from "sweetalert2";
const ReleaseGrid = ({ searchKeyword, filter, filterContext, searchBy }) => {

  const {
    fetchProducts,
    loading,
    products,
    deleteProductById,
  } = useReleaseContext();

  const layoutColumns = 3;
  const { layoutURL } = useContext(CustomizerContext);
  // const quantity = 1;
  // const [dataid, setDataid] = useState("");
  // const [openModal, setOpenModal] = useState(false);
  // const [toggleDelete, setToggleDelete] = useState(false);
  // const [searchKeyword, setSearchKeyword] = useState('');
  const history = useNavigate();

  const handleDelete = (productId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to delete this product?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const deleteResult = await deleteProductById(productId);
        if (deleteResult.success) {
          Swal.fire("Deleted!", "Your product has been deleted.", "success");
        } else {
          Swal.fire(
            "Error!",
            "There was an error deleting your product.",
            "error"
          );
        }
      }
    });
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  //? maybe to be used from the Image Helper
  const ProductPlaceholderImage = "/src/assets/images/masonry/13.jpg";
  const noProductsImage = "/src/assets/images/product/empty-box.png";

  const ProductImage = (imageName) => {
    return imageName ? getProductCoverUrl(imageName) : ProductPlaceholderImage;
  };

  const handleClick = (id_product) => {
    history(
      `${process.env.PUBLIC_URL}/app/releases/release-page/${id_product}/${layoutURL}`,
      { state: { productId: id_product } }
    );
  };

  //TODO: Refactor this function to be used inside UseEffect
  const applyFilters = (products, filters) => {
    let filteredProducts = [...products];

    if (filterContext) {
      if (filter.genre && filter.genre.length > 0) {
        filteredProducts = filteredProducts.filter((item) =>
          filter.genre.includes(item.genre.genre)
        );
      }
      if (filter.status && filter.status.length > 0) {
        filteredProducts = filteredProducts.filter((product) =>
          filter.status.includes(product.status)
        );
      }
      if (filter.explicit && filter.explicit.length > 0) {
        filteredProducts = filteredProducts.filter((product) =>
          filter.explicit.includes(product.explicit_content)
        );
      }
    }

    if (filters.searchBy) {
      filteredProducts = filteredProducts.filter(
        (product) =>
          product.title &&
          typeof product.title === "string" &&
          product.title.toLowerCase().includes(filters.searchBy.toLowerCase())
      );
    }

    return filteredProducts;
  };
  
  const filteredProducts = applyFilters(products, filter);
  return (
    <Fragment>
      {loading ? (
        <GridLoader />
      ) : (
        <div className="product-wrapper-grid" id="product-wrapper-grid">
          <Row className="gridRow" id="gridRow">
            {filteredProducts.length > 0 ? (
              filteredProducts.map((item) => {
                return (
                  <div
                    id="gridId"
                    className={`${
                      layoutColumns === 3
                        ? "col-xl-3 col-lg-6 col-sm-6 xl-4 box-col-4"
                        : "col-xl-" + layoutColumns
                    }`}
                    key={item.id}
                  >
                    <Card>
                      <div className="product-box">
                        <div className="product-img">
                          {item.status === "Takedown" ? (
                            <span className="ribbon ribbon-danger">
                              {item.status}
                            </span>
                          ) : (
                            ""
                          )}
                          {item.status === "Approved" ? (
                            <span className="ribbon ribbon-success ribbon-left">
                              {item.status}
                            </span>
                          ) : (
                            ""
                          )}
                          {item.status === "Delivered" ? (
                            <span className="ribbon ribbon-info ribbon-left">
                              {item.status}
                            </span>
                          ) : (
                            ""
                          )}
                          {item.status === "Processing" ? (
                            <span className="ribbon ribbon-info ribbon-left">
                              {item.status}
                            </span>
                          ) : (
                            ""
                          )}
                          {item.status === "Draft" ? (
                            <span className="ribbon ribbon ribbon-clip ribbon-warning">
                              {item.status}
                            </span>
                          ) : (
                            ""
                          )}
                          <Image
                            attrImage={{
                              className: "img-fluid",
                              src: ProductImage(item.artwork),
                              alt: "",
                              style: {
                                width: "524px",
                                height: "481px",
                              },
                            }}
                          />
                          <div className="product-hover">
                            <UL
                              attrUL={{
                                className: "simple-list d-flex flex-row",
                              }}
                            >
                              <LI attrLI={{ className: "border-0" }}>
                                <Button
                                  color="default"
                                  onClick={() => handleClick(item.id)}
                                >
                                  <i className="icon-eye"></i>
                                </Button>
                              </LI>
                              <LI attrLI={{ className: "border-0" }}>
                                <Button
                                  color="default"
                                  onClick={() => handleDelete(item.id)}
                                >
                                  <i className="icon-trash"></i>
                                </Button>
                              </LI>
                            </UL>
                          </div>
                        </div>
                        <div className="product-details h-100">
                          <H4>{item.title || "No Title"}</H4>
                          {/* <P>{item.note || "No Note"}</P> */}
                          <div className="product-price">
                            <div>Artist: {item.user.name || "No Name"}</div>
                          </div>
                          {/* 
                          <div>
                            Genre:{" "}
                            {item.genre
                              ? item.genre.genre || "No Genre"
                              : "No Genre"}
                          </div>
                          */}
                          {/* 
                          <div>
                            <Badges
                              attrBadge={{
                                color:
                                  item.explicit_content === "Explicit"
                                    ? "danger"
                                    : "success",
                                style: { color: "white" },
                              }}
                            >
                              {item.explicit_content || "No Explicit Content"}
                            </Badges>
                          </div>
                          */}
                        </div>
                      </div>
                    </Card>
                  </div>
                );
              })
            ) : (
              <div
                className="product-wrapper-grid"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100vh", // Adjust this value as needed
                }}
              >
                <img src={noProductsImage} alt="No products" />
                <H4>No Releases Availaibe Yet</H4>
              </div>
            )}
            {/* {openModal && (
              <ProductModal
                value={openModal}
                setOpenModal={setOpenModal}
                dataid={dataid}
              />
            )} */}
          </Row>
        </div>
      )}
    </Fragment>
  );
};
export default ReleaseGrid;
