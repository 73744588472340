import React, { Fragment, useState, useContext } from "react";

import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

import UserContext from "../../../../_helper/User";

//import Loader from "../../../../Layout/Loader";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-hot-toast";
import PersonalInfoTab from "./Tabs/PersonalInfoTab";
import BankDetailsTab from "./Tabs/BankDetailsTab";
import SecurityInfoTab from "./Tabs/SecurityInfoTab";
import { Btn, H4 } from "../../../../AbstractElements";
import { useAuth } from "../../../../_helper/Auth/AuthProvider";
import TailChase from "../../../Loaders/TailChase";
// import  useSecurityStore  from "../../../../_helper/Auth/authStore";

const EditMyProfile = ({
  userData,
  setUserData,
  bankDetails,
  addBankDetails,
  securitySettings,
  bankDetailsLoading,
}) => {
  const { checkPassword, updateSecurityLayers } = useAuth();
  const { updatePersonalDetails, updateBankDetails } = useContext(UserContext);
  const [isOldPasswordVerified, setIsOldPasswordVerified] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const { control, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);

  const onOldPasswordSubmit = async (data) => {
    setLoading(true); 
    try {
      const isPasswordCorrect = await checkPassword(data.oldPassword);
      if (isPasswordCorrect) {
        setIsOldPasswordVerified(true);
        setOldPassword(data.oldPassword);
      } else {
        toast.error("Incorrect password! Please try again");
      }
    } catch (error) {
      toast.error("An error occurred while checking the password");
    } finally {
      setLoading(false); 
    }
  };

  const onEditSubmit = async (data) => {
    try {
      await updatePersonalDetails({
        name: data.name,
        username: data.username,
        fullname: data.fullname,
        address: data.address,
      });
      // Update userData state with new data
      setUserData(prevState => ({
        ...prevState,
        name: data.name,
        username: data.username,
        fullname: data.fullname,
        address: data.address,
      }));
      toast.success("Details updated successfully");
    } catch (error) {
      toast.error("An error occurred while updating details");
    }
  };
  // ! Moved to Zustand Store
  // const onBankSubmit = async (data) => {
  //   try {
  //     const response = await updateBankDetails({
  //       bank_name: data.bank_name,
  //       bank_account_number: data.bank_account_number,
  //       bank_iban_number: data.bank_iban_number,
  //       bank_swift_number: data.bank_swift_number,
  //       bank_city: data.bank_city,
  //       bank_country: data.bank_country,
  //     });

  //     if (response.status) {
  //       toast.success("Bank details updated successfully");
  //       setBankDetails(response.user);
  //     } else {
  //       throw new Error("Failed to update bank details");
  //     }
  //   } catch (error) {
  //     console.error("An error occurred while updating bank details", error);
  //     toast.error("An error occurred while updating bank details");
  //   }
  // };

  const onSecuritySubmit = async (data) => {
    const { newPassword, isEmailOTP, isSmsOTP, isPinOTP, pinValue } = data;
    setLoading(true);
    try {
      const result = await updateSecurityLayers(
        newPassword,
        isEmailOTP,
        isSmsOTP,
        isPinOTP,
        pinValue
      );
      toast.success("Security layers updated successfully");
    } catch (error) {
      toast.error("An error occurred while updating security layers");
    } finally {
      setLoading(false);
    }
  };
  const [activeTab, setActiveTab] = useState("1");
  //console.log("from Edit", userData);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({
    sms: false,
    pin: false,
    mail: false,
  });
  const handleCheckboxChange = (event) => {
    setSelectedCheckboxes({
      ...selectedCheckboxes,
      [event.target.id]: event.target.checked,
    });
  };

  return (
    <Fragment>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={activeTab === "1" ? "active" : ""}
            onClick={() => setActiveTab("1")}
          >
            <i className="icofont icofont-ui-home"></i>
            Personal Info
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === "2" ? "active" : ""}
            onClick={() => setActiveTab("2")}
          >
            <i className="icofont icofont-man-in-glasses"></i>
            Billing Details
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === "3" ? "active" : ""}
            onClick={() => setActiveTab("3")}
          >
            <i className="icofont icofont-lock"></i>
            Security
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        {/*PERSONAL INFO */}
        <TabPane tabId="1">
          <PersonalInfoTab userData={userData} onEditSubmit={onEditSubmit} />
        </TabPane>

        {/*Bank Details */}
        <TabPane tabId="2">
          <BankDetailsTab
            bankDetails={bankDetails}
            onBankSubmit={addBankDetails}
            bankDetailsLoading={bankDetailsLoading}
          />
        </TabPane>

        {/* Security Info */}
        <TabPane tabId="3">
          {isOldPasswordVerified ? (
            <SecurityInfoTab
              loading={loading}
              onSecuritySubmit={onSecuritySubmit}
              securitySettings={securitySettings}
            />
          ) : (
            <Card>
              <CardHeader>
                <H4 attrH4={{ className: "card-title mb-0" }}>
                  Verify Your Password To Proceed
                </H4>
              </CardHeader>
              <CardBody>
                <Form
                  className="theme-form"
                  onSubmit={handleSubmit(onOldPasswordSubmit)}
                >
                  <FormGroup>
                    <Label for="oldPassword">Current Password</Label>
                    <Controller
                      name="oldPassword"
                      control={control}
                      defaultValue=""
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Input
                          {...field}
                          type="password"
                          id="oldPassword"
                          placeholder="Current Password"
                        />
                      )}
                    />
                  </FormGroup>
                  {/* <Btn color="primary" type="submit" >
                    Verify password
                  </Btn> */}

                  <Btn
                    attrBtn={{
                      color: "primary",
                      type: "submit",
                    }}
                  >
                    {loading ? <TailChase /> : "Verify password"}
                  </Btn>
                </Form>
              </CardBody>
            </Card>
          )}
        </TabPane>
      </TabContent>
    </Fragment>
  );
};
export default EditMyProfile;
