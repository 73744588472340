import axios from "../../api/axios";

const ClaimService = {
  getAllClaims: async () => {
    try {
      const response = await axios.get("/claims");
      return { success: true, data: response.data.claims };
    } catch (error) {
      console.error("Failed to fetch claims", error);
      throw error;
    }
  },

  getClaimById: async (id) => {
    try {
      const response = await axios.get(`/claims/${id}`);
      return { success: true, data: response.data };
    } catch (error) {
      console.error(`Failed to fetch claim with id ${id}`, error);
      throw error;
    }
  },

  createClaim: async (claimData) => {
    try {
      const response = await axios.post("/claims", claimData);
      return { success: true, data: response.data };
    } catch (error) {
      console.error("Failed to create claim", error);
      throw error;
    }
  },

  updateClaim: async (id, claimData) => {
    try {
      const response = await axios.put(`/claims/${id}`, claimData);
      return { success: true, data: response.data };
    } catch (error) {
      console.error(`Failed to update claim with id ${id}`, error);
      throw error;
    }
  },

  deleteClaim: async (id) => {
    try {
      const response = await axios.delete(`/claims/${id}`);
      return { success: true, data: response.data };
    } catch (error) {
      console.error(`Failed to delete claim with id ${id}`, error);
      throw error;
    }
  },
};

export default ClaimService;