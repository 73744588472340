import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {Col, Container, Form, FormGroup, Input, Label, Row, Spinner} from "reactstrap";
import { Btn, H4, P, Image, } from "../../AbstractElements";
import { AtSign, Smartphone } from "react-feather";
import { fetchCustomizationSettings } from "../../utils/customization";
import Error500 from "../../Components/Pages/ErrorPages/ErrorPage500";
import UserService from "../../Services/users/UserService";
import { ToastContainer, toast } from "react-toastify";


const ForgetPwd = ({ logoClassMain }) => {
  const [togglePassword, setTogglePassword] = useState(false);
  const [emailValue, setEmailValue] = useState("");
  const [phoneValue, setPhoneValue] = useState("");
  const [showEmail, setShowEmail] = useState(true);
  const [showPhone, setShowPhone] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [customization, setCustomization] = useState(null);

  useEffect(() => {
    async function loadCustomization() {
      const settings = await fetchCustomizationSettings();
      if (!settings) {
        setHasError(true);
        return;
      }
      setCustomization(settings);
      document.documentElement.style.setProperty("--theme-default", settings.color1);
      document.documentElement.style.setProperty("--theme-secondary", settings.color2);
    }
    loadCustomization();
  }, []);

  const navigate = useNavigate();

  const handeForgetPwd = async (e) => {
    e.preventDefault();
    if (showEmail) {
      try {
        const response = await UserService.sendResetPasswordEmail(emailValue);
        if (response.success) {
          alert("Email sent successfully. Please check your inbox to reset your password.");
          navigate("/login");
        } else {
          alert("Failed to send reset password email. Please try again.");
        }
      } catch (error) {
        console.error("Error sending reset password email", error);
        alert("An error occurred. Please try again.");
      }
    } else if (showPhone) {
      // handle phone submission
    }
  };

  if (hasError) {
    return <Error500 />;
  }

  if (!customization) {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <Col md="3" key={1}>
            <div className="loader-box">
              <Spinner attrSpinner={{ className: 'loader-6' }} />
            </div>
          </Col>
        </div>
    );
  }

  return (
    <Fragment>
      <section>
        <Container
          className="p-0 login-page"
          fluid={true}
          style={{
            backgroundImage: `url(${customization.bgImage})`,
            backgroundSize: 'cover',
            backgroundAttachment: 'fixed',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat'
          }}
        >
          <Row className="m-0">
            <Col className="p-0">
              <div className="login-card">
                <div>
                  <div>
                    <Link
                      className={`logo ${logoClassMain ? logoClassMain : ""}`}
                      to={process.env.PUBLIC_URL}
                    >
                      <Image
                        attrImage={{
                          className: "img-fluid for-light",
                          src: customization.logo,
                          alt: "loginpage",
                          style: { width: '300px' }
                        }}
                      />
                    </Link>
                  </div>
                  <div className="login-main">
                    <Form
                      className="theme-form login-form"
                      onSubmit={handeForgetPwd}
                    >
                      <H4 attrH4={{ className: "mb-3" }}>
                        Reset Your Password
                      </H4>
                      {showPhone && (
                        <>
                          <FormGroup>
                            <Label className="m-0 col-form-label">
                              <Smartphone /> Enter Your Mobile Number
                            </Label>
                            <Row>
                              <Col xs="4" sm="3">
                                <Input
                                  className="form-control"
                                  type="text"
                                  placeholder="+ 216"
                                />
                              </Col>
                              <Col xs="8" sm="9">
                                <Col xs="8" sm="9">
                                  <Input
                                    className="form-control"
                                    type="tel"
                                    placeholder="00-00-00-00"
                                    value={phoneValue}
                                    onChange={(e) =>
                                      setPhoneValue(e.target.value)
                                    }
                                  />
                                </Col>
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup className="text-end">
                            <Btn
                              attrBtn={{
                                className: "btn-block",
                                color: "primary",
                                type: "submit",
                              }}
                            >
                              Send
                            </Btn>
                          </FormGroup>
                        </>
                      )}
                      {showEmail && (
                        <>
                          <FormGroup className="position-relative">
                            <Label className="col-form-label m-0">
                              Enter Your Email
                            </Label>
                            <Row>
                              <Col xs="12">
                                <Input
                                  className="form-control"
                                  type="text"
                                  placeholder="Enter your Email"
                                  value={emailValue}
                                  onChange={(e) =>
                                    setEmailValue(e.target.value)
                                  }
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup className="text-end">
                            <Btn
                              className="btn-block"
                              color="primary"
                              type="submit"
                            >
                              Send
                            </Btn>
                          </FormGroup>
                        </>
                      )}
                      <P attrPara={{ className: "text-start" }}>
                        Already have a password?
                        <Link
                          to={`${process.env.PUBLIC_URL}/login`}
                          className="ms-2"
                        >
                          Sign in
                        </Link>
                      </P>
                    </Form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <ToastContainer />
    </Fragment>
  );
};

export default ForgetPwd;