import React, { createContext, useContext, useState , useEffect } from 'react';
import ReleaseService from '../../Services/release/ReleaseService';

const ReleaseContext = createContext();

export const useReleaseContext = () => {
  return useContext(ReleaseContext);
};

export const ReleaseProvider = ({ children }) => {
  const [artists, setArtists] = useState([]);
  const [loading, setLoading] = useState(false);
  const [genres, setGenres] = useState([]);
  const [countries, setCountries] = useState([]);
  const [products, setProducts] = useState([]);
  const [fetchedProduct, setFetchedProduct] = useState([]);
  const [isLoadingForImage, setIsLoadingForImage] = useState(false);
  const [fetchedProductassets, setFetchedProductAssets] = useState([]);

  useEffect(() => {
    setFetchedProductAssets((fetchedProduct && fetchedProduct.assets) || []);
  }, [products,fetchedProduct]); 

  // console.log('fetchedProductassets:', fetchedProductassets)

  const fetchArtists = async () => {
    setLoading(true);
    const { success, artists: fetchedArtists, error } = await ReleaseService.getAllArtists();
    if (success) {
      setArtists(fetchedArtists);
    } else {
      console.error('Failed to fetch artists', error);
    }
    setLoading(false);
  };

  const fetchGenres = async () => {
    setLoading(true);
    const { success, genres: fetchedGenres, error } = await ReleaseService.getGenres();
    if (success) {
      setGenres(fetchedGenres);
    } else {
      console.error('Failed to fetch genres', error);
    }
    setLoading(false);
  };
  
  const fetchProductById = async (id) => {
    setLoading(true);
    const product = await ReleaseService.getProductById(id);
    setFetchedProduct(product);
    setLoading(false);

    return product;
  };

  const deleteProductById = async (productId) => {
    setLoading(true);
    try {
      const response = await ReleaseService.deleteProductById(productId);
      if (response.success) {
        setProducts(products.filter(product => product.id !== productId));
        return { success: true };
      } else {
        console.error(`Failed to delete product:`, response);
        return { success: false, error: 'Product deletion failed' };
      }
    } catch (error) {
      console.error(`Failed to delete product:`, error);
      return { success: false, error };
    } finally {
      setLoading(false);
    }
  };

  const deleteAssetById = async (assetId) => {
    setLoading(true);
    try {
      const response = await ReleaseService.deleteAssetById(assetId);
      if (response.success) {
        setFetchedProductAssets(fetchedProductassets.filter(asset => asset.id !== assetId));
        return { success: true };
      } else {
        console.error(`Failed to delete asset:`, response);
        return { success: false, error: 'Asset deletion failed' };
      }
    } catch (error) {
      console.error(`Failed to delete asset:`, error);
      return { success: false, error };
    } finally {
      setLoading(false);
    }
  };
  
  const getAllCountries = async () => {
    setLoading(true);
    const { message, code, listCountries: fetchedCountries } = await ReleaseService.getAllCountries();
    if (code === 200) {
    setCountries(fetchedCountries);
    } else {
      console.error('Failed to fetch countries', message);
    }
    setLoading(false);
  };


  const saveStepData = async (step, data) => {
    setLoading(true);
    try {
      const response = await ReleaseService.saveStepData(step, data);
      setLoading(false);
      if (response.status !== 201) {
        return { success: false, error: response.data.error };
      } else {
        return { success: true, data: response.data };
      }
    } catch (error) {
      setLoading(false);
      console.error(`Failed to save step data`, error);
      return { success: false, error: error.response?.data?.error || error.message };
    }
  };
  
  const saveSecondStepData = async (data) => {
    setLoading(true);
    const { response }  = await ReleaseService.saveSecondStepData(data);
    setLoading(false);
    if (response.status !== 201) {
      console.error(`Failed to save data:`, response.status);
    } else {
      // console.log("Provider Response",response.data.assets);
      setFetchedProductAssets((prevAssets) => [...prevAssets, response.data.assets[0]]);
      return response.data;
    }
  };
  
  const saveThirdStepData = async (data) => {
    setLoading(true);
    try {
      const { response } = await ReleaseService.saveThirdStepData(data);
      setLoading(false);
      if (response.status !== 201) {
        console.error(`Failed to save step three data:`, response.status);
      } else {
        return response.data;
      }
    } catch (error) {
      setLoading(false);
      console.error(`Failed to save step three data:`, error);
      return { success: false, error };
    }
  };


  const fetchProducts = async () => { 
    setLoading(true);
    const { success, products: fetchedProducts, error } = await ReleaseService.getAllProducts();
    if (success) {
      setProducts(fetchedProducts);
    } else {
      console.error('Failed to fetch products', error);
    }
    setLoading(false);
  };

  const searchProducts = (keyword) => {
    const filteredProducts = products.filter((product) =>
        product.title.toLowerCase().includes(keyword.toLowerCase())
    );
    setProducts(filteredProducts);
  };

  const updateProductMetadata = async (productId, metadata) => {
    try {
      const response = await ReleaseService.updateProductMetadata(productId, metadata);
      if (response.success  && response.product) {
        const updatedProduct = response.product;
        setProducts(products.map(product => product.id === productId ? updatedProduct : product));
        fetchProducts();
        return { success: true, product: updatedProduct };
      } else {
        console.error(`Failed to update product metadata:`, response);
        return { success: false, error: 'Product data not available in response' };
      }
    } catch (error) {
      console.error(`Failed to update product metadata:`, error);
      return { success: false, error };
    }
  };

  const updateProductAddInfos = async (productId, data) => {
    setLoading(true);
    try {
      const response = await ReleaseService.updateProductAddInfos(productId, data);
      if (response.success) {
        // Update your state here if necessary
        return { success: true };
      } else {
        console.error(`Failed to update product additional information:`, response);
        return { success: false, error: 'Product additional information update failed' };
      }
    } catch (error) {
      console.error(`Failed to update product additional information:`, error);
      return { success: false, error };
    } finally {
      setLoading(false);
    }
  };
  

  const updateProductPicture = async (productId, imageData) => {
    setIsLoadingForImage(true);
    try {
      const response = await ReleaseService.updateProductPicture(productId, imageData);
      console.log('Update product picture response:', response);
      return { success: true, product: response.product }; // Modify the response to include a success property
    } catch (error) {
      console.error('Failed to update product picture:', error);
      return { success: false, error }; // Return an object with success false in case of error
    } finally {
      setIsLoadingForImage(false);
    }
  };

  const updateAsset = async (data) => {
    setLoading(true);
    try {
      const response = await ReleaseService.updateAsset(data);
      if (response && response.success) {
        // console.log('Update asset response:', response.response.data.asset)
        setFetchedProductAssets(prevAssets => {
          return prevAssets.map(asset => 
            asset.id === response.response.data.asset.id ? response.response.data.asset : asset
          );
        });
        // console.log('Updated assets:', fetchedProductassets);
      }
      return response;
    } catch (error) {
      console.error('Error updating asset:', error);
      return { success: false, error };
    } finally {
      setLoading(false);
    }
  };

  // ?
  // const updateAssets = (updatedAssets) => {
  //   setFetchedProductAssets(updatedAssets);
  // };
  


  return (
    <ReleaseContext.Provider value={{ 
    artists, 
    genres, 
    countries,  
    loading, 
    fetchedProductassets,
    fetchArtists, 
    saveStepData, 
    fetchGenres, 
    saveSecondStepData, 
    getAllCountries,
    saveThirdStepData,
    products,
    fetchProducts,
    fetchProductById,
    fetchedProduct,
    deleteProductById,
    deleteAssetById,
    searchProducts,
    updateProductMetadata,
    updateProductPicture,
    updateProductAddInfos,
    isLoadingForImage,
    updateAsset,
    // updateAssets,
    setFetchedProductAssets,
    }}>
      {children}
    </ReleaseContext.Provider>
  );
};
