import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import { useAddClaim } from "../../../../Hooks/useClaimData";
import { toast } from "react-hot-toast";

const AddClaimModal = ({ show, handleClose }) => {
    const [formData, setFormData] = useState({
        artist_id: "",
        type: "",
        yt_video_url: [""],
        isrcs: [],
        content_timing: "",
        policy: "",
        status: "",
    });

    const addClaimMutation = useAddClaim();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleYtVideoUrlChange = (index, value) => {
        const newYtVideoUrls = [...formData.yt_video_url];
        newYtVideoUrls[index] = value;
        setFormData({ ...formData, yt_video_url: newYtVideoUrls });
    };

    const addYtVideoUrlField = () => {
        setFormData({ ...formData, yt_video_url: [...formData.yt_video_url, ""] });
    };

    const deleteYtVideoUrlField = (index) => {
        const newYtVideoUrls = formData.yt_video_url.filter((_, i) => i !== index);
        setFormData({ ...formData, yt_video_url: newYtVideoUrls });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        addClaimMutation.mutate(formData, {
            onSuccess: () => {
                toast.success("Claim added successfully!");
                handleClose();
            },
            onError: (error) => {
                console.error("Error creating claim:", error);
            },
        });
    };

    return (
        <Modal isOpen={show} toggle={handleClose} size="lg">
            <ModalHeader toggle={handleClose}>Add Claim</ModalHeader>
            <Form onSubmit={handleSubmit}>
                <ModalBody>
                    <Row>
                        <Col md="4">
                            <FormGroup>
                                <Label for="artist_id">Artist ID</Label>
                                <Input
                                    type="number"
                                    name="artist_id"
                                    id="artist_id"
                                    value={formData.artist_id}
                                    onChange={handleChange}
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <Label for="type">Type</Label>
                                <Input
                                    type="select"
                                    name="type"
                                    id="type"
                                    value={formData.type}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="">Select Type</option>
                                    <option value="type1">Type 1</option>
                                    <option value="type2">Type 2</option>
                                    <option value="type3">Type 3</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <Label for="content_timing">Content Timing</Label>
                                <Input
                                    type="text"
                                    name="content_timing"
                                    id="content_timing"
                                    value={formData.content_timing}
                                    onChange={handleChange}
                                    required
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="4">
                            <FormGroup>
                                <Label for="policy">Policy</Label>
                                <Input
                                    type="text"
                                    name="policy"
                                    id="policy"
                                    value={formData.policy}
                                    onChange={handleChange}
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <Label for="status">Status</Label>
                                <Input
                                    type="select"
                                    name="status"
                                    id="status"
                                    value={formData.status}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="">Select Status</option>
                                    <option value="status1">Status 1</option>
                                    <option value="status2">Status 2</option>
                                    <option value="status3">Status 3</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <Label for="isrcs">ISRCs</Label>
                                <Input
                                    type="text"
                                    name="isrcs"
                                    id="isrcs"
                                    value={formData.isrcs}
                                    onChange={(e) => setFormData({ ...formData, isrcs: e.target.value.split(",") })}
                                    required
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <FormGroup>
                        <Label for="yt_video_url">Youtube Video URL</Label>
                        {formData.yt_video_url.map((url, index) => (
                            <div key={index} className="d-flex mb-2">
                                <Input
                                    type="text"
                                    value={url}
                                    onChange={(e) => handleYtVideoUrlChange(index, e.target.value)}
                                    required
                                />
                                {index > 0 && (
                                    <Button color="danger" onClick={() => deleteYtVideoUrlField(index)} className="ms-2">-</Button>
                                )}
                                {index === formData.yt_video_url.length - 1 && (
                                    <Button color="primary" onClick={addYtVideoUrlField} className="ms-2">+</Button>
                                )}
                            </div>
                        ))}
                    </FormGroup>
                </ModalBody>
                <ModalFooter className="d-flex justify-content-center">
                    <Button color="primary" type="submit">Add Claim</Button>
                    <Button color="secondary" onClick={handleClose}>Cancel</Button>
                </ModalFooter>
            </Form>
        </Modal>
    );
};

export default AddClaimModal;