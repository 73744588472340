import React, { useEffect, useState } from 'react';
import Context from './index';
import LabelService from '../../Services/label/LabelService';

const LabelProvider = (props) => {
    const [partners, setPartners] = useState([]);
    const [allData, setAllData] = useState([]);
   // const [labels, setLabels] = useState([]);
    
    const getAllPartnersData = async () => {
        try {
          const response = await LabelService.getAllPartners();
          if (response.success) {
            setAllData(response.data);
          } else {
            console.error('Failed to fetch partners', response.error);
          }
        } catch (error) {
          console.log('cancelled', error);
        }
      };

      //TODO: ERROR HANDLING
      const getLabelsData = async () => {
        try {
          const response = await LabelService.getLabels();
          if (response.success) {
            return response.data; 
          } else {
            console.error('Failed to fetch labels', response.error);
          }
        } catch (error) {
          console.log('cancelled', error);
        }
        return []; 
      };

    const addNewPartner = async (partnerData) => {
        const response = await LabelService.register(partnerData);
        if (response.success) {
          const partnerTemp = {
            username: partnerData.username,
            fullname: partnerData.fullname,
            name: partnerData.name,
            email: partnerData.email,
            password: partnerData.password,
            role: partnerData.role,
            permissions: partnerData.permissions,
            progress: 50
          };
          setAllData(prevData => [...prevData, partnerTemp]);
        } else {
          console.error('Failed to add new partner', response.error);
        }
      };

      const updatePersonalDetails = async (partnerDetails) => {
        const response = await LabelService.updatePersonalDetails(partnerDetails);
        if (response.success) {
          setAllData(prevData => prevData.map(partner => partner.username === partnerDetails.username ? { ...partner, ...partnerDetails } : partner));
        } else {
          console.error('Failed to update personal details', response.error);
        }
      };

      const updateBankDetails = async (bankDetails) => {
        const response = await LabelService.updateBankDetails(bankDetails);
        if (!response.success) {
          console.error('Failed to update bank details', response.error);
        }
      };

    return (
        <Context.Provider
            value={{
                ...props,
                addNewPartner: addNewPartner,
                partner: partners,
                allData,
                getAllPartnersData,
                updatePersonalDetails,
                updateBankDetails,
                getLabelsData,
            }}
        >
            {props.children}
        </Context.Provider>
    );
};

export default LabelProvider;