import React, { createContext, useContext, useState, useEffect } from 'react';
import BarcodeService from '../../Services/barcode/BarcodeService';

const BarcodeContext = createContext();

export const useBarcodeContext = () => {
  return useContext(BarcodeContext);
};

export const BarcodeProvider = ({ children }) => {

  const [upcs, setUpcs] = useState([]);
  const [transfers, setTransfers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [smartLinks, setSmartLinks] = useState([]);

  const fetchUPCs = async () => {
    try {
      setLoading(true);
      const response = await BarcodeService.getAllUPCs();

      if (response.success) {
        setUpcs(response.upcs);
      } else {
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const fetchSmartLinks = async () => {
    try {
      setLoading(true);
      const response = await BarcodeService.getSmartLinks();
      
      if (response.success) {
        setSmartLinks(response.smartLinks);
      } else {
        console.error('Failed to fetch smart links:', response.error);
      }
    } catch (error) {
      console.error('Error fetching smart links:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchTransfers = async () => {
    try {
      setLoading(true);
      const response = await BarcodeService.getAllUPCs();

      if (response.success) {
        setUpcs(response.upcs);
      } else {
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };


  const deleteUPC = async (upcId) => {
    try {
      setLoading(true);
      const response = await BarcodeService.deleteUPC(upcId);
      if (response.success) {
        await fetchUPCs();
      }
      return response;
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };



  const importData = async (file) => {
    try {
      setLoading(true);
      const response = await BarcodeService.importData(file);
      setUpcs(response.upcs);
      return response;
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const importProducts = async (file) => {

      setLoading(true);
      const response = await BarcodeService.importProducts(file);
      setTransfers(response.transfers);
      return response;

  };

  const generateSmartLink = async (file) => {
    try {
      setLoading(true);
      const response = await BarcodeService.generateSmartLink(file);
      return response;
    } catch (error) {
      console.error('Error generating smart link:', error);
      return { success: false, error: 'Failed to generate smart link', error_code: error.response.status };
    } finally {
      setLoading(false);
    }
  };



  return (
    <BarcodeContext.Provider value={{ upcs, transfers, loading, fetchUPCs, importData, deleteUPC, importProducts, generateSmartLink, fetchSmartLinks, smartLinks }}>
      {children}
    </BarcodeContext.Provider>
  );
};

