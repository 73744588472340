import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Row, Col, FormGroup, Label } from 'reactstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import DataTable from 'react-data-table-component';
import { useAccountingData, useLabelArtists } from '../../../../Hooks/useAccountingData';
import Widgets2 from "../../../Common/CommonWidgets/Widgets2";
import { Widgets2ChartData2Widgets } from "../../../../Data/DefaultDashboard/Chart";
import HourGlassLoader from "../../../Loaders/HourGlassLoader";
import countries from 'i18n-iso-countries';
import {AlertCircle} from "react-feather";


countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
const StreamingContainer = ({ userRole, userData }) => {
  const animatedComponents = makeAnimated();

  const providerOptions = [
    { value: 'country', label: 'Country' },
    { value: 'dsp', label: 'DSP' },
    { value: 'title', label: 'Title' },
    { value: 'typesale', label: 'Type Sale' },
  ];

  const columns = {
    country: [
      //{ name: 'Country Name', selector: row => row.name },
      { name: 'Country', selector: row => countries.getName(row.name, "en") || row.name },
      { name: 'Start Date', selector: row => row.start },
      { name: 'End Date', selector: row => row.end },
      { name: 'Streams', selector: row => row.streams },
      { name: 'Downloads', selector: row => row.downloads },
      { name: 'Revenue Downloads', selector: row => row.revenue_downloads },
      { name: 'Revenue Streams', selector: row => row.revenue_streams },
    ],
    dsp: [
      { name: 'DSP Name', selector: row => row.name },
      { name: 'Start Date', selector: row => row.start },
      { name: 'End Date', selector: row => row.end },
      { name: 'Streams', selector: row => row.streams },
      { name: 'Downloads', selector: row => row.downloads },
      { name: 'Revenue Downloads', selector: row => row.revenue_downloads },
      { name: 'Revenue Streams', selector: row => row.revenue_streams },
    ],
    title: [
      { name: 'Title Name', selector: row => row.name },
      { name: 'Start Date', selector: row => row.start },
      { name: 'End Date', selector: row => row.end },
      { name: 'Streams', selector: row => row.streams },
      { name: 'Downloads', selector: row => row.downloads },
      { name: 'Revenue Downloads', selector: row => row.revenue_downloads },
      { name: 'Revenue Streams', selector: row => row.revenue_streams },
    ],
    typesale: [
      { name: 'Type Sale Name', selector: row => row.name },
      { name: 'Start Date', selector: row => row.start },
      { name: 'End Date', selector: row => row.end },
      { name: 'Streams', selector: row => row.streams },
      { name: 'Downloads', selector: row => row.downloads },
      { name: 'Revenue Downloads', selector: row => row.revenue_downloads },
      { name: 'Revenue Streams', selector: row => row.revenue_streams },
    ],
  };

  const [selectedProvider, setSelectedProvider] = useState(providerOptions[0]);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedQuarter, setSelectedQuarter] = useState(null);
  const [selectedArtist, setSelectedArtist] = useState(null);
  const [page, setPage] = useState(1);
  const color1 = userData?.label?.color1 || "#900C3F";

  const handleProviderChange = (selectedOption) => {
    setSelectedProvider(selectedOption);
    setSelectedYear(null);
  };

  const handleYearChange = (selectedOption) => {
    setSelectedYear(selectedOption);
  };

  const handleQuarterChange = (selectedOption) => setSelectedQuarter(selectedOption);

  const handleArtistChange = (selectedOption) => setSelectedArtist(selectedOption);

  const artistId = userRole === 'label' ? selectedArtist?.value : userRole === 'artist' ? userData?.id_user : null;
  const { data, isLoading, error } = useAccountingData(artistId, selectedProvider?.value, selectedQuarter?.value, selectedYear?.value, page);
  const { data: labelArtists, isLoading: isLabelArtistsLoading, error: labelArtistsError } = useLabelArtists(userRole === 'label' ? userData : null);

  useEffect(() => {
    if (userRole === 'label') {
      console.log('Label Artists:', labelArtists);
    }
  }, [labelArtists, userRole]);

  useEffect(() => {
    // Fetch data whenever the selected provider, year, quarter, or page changes
  }, [selectedProvider, selectedYear, selectedQuarter, page]);

  const getDataTable = () => {
    if (userRole === 'label' && !selectedArtist) {
      return (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', color: color1, flexDirection: 'column' }}>
            <AlertCircle size={48} />
            <p>Please select an artist to view data</p>
          </div>
      );
    }
    if (!selectedProvider) {
      return (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', color: color1, flexDirection: 'column' }}>
            <AlertCircle size={48} />
            <p>Please choose a provider to overview data</p>
          </div>
      );
    }
    if (isLoading) {
      return (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <HourGlassLoader/>
          </div>
      );
    }
    if (error) {
      return (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', color: color1, flexDirection: 'column' }}>
            <AlertCircle size={48} />
            <p>Error loading data</p>
            <p>If the error persists, please contact the administrator.</p>
          </div>
      );
    }

    const tableData = data?.data?.data || [];
    const rowsPerPage = data?.pagination?.per_page || 20;

    return (
        <DataTable
            columns={columns[selectedProvider.value]}
            data={tableData}
            pagination
            paginationServer
            paginationTotalRows={data.pagination.total}
            paginationPerPage={rowsPerPage}
            onChangePage={setPage}
        />
    );
  };

  const StreamWidget = {
    title: 'Streams',
    total: data?.totals?.total_streams || '0',
    chart: Widgets2ChartData2Widgets,
  };

  const DownloadWidget = {
    title: 'Downloads',
    total: data?.totals?.total_downloads || '0',
    chart: Widgets2ChartData2Widgets,
  };

  const TotalRevenueStream = {
    title: 'Total Revenue Streams',
    total: data?.totals?.total_revenue_streams || '0.00',
    chart: Widgets2ChartData2Widgets,
  };

  const TotalRevenueDownload = {
    title: 'Total Revenue Downloads',
    total: data?.totals?.total_revenue_downloads || '0.00',
    chart: Widgets2ChartData2Widgets,
  };

  return (
      <>
        {userRole === 'artist' && (
            <Row className="widget-grid">
              <Col xxl='auto' xl='3' sm='6' className='box-col-6'>
                <Widgets2 chartClass='profit-chart' data={StreamWidget} />
              </Col>
              <Col xxl='auto' xl='3' sm='6' className='box-col-6'>
                <Widgets2 chartClass='profit-chart' data={DownloadWidget} />
              </Col>
              <Col xxl='auto' xl='3' sm='6' className='box-col-6'>
                <Widgets2 chartClass='profit-chart' data={TotalRevenueStream} />
              </Col>
              <Col xxl='auto' xl='3' sm='6' className='box-col-6'>
                <Widgets2 chartClass='profit-chart' data={TotalRevenueDownload} />
              </Col>
            </Row>
        )}
        <Card>
          <CardHeader>
            <Row>
              {userRole === 'label' && (
                  <Col md="4">
                    <FormGroup>
                      <Label for="artistSelect">Artist</Label>
                      <Select
                          id="artistSelect"
                          components={animatedComponents}
                          options={labelArtists?.map(artist => ({ value: artist.id, label: artist.name }))}
                          value={selectedArtist}
                          onChange={handleArtistChange}
                          isClearable
                          isLoading={isLabelArtistsLoading}
                          isDisabled={labelArtistsError}
                      />
                    </FormGroup>
                  </Col>
              )}
              <Col md="4">
                <FormGroup>
                  <Label for="providerSelect">Provider</Label>
                  <Select
                      id="providerSelect"
                      components={animatedComponents}
                      options={providerOptions}
                      value={selectedProvider}
                      onChange={handleProviderChange}
                      isClearable
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label for="yearSelect">Year</Label>
                  <Select
                      id="yearSelect"
                      components={animatedComponents}
                      options={Array.from({ length: new Date().getFullYear() - 2013 }, (_, i) => ({
                        value: 2014 + i,
                        label: 2014 + i
                      }))}
                      value={selectedYear}
                      onChange={handleYearChange}
                      isClearable
                      isDisabled={!selectedProvider}
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label for="quarterSelect">Quarter</Label>
                  <Select
                      id="quarterSelect"
                      components={animatedComponents}
                      options={[
                        { value: "q1", label: 'Q1' },
                        { value: "q2", label: 'Q2' },
                        { value: "q3", label: 'Q3' },
                        { value: "q4", label: 'Q4' }
                      ]}
                      value={selectedQuarter}
                      onChange={handleQuarterChange}
                      isClearable
                      isDisabled={!selectedYear}
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <div className="table-responsive support-table">
              {getDataTable()}
            </div>
          </CardBody>
        </Card>
      </>
  );
};

export default StreamingContainer;